import { connect } from 'react-redux'

import { removeChangelogHistoryItem } from 'actions/ChangelogHistory'
import { closeDialog } from 'actions/Dialog'

import DialogConfirmationChangelogHistoryItemRemove
    from 'components/Dialogs/DialogConfirmationChangelogHistoryItemRemove/DialogConfirmationChangelogHistoryItemRemove'

const mapStateToProps = (state, ownProps) => ({
    id: ownProps.id,
})

const mapDispatchToProps = (dispatch) => ({
    onCloseDialog: () => {
        dispatch(closeDialog())
    },
    onSubmitDialog: (id) => {
        dispatch(removeChangelogHistoryItem(id))
        dispatch(closeDialog())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(DialogConfirmationChangelogHistoryItemRemove)
