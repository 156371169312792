import React from 'react'
import PropTypes from 'prop-types'

import styles from './Header.scss'

export default class Header extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node,
    }

    render() {
        return (
            <header className={styles.base}>
                <div className={styles.inner}>
                    <a href="/" className={styles.logo} />
                    {this.props.children}
                </div>
            </header>
        )
    }
}
