import React from 'react'
import { FormattedMessage } from 'react-intl'

export const EDIT_MOD_VERSION_LABEL = (
    <FormattedMessage
        id="edit.mod.version.label"
        defaultMessage="Укажите версию мода"
    />
)

export const EDIT_MOD_VERSION_LABEL_TEXT = (
    <FormattedMessage
        id="edit.mod.version.label.text"
        defaultMessage="Только номер версии. Например 2.5"
    />
)
