import React from 'react'
import { FormattedMessage } from 'react-intl'

import { FileUploadCaption } from 'components'

export const EDIT_UPLOAD_MOD_LABEL = (
    <FormattedMessage
        id="edit.upload.mod.label"
        defaultMessage="Загрузите файл мода"
    />
)

export const EDIT_UPLOAD_MOD_FILE_FORMAT = (isError) => (
    <FormattedMessage
        id="edit.upload.mod.file.format"
        defaultMessage="Формат файла: {ext0}, {ext1}, {ext2} или {ext3}"
        values={{
            ext0: (<FileUploadCaption isError={isError} caption="zip" />),
            ext1: (<FileUploadCaption isError={isError} caption="rar" />),
            ext2: (<FileUploadCaption isError={isError} caption="7z" />),
            ext3: (<FileUploadCaption isError={isError} caption="wotmod" />),
        }}
    />
)

export const EDIT_UPLOAD_MOD_FILE_SIZE = (isError) => (
    <FormattedMessage
        id="edit.upload.mod.file.size"
        defaultMessage="Размер: {size}"
        values={{
            size: (
                <FileUploadCaption
                    isError={isError}
                    caption={(
                        <FormattedMessage
                            id="edit.upload.mod.file.size.caption"
                            defaultMessage="Не более 2GB"
                        />
                    )}
                />
            ),
        }}
    />
)

export const EDIT_UPLOAD_MOD_INVALID_FILE_TYPE_ERROR = (
    <FormattedMessage
        id="edit.upload.mod.invalid.file.type.error"
        defaultMessage="Вы загружаете файл неверного формата"
    />
)

export const EDIT_UPLOAD_MOD_INVALID_FILE_SIZE_ERROR = (
    <FormattedMessage
        id="edit.upload.mod.invalid.file.size.error"
        defaultMessage="Вы загружаете слишком большой файл"
    />
)

export const EDIT_UPLOAD_MOD_COMMON_ERROR = (
    <FormattedMessage
        id="edit.upload.mod.common.error"
        defaultMessage="Не удалось загрузить файл. Попробуйте ещё раз."
    />
)

export const EDIT_UPLOAD_MOD_NO_FILE_SELECTED = (
    <FormattedMessage
        id="edit.upload.mod.no.file.selected"
        defaultMessage="Необходимо загрузить файл мода"
    />
)
