import React from 'react'
import PropTypes from 'prop-types'

import { ButtonYellowLarge } from 'components'

import styles from './Information.scss'

export default class Information extends React.PureComponent {
    static propTypes = {
        message: PropTypes.node.isRequired,
        buttonCaption: PropTypes.node.isRequired,
        onButtonClick: PropTypes.func.isRequired,
    }

    render() {
        return (
            <div className={styles.base}>
                <div className={styles.inner}>
                    <div className={styles.message}>
                        {this.props.message}
                    </div>
                    <ButtonYellowLarge onClick={this.props.onButtonClick}>
                        {this.props.buttonCaption}
                    </ButtonYellowLarge>
                </div>
            </div>
        )
    }
}
