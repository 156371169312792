import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Route, Link } from 'react-router-dom'
import { includes } from 'lodash'
import ClampLines from 'react-clamp-lines'

import { formatFileSize } from 'utils/formatting'
import {
    getDetailsUrl,
    getEditUrl,
    pushHistoryWithOwner,
    pushHistoryWithTags,
} from 'utils/routing'

import {
    MOD_STATE,
} from 'utils/constants'

import {
    ButtonYellowSmall,
    ModState,
    Rating,
    Tag,
} from 'components'

import {
    MOD_SUMMARY_VERSION,
    MOD_SUMMARY_AUTHOR_CAPTION,
    MOD_DOWNLOAD_BUTTON_CAPTION,
    MOD_VIEW_LABEL,
} from './translations'

import settings from 'settings'

import styles from './Mod.scss'

export default class Mod extends React.PureComponent {
    static propTypes = {
        authorName: PropTypes.string,
        cover: PropTypes.string.isRequired,
        gameVersion: PropTypes.string,
        id: PropTypes.number.isRequired,
        isEditLinkEnabled: PropTypes.bool,
        isDownloadDisabled: PropTypes.bool,
        isModDownloadAccepted: PropTypes.bool,
        isSingleVersionAvailable: PropTypes.bool,
        isViewModLinkEnabled: PropTypes.bool,
        mark: PropTypes.number.isRequired,
        modSize: PropTypes.number,
        modVersion: PropTypes.string,
        modVersionFile: PropTypes.string,
        modVersionFileOriginalName: PropTypes.string,
        ownerId: PropTypes.number.isRequired,
        ownerName: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            caption: PropTypes.string.isRequired,
        }).isRequired).isRequired,
        state: PropTypes.number,
        isDisabled: PropTypes.bool,
        onDownloadClick: PropTypes.func,
    }
    constructor(props) {
        super(props)

        this.handleMouseDown = this.handleMouseDown.bind(this)
        this.handleModClick = this.handleModClick.bind(this)
    }

    handleDownloadClick = (event) => {
        event.preventDefault()
        event.stopPropagation()

        const isModDownloadAvailable = this.props.isModDownloadAccepted && this.props.isSingleVersionAvailable
        if (isModDownloadAvailable) {
            this._downloadLinkRef.click()
        }

        this.props.onDownloadClick(this.props.id, isModDownloadAvailable)
    }

    handlerTagClick = (history, tagId) => {
        this.eventClickTagRef.click()
        pushHistoryWithTags(history, tagId)
    }

    handleMouseDown(event) {
        if (!this.props.isEditLinkEnabled && event.button === 1) {
            this.eventClickModRef.click()
        }
    }

    handleModClick() {
        if (!this.props.isEditLinkEnabled) {
            this.eventClickModRef.click()
        }
    }

    handleHiddenLinkClick = (event) => {
        event.stopPropagation()
    }

    renderAuthor(history) {
        const isClickable = !this.props.authorName
        const classNameOwnerName = classNames(styles.ownerName, {
            [styles.isClickable]: isClickable,
        })


        return (
            <div className={styles.owner}>
                {MOD_SUMMARY_AUTHOR_CAPTION}
                &nbsp;
                <span
                    className={classNameOwnerName}
                    title={this.props.authorName || this.props.ownerName}
                    onClick={(event) => {
                        event.preventDefault()
                        if (isClickable) {
                            pushHistoryWithOwner(history, this.props.ownerId, this.props.ownerName)
                        }
                    }}
                >
                    {this.props.authorName || this.props.ownerName}
                </span>
            </div>
        )
    }

    renderState() {
        const stateValues = Object.values(MOD_STATE)

        if (!includes(stateValues, this.props.state)) {
            return null
        }

        return (
            <div className={styles.status}>
                <ModState isBlock state={this.props.state} />
            </div>
        )
    }

    renderCover() {
        const cover = this.props.cover

        return cover ? (
            <img className={styles.cover} src={cover} />
        ) : (
            <div className={styles.cover} />
        )
    }

    renderTags(history) {
        return this.props.tags.map((tag, index) => (
            <div className={styles.tag} key={`mod-tag-${tag.id}-${index}`}>
                <Tag
                    id={tag.id}
                    caption={tag.caption}
                    onClick={() => this.handlerTagClick(history, [tag.id])}
                />
            </div>
        ))
    }

    renderDownload() {
        return !this.props.isDownloadDisabled ? (
            <React.Fragment>
                <div className={styles.button}>
                    <ButtonYellowSmall onClick={this.handleDownloadClick}>
                        {MOD_DOWNLOAD_BUTTON_CAPTION(formatFileSize(this.props.modSize))}
                    </ButtonYellowSmall>
                </div>
            </React.Fragment>
        ) : null
    }

    renderHiddenDownloadLink() {
        return (
            <a
                className={styles.hidden}
                ref={c => (this._downloadLinkRef = c)}
                onClick={this.handleHiddenLinkClick}
                href={this.props.modVersionFile}
                download={this.props.modVersionFileOriginalName}
            />
        )
    }

    renderViewModLink() {
        if (!this.props.isViewModLinkEnabled) {
            return null
        }

        return (
            <Link className={styles.viewMod} to={getDetailsUrl(this.props.id)}>
                <div className={styles.viewModIcon} />
                <div className={styles.viewModLabel}>{MOD_VIEW_LABEL}</div>
            </Link>
        )
    }

    renderVersions() {
        let isNewVersion = false
        if (this.props.gameVersion && settings.gameVersions.length) {
            isNewVersion = settings.gameVersions[0].version === this.props.gameVersion
        }
        return (this.props.gameVersion && this.props.modVersion) ? (
            <div className={styles.versions}>
                {MOD_SUMMARY_VERSION(this.props.modVersion, this.props.gameVersion, isNewVersion)}
            </div>
        ) : null
    }

    render() {
        const classNamesBase = classNames(styles.base, {
            [styles.isDisabled]: this.props.isDisabled,
        })

        const classNameBody = classNames(styles.body, {
            [styles.isDownloadDisabled]: this.props.isDownloadDisabled,
        })

        const targetUrl = this.props.isEditLinkEnabled ? getEditUrl(this.props.id) : getDetailsUrl(this.props.id)

        return (
            <Route render={({ history }) => (
                <div className={classNamesBase}>
                    <div className={classNames('ev_click-mod')} ref={c => (this.eventClickModRef = c)} />
                    <div className={classNames('ev_click-tag-on-mod')} ref={c => (this.eventClickTagRef = c)} />
                    <Link to={targetUrl}
                        className={styles.inner}
                        onClick={this.handleModClick}
                        onMouseDown={this.handleMouseDown}>
                        <div className={styles.rating}>
                            <Rating mark={this.props.mark} />
                        </div>

                        {this.renderState()}

                        <div className={styles.header}>
                            {this.renderCover()}
                        </div>

                        <div className={classNameBody}>
                            <div className={styles.title} title={this.props.title}>
                                <ClampLines
                                    className={styles.clampLines}
                                    text={this.props.title}
                                    lines={2}
                                    buttons={false}
                                />
                            </div>

                            <div className={styles.summary}>
                                {this.renderVersions()}
                                {this.renderAuthor(history)}
                            </div>

                            {this.renderDownload()}
                        </div>

                        <div className={styles.footer}>
                            {this.renderTags(history)}
                        </div>
                    </Link>

                    {this.renderViewModLink()}
                    {this.renderHiddenDownloadLink()}
                </div>
            )} />
        )
    }
}
