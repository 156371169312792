import React from 'react'
import PropTypes from 'prop-types'

import styles from './ButtonGrayOutlineSmall.scss'

export default class ButtonGrayOutlineSmall extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        onClick: PropTypes.func.isRequired,
    }

    render() {
        return (
            <button className={styles.base} onClick={this.props.onClick}>
                {this.props.children}
            </button>
        )
    }
}
