import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import {
    MAX_CHANGELOG_LENGTH,
    MAX_MOD_VERSION_LENGTH,
} from 'utils/constants'

import {
    ButtonYellowSmall,
    Cancel,
    Caption,
    Divider,
    EditLabelError,
    Input,
    Textarea,
} from 'components'

import {
    CANCEL_CAPTION,
    SAVE_BUTTON_CAPTION,
    FIELD_MAX_LIMIT_EXCEED_ERROR,
} from 'translations'

import {
    EDIT_CHANGE_LOG_FORM_PREV_VERSION_DESCRIPTION_LABEL,
    EDIT_CHANGE_LOG_FORM_PREV_VERSION_LABEL,
} from './translations'

import styles from './EditChangelogForm.scss'

export default class EditChangelogForm extends React.PureComponent {
    static propTypes = {
        content: PropTypes.string,
        hasHistory: PropTypes.bool,
        id: PropTypes.number,
        version: PropTypes.string,

        onFormClose: PropTypes.func.isRequired,
        onHistoryItemSave: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            version: '',
            content: '',
            isVersionError: false,
            isContentError: false,
            isVersionEmpty: false,
            isContentEmpty: false,
        }
    }

    componentWillMount() {
        if (this.props.content && this.props.version) {
            this.setState({
                content: this.props.content,
                version: this.props.version,
            })
        }
    }

    handleVersionChange = (version) => {
        this.setState({
            version,
            isVersionError: version.length > MAX_MOD_VERSION_LENGTH,
        })
    }

    handleContentChange = (content) => {
        this.setState({
            content,
            isContentError: content.length > MAX_CHANGELOG_LENGTH,
        })
    }

    handleSaveButtonClick = () => {
        const isVersionEmpty = isEmpty(this.state.version)
        const isContentEmpty = isEmpty(this.state.content)

        this.setState({ isVersionEmpty, isContentEmpty })

        if (!this.state.content || !this.state.version) {
            return
        }
        if (this.state.content.length > MAX_CHANGELOG_LENGTH || this.state.version.length > MAX_MOD_VERSION_LENGTH) {
            return
        }
        this.props.onHistoryItemSave(this.props.id, this.state.content, this.state.version)
    }

    renderVersionError() {
        return this.state.isVersionError ? (
            <EditLabelError message={FIELD_MAX_LIMIT_EXCEED_ERROR(MAX_MOD_VERSION_LENGTH)} />
        ) : null
    }

    renderContentError() {
        return this.state.isContentError ? (
            <EditLabelError message={FIELD_MAX_LIMIT_EXCEED_ERROR(MAX_CHANGELOG_LENGTH)} />
        ) : null
    }

    render() {
        return (
            <div className={styles.base}>
                {!this.props.hasHistory && <div className={styles.divider}><Divider isDashed /></div>}
                <div className={styles.cancel}>
                    <Cancel
                        caption={CANCEL_CAPTION}
                        align="left"
                        onClick={this.props.onFormClose}
                    />
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>
                        <Caption isRequired isError={this.state.isVersionEmpty}>
                            {EDIT_CHANGE_LOG_FORM_PREV_VERSION_LABEL}
                        </Caption>
                        {this.renderVersionError()}
                    </div>
                    <div className={styles.field}>
                        <Input
                            value={this.state.version}
                            isError={this.state.isVersionEmpty}
                            onChange={this.handleVersionChange}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>
                        <Caption isRequired isError={this.state.isContentEmpty}>
                            {EDIT_CHANGE_LOG_FORM_PREV_VERSION_DESCRIPTION_LABEL}
                        </Caption>
                        {this.renderContentError()}
                    </div>
                    <div className={styles.field}>
                        <Textarea
                            value={this.state.content}
                            isError={this.state.isContentEmpty}
                            onChange={this.handleContentChange}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label} />
                    <div className={styles.field}>
                        <ButtonYellowSmall onClick={this.handleSaveButtonClick}>
                            {SAVE_BUTTON_CAPTION}
                        </ButtonYellowSmall>
                    </div>
                </div>
            </div>
        )
    }
}
