import React from 'react'
import { FormattedMessage } from 'react-intl'

export const DIALOG_CONFIRMATION_MOD_VERSION_TITLE = (
    <FormattedMessage
        id="dialog.confirmation.mod.version.title"
        defaultMessage="Вы действительно хотите удалить версию мода?"
    />
)

export const DIALOG_CONFIRMATION_MOD_VERSION_CONTENT = (
    <FormattedMessage
        id="dialog.confirmation.mod.version.content"
        defaultMessage="Обратите внимание, данное действие необратимо и восстановить версию мода вы не сможете."
    />
)

export const DIALOG_CONFIRMATION_MOD_VERSION_OK_BUTTON = (
    <FormattedMessage
        id="dialog.confirmation.mod.version.ok.button"
        defaultMessage="Удалить"
    />
)

export const DIALOG_CONFIRMATION_MOD_VERSION_CANCEL_BUTTON = (
    <FormattedMessage
        id="dialog.confirmation.mod.version.cancel.button"
        defaultMessage="Отмена"
    />
)
