import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Caption } from 'components'

import { TOGLE_CAPTION } from './translations'

import styles from './Toggle.scss'

export default class Toggle extends React.PureComponent {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            caption: PropTypes.node.isRequired,
        })).isRequired,
        checkedItem: PropTypes.string.isRequired,
        isDisabled: PropTypes.bool,
        onItemChange: PropTypes.func.isRequired,
    }

    handleItemChange = (value) => {
        value !== this.props.checkedItem && this.props.onItemChange(value)
    }

    renderItems() {
        return this.props.items.map((item) => {
            const isChecked = item.value === this.props.checkedItem

            const classNameItem = classNames(styles.item, {
                [styles.isChecked]: isChecked,
            })

            return (
                <div
                    key={`nav-item-${item.value}`}
                    className={classNameItem}
                    onClick={() => this.handleItemChange(item.value)}
                >
                    {item.caption}
                </div>
            )
        })
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isDisabled]: this.props.isDisabled,
        })

        return (
            <div className={classNameBase}>
                <Caption>{TOGLE_CAPTION}</Caption>
                <div className={styles.toggle}>
                    {this.renderItems()}
                </div>
            </div>
        )
    }
}
