import { connect } from 'react-redux'

import HyperCommentsWidgetWrapper from 'components/HyperCommentsWidget/HyperCommentsWidgetWrapper'


const mapStateToProps = (state) => ({
    authString: state.hypercomments.authString,
})

export default connect(mapStateToProps)(HyperCommentsWidgetWrapper)
