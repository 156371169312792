import React from 'react'
import { FormattedMessage } from 'react-intl'

export const DIALOG_ERROR_TITLE = (
    <FormattedMessage
        id="dialog.error.title"
        defaultMessage="Что-то пошло не так..."
    />
)

export const DIALOG_ERROR_CONTENT = (
    <FormattedMessage
        id="dialog.error.content"
        defaultMessage="Произошла ошибка. Попробуйте ещё раз."
    />
)

export const DIALOG_ERROR_CLOSE_BUTTON = (
    <FormattedMessage
        id="dialog.error.close.button"
        defaultMessage="Закрыть"
    />
)
