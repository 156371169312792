import { connect } from 'react-redux'

import { closeDialog } from 'actions/Dialog'
import { setModDownloadAccepted } from 'actions/CurrentAccount'
import {
    incrementDownloadCount,
    showDialogDownload,
} from 'actions/Details'

import DialogInformation from 'components/Dialogs/DialogInformation/DialogInformation'

const mapDispatchToProps = (dispatch, ownProps) => ({
    onSubmitDialog: (openDialogDownload) => {
        dispatch(setModDownloadAccepted())

        if (openDialogDownload) {
            dispatch(closeDialog())
            dispatch(showDialogDownload(ownProps.modId, ownProps.title, ownProps.versions))
        } else {
            dispatch(incrementDownloadCount(ownProps.modId))
            dispatch(closeDialog())
        }
    },
})

export default connect(null, mapDispatchToProps)(DialogInformation)
