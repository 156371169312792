import React from 'react'
import PropTypes from 'prop-types'

import { formatCounter, formatDate, formatFileSize } from 'utils/formatting'

import ModDetailsFixedBar from './components/ModDetailsFixedBar/ModDetailsFixedBar'

import { ButtonYellowLarge } from 'components'

import {
    MOD_DETAILS_DOWNLOAD_BUTTON_CAPTION,
    MOD_DETAILS_DOWNLOADS,
    MOD_DETAILS_MOD_VERSION,
    MOD_DETAILS_GAME_VERSION,
    MOD_DETAILS_GAME_CREATED_AT,
    MOD_DETAILS_GAME_UPDATED_AT,
    MOD_DETAILS_STATUS,
} from './translations'

import settings from 'settings'

import styles from './ModDetails.scss'

export default class ModDetails extends React.PureComponent {
    static propTypes = {
        modName: PropTypes.string,
        createdAt: PropTypes.string,
        downloads: PropTypes.number,
        gameVersion: PropTypes.string,
        modSize: PropTypes.number,
        modVersion: PropTypes.string,
        modVersionFile: PropTypes.string,
        modVersionFileOriginalName: PropTypes.string,
        updatedAt: PropTypes.string,
        isSingleVersionAvailable: PropTypes.bool,
        isModDownloadAccepted: PropTypes.bool,
        onDownloadClick: PropTypes.func.isRequired,
    }

    handleDownloadClick = () => {
        const isModDownloadAvailable = this.props.isModDownloadAccepted && this.props.isSingleVersionAvailable
        if (isModDownloadAvailable) {
            this._downloadLinkRef.click()
        }

        this.props.onDownloadClick(isModDownloadAvailable)
    }

    renderDownloads() {
        return (
            <div className={styles.label}>
                {MOD_DETAILS_DOWNLOADS(formatCounter(this.props.downloads))}
            </div>
        )
    }

    renderModVersion() {
        return this.props.modVersion && (
            <div className={styles.label} title={this.props.modVersion}>
                {MOD_DETAILS_MOD_VERSION(this.props.modVersion)}
            </div>
        )
    }

    renderGameVersion() {
        if (this.props.gameVersion) {
            let isNewVersion = false
            if (this.props.gameVersion && settings.gameVersions.length) {
                isNewVersion = settings.gameVersions[0].version === this.props.gameVersion
            }
            return (
                <div className={styles.label}>
                    {MOD_DETAILS_GAME_VERSION(this.props.gameVersion, isNewVersion)}
                </div>
            )
        }

        return null
    }

    renderModDate() {
        if (this.props.updatedAt) {
            return (
                <div className={styles.label}>
                    {MOD_DETAILS_GAME_UPDATED_AT(formatDate(this.props.updatedAt))}
                </div>
            )
        } else if (this.props.createdAt) {
            return (
                <div className={styles.label}>
                    {MOD_DETAILS_GAME_CREATED_AT(formatDate(this.props.createdAt))}
                </div>
            )
        }

        return null
    }

    render() {
        return (
            <div className={styles.base}>
                <ButtonYellowLarge onClick={this.handleDownloadClick} isLarge gtmTag={'ev_click-download1'}>
                    {MOD_DETAILS_DOWNLOAD_BUTTON_CAPTION(formatFileSize(this.props.modSize))}
                </ButtonYellowLarge>

                <a
                    className={styles.hidden}
                    ref={c => (this._downloadLinkRef = c)}
                    href={this.props.modVersionFile}
                    download={this.props.modVersionFileOriginalName}
                />

                <div className={styles.labels}>
                    {this.renderDownloads()}
                    {this.renderModVersion()}
                    {this.renderGameVersion()}
                    {this.renderModDate()}
                </div>
                <div className={styles.status}>{MOD_DETAILS_STATUS}</div>

                <ModDetailsFixedBar
                    modName={this.props.modName}
                    modSize={this.props.modSize}
                    modVersion={this.props.modVersion}
                    gameVersion={this.props.gameVersion}
                    createdAt={this.props.createdAt}
                    updatedAt={this.props.updatedAt}
                    onDownloadClick={this.handleDownloadClick}
                />

            </div>
        )
    }
}
