import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import ClampLines from 'react-clamp-lines'

import styles from './DialogDownload.scss'

export const DIALOG_DOWNLOAD_CAPTION = (modName) => (
    <FormattedMessage
        id="dialog.download.caption"
        defaultMessage="Скачать мод {modName}"
        values={{ modName }}
        // eslint-disable-next-line react/no-children-prop
        children={
            (formattedMessage) => (
                <ClampLines
                    className={styles.clampLines}
                    text={formattedMessage}
                    lines={2}
                    buttons={false}
                />
            )
        }
    />
)

export const DIALOG_DOWNLOAD_SELECT_CAPTION = (
    <FormattedMessage
        id="dialog.download.select.caption"
        defaultMessage="Выберите версию мода:"
    />
)

export const DIALOG_DOWNLOAD_ALERT = (
    <FormattedMessage
        id="dialog.download.alert"
        defaultMessage="Выбранная версия мода не соответствует актуальной версии игры."
    />
)

export const DIALOG_DOWNLOAD_OK_BUTTON = (size) => (
    <FormattedHTMLMessage
        id="dialog.download.ok.button"
        defaultMessage="Скачать мод <mark>({size})</mark>"
        values={{ size }}
    />
)

export const DIALOG_DOWNLOAD_ATTENTION = (
    <FormattedHTMLMessage
        id="dialog.download.attention"
        defaultMessage="Обращаем ваше внимание на то, что компания <mark>Wargaming не несёт ответственности за использование модификаций</mark>."
    />
)

export const DIALOG_DOWNLOAD_VERSION_VALUE = (modVersion, gameVersion) => (
    <FormattedMessage
        id="dialog.download.version.value"
        defaultMessage="{modVersion} для WoT {gameVersion}"
        values={{ modVersion, gameVersion }}
    />
)
