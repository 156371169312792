import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

export const CREATE_PAGE_TITLE = (
    <FormattedMessage
        id="create.page.title"
        defaultMessage="Добавление мода"
    />
)

export const CREATE_INFO = (
    <FormattedMessage
        id="create.info"
        defaultMessage="Чтобы добавить мод, заполните поля ниже. Ваш мод будет опубликован после успешного прохождения проверки."
    />
)

export const messages = defineMessages({
    createModTitle: {
        id: 'create.mod.title',
        defaultMessage: 'Добавление мода — Моды для World of Tanks',
    },
})
