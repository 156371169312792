import React from 'react'
import PropTypes from 'prop-types'

import styles from './Alert.scss'

export default class Alert extends React.PureComponent {
    static propTypes = {
        content: PropTypes.node.isRequired,
    }

    render() {
        return (
            <div className={styles.base}>{this.props.content}</div>
        )
    }
}
