import React from 'react'
import PropTypes from 'prop-types'

import styles from './ModName.scss'

export default class ModName extends React.PureComponent {
    static propTypes = {
        titles: PropTypes.arrayOf(PropTypes.string).isRequired,
    }

    untitled = '<Untitled>'

    renderTitles() {
        if (!this.props.titles.length) {
            return this.untitled
        }

        return this.props.titles.map((title, i) => (
            <span key={`title-${i}`} className={styles.title}>
                {title}
            </span>
        ))
    }

    render() {
        const titles = this.props.titles

        return (
            <div className={styles.base} title={titles.length ? titles.join(' / ') : this.untitled}>
                {this.renderTitles()}
            </div>
        )
    }
}
