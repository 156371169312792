import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import classNames from 'classnames'

import { pushHistoryWithLanding } from 'utils/routing'

import styles from './Dialog.scss'

const ESC_KEY_CODE = 27

export default class Dialog extends React.Component {
    static propTypes = {
        content: PropTypes.node,
        isNavigatingToLandingOnClose: PropTypes.bool,
        isOpened: PropTypes.bool.isRequired,

        history: ReactRouterPropTypes.history,

        onCloseDialog: PropTypes.func.isRequired,
    }

    componentDidMount() {
        document.body.classList.toggle('scroll-off', this.props.isOpened)
    }

    componentWillReceiveProps(nextProps) {
        document.body.classList.toggle('scroll-off', nextProps.isOpened)
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeyDown, true)
        window.addEventListener('click', this.handleOutsideClick, true)
    }

    componentWillUnmount() {
        document.body.classList.remove('scroll-off')

        window.removeEventListener('keydown', this.handleKeyDown, true)
        window.removeEventListener('click', this.handleOutsideClick, true)
    }

    handleCloseDialog = () => {
        this.props.onCloseDialog()
        if (this.props.isNavigatingToLandingOnClose && this.props.history) {
            pushHistoryWithLanding(this.props.history)
        }
    }

    handleKeyDown = (e) => {
        if (this.props.isOpened && e.keyCode === ESC_KEY_CODE) {
            this.handleCloseDialog()
            e.stopImmediatePropagation()
        }
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isOpened]: this.props.isOpened,
        })

        return (
            <div className={classNameBase}>
                <div className={styles.overlay} onClick={this.handleCloseDialog} />
                <div className={styles.dialog}>
                    <div className={styles.close} onClick={this.handleCloseDialog} />
                    {this.props.content}
                </div>
            </div>
        )
    }
}
