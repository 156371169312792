import React from 'react'
import PropTypes from 'prop-types'
import { addLocaleData, IntlProvider } from 'react-intl'
import { isEmpty } from 'lodash'

import { localeDataHash } from 'utils/i18n'

export default class IntlWrapper extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        messages: PropTypes.object.isRequired,
        language: PropTypes.string.isRequired,

        loadLocale: PropTypes.func.isRequired,
    }

    componentDidMount() {
        addLocaleData(localeDataHash[this.props.language])
        this.props.loadLocale(this.props.language)
    }

    render() {
        return !isEmpty(this.props.messages) ? (
            <IntlProvider locale={this.props.language} messages={this.props.messages}>
                {this.props.children}
            </IntlProvider>
        ) : null
    }
}
