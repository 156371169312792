import React from 'react'
import PropTypes from 'prop-types'

import settings from 'settings'
import HyperCommentsWidget from './HyperCommentsWidget'
import { AVAILABLE_LANGUAGES } from 'utils/i18n'
import { scriptLoader } from 'utils/hypercomments'

export default class HyperCommentsWidgetWrapper extends React.Component {
    static propTypes = {
        authString: PropTypes.string,
        lang: PropTypes.oneOf(AVAILABLE_LANGUAGES).isRequired,
        modId: PropTypes.number.isRequired,
        readonly: PropTypes.bool.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            HCAvailable: !!HyperCommentsWidget.manager,
        }
    }

    componentDidMount() {
        if (!this.state.HCAvailable) {
            scriptLoader.getManager(settings.hypercommentsWidgetId, this.props.lang).then((manager) => {
                HyperCommentsWidget.manager = manager
                this.setState({ HCAvailable: true })
            })
        }
    }

    render() {
        return this.state.HCAvailable ? (
            <HyperCommentsWidget
                authString={this.props.authString}
                lang={this.props.lang}
                modId={this.props.modId}
                readonly={this.props.readonly}
            />
        ) : (
            null
        )
    }
}
