import React from 'react'
import classNames from 'classnames'

import styles from './ScrollToTop.scss'

const SCROLL_STEP_IN_PX = 50
const DELAY_IN_MS = 10
const OFFSET_SCROLL = 100

export default class ScrollToTop extends React.PureComponent {
    constructor() {
        super()

        this.state = {
            intervalId: 0,
            isVisible: false,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        const isVisible = window.pageYOffset > OFFSET_SCROLL

        if (this.state.isVisible !== isVisible) {
            this.setState({ isVisible })
        }
    }

    handleClick = () => {
        let intervalId = setInterval(this.scrollStep.bind(this), DELAY_IN_MS)
        this.setState({ intervalId })
    }

    scrollStep = () => {
        window.pageYOffset === 0 && clearInterval(this.state.intervalId)
        window.scroll(0, window.pageYOffset - SCROLL_STEP_IN_PX)
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isVisible]: this.state.isVisible,
        })

        return (
            <div className={classNameBase} onClick={this.handleClick} />
        )
    }
}
