import {
    OPEN_DIALOG,
    CLOSE_DIALOG,
} from 'actions/Dialog'

const initialState = {
    content: null,
    isOpened: false,
}

export const dialog = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_DIALOG: {
            return {
                ...state,
                content: action.content,
                isOpened: true,
                isNavigatingToLandingOnClose: action.isNavigatingToLandingOnClose,
            }
        }
        case CLOSE_DIALOG: {
            return {
                ...state,
                content: null,
                isOpened: false,
            }
        }
        default:
            return state
    }
}
