import React from 'react'
import { connect } from 'react-redux'

import { openDialog } from 'actions/Dialog'
import { fetchItemsModderSection } from 'actions/ModderSection'
import { DialogLogin, DialogConfirmation } from 'containers'
import ModderSection from 'components/ModderSection/ModderSection'


const mapStateToProps = (state) => {
    const isLoggedIn = !!state.currentAccount.spaId && !state.modderSection.isErrorForbidden
    return ({
        isRulesAccepted: state.currentAccount.isRulesAccepted,
        isLoggedIn: isLoggedIn,
        isFetching: state.modderSection.isFetching,
        items: state.modderSection.items,
        isBlocked: isLoggedIn && state.currentAccount.isBanned,
        isDisabled: isLoggedIn && !state.currentAccount.isUserStatisticsSufficient,
        isError: state.modderSection.isError,
    })
}

const mapDispatchToProps = (dispatch) => {
    return ({
        onShowLoginDialog: () => {
            dispatch(openDialog(<DialogLogin />))
        },
        onShowRulesDialog: () => {
            dispatch(openDialog(<DialogConfirmation />, true))
        },
        fetchItems: () => {
            dispatch(fetchItemsModderSection())
        },
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ModderSection)
