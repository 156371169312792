import React from 'react'
import { FormattedMessage } from 'react-intl'

export const EDIT_STATE_LABEL = (state) => (
    <FormattedMessage
        id="edit.state.label"
        defaultMessage="Статус{NBSP}мода: {state}"
        values={{
            state,
            NBSP: '\u00a0',
        }}
    />
)
