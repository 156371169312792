import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { sortableHandle } from 'react-sortable-hoc'

import {
    EditChangelogForm,
    PreviewEdit,
    PreviewRemove,
    ScrollArea,
} from 'components'

import styles from './EditChangelogListItem.scss'

const CONTENT_MAX_HEIGHT = 100

export default class EditChangelogListItem extends React.PureComponent {
    static propTypes = {
        id: PropTypes.number.isRequired,
        content: PropTypes.string.isRequired,
        version: PropTypes.string.isRequired,
        isDraggable: PropTypes.bool,

        onHistoryItemEdit: PropTypes.func.isRequired,
        onConfirmationDialogShow: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            isFormVisible: false,
        }
    }

    handleSetFormVisible = () => {
        this.setState({ isFormVisible: !this.state.isFormVisible })
    }

    handlePreviewRemoveClick = () => {
        this.props.onConfirmationDialogShow(this.props.id)
    }

    renderForm() {
        if (!this.state.isFormVisible) {
            return
        }

        return (
            <div className={styles.form}>
                <EditChangelogForm
                    content={this.props.content}
                    hasHistory
                    id={this.props.id}
                    version={this.props.version}
                    onFormClose={this.handleSetFormVisible}
                    onHistoryItemSave={this.props.onHistoryItemEdit}
                />
            </div>
        )
    }

    renderItem() {
        if (this.state.isFormVisible) {
            return
        }

        const DragHandle = sortableHandle(({ children }) => (
            <div>{children}</div>
        ))

        const classNameItem = classNames(styles.item, {
            [styles.isDraggable]: this.props.isDraggable,
        })

        return (
            <div className={classNameItem}>
                <div className={styles.previewRemove}>
                    <PreviewRemove onClick={this.handlePreviewRemoveClick} />
                </div>

                <div className={styles.previewEdit}>
                    <PreviewEdit onClick={this.handleSetFormVisible} />
                </div>

                <DragHandle>
                    <div className={styles.inner}>
                        <ScrollArea contentHeightMax={CONTENT_MAX_HEIGHT}>
                            <React.Fragment>
                                <div className={styles.title}>{this.props.version}</div>
                                <div className={styles.content}>{this.props.content}</div>
                            </React.Fragment>
                        </ScrollArea>
                    </div>
                </DragHandle>
            </div>
        )
    }

    render() {
        return (
            <div className={styles.base}>
                {this.renderItem()}
                {this.renderForm()}
            </div>
        )
    }
}
