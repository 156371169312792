import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import ClampLines from 'react-clamp-lines'
import Blur from 'react-blur'
import { first, uniqueId } from 'lodash'
import { injectIntl, intlShape } from 'react-intl'
import classNames from 'classnames'

import {
    getPrivacyPolicyUrlByRealm,
    pushHistoryWithErrorNotFound,
    pushHistoryWithOwner,
    pushHistoryWithTags,
    urls,
} from 'utils/routing'

import { getStringLengthFromHtml } from 'utils/strings'

import {
    ActionReport,
    ActionSubscribe,
    Back,
    ButtonGrayOutlineLarge,
    Caption,
    Content,
    Error,
    LanguageSelectMini,
    Main,
    ModDetails,
    Screenshots,
    Tag,
    TitleMajor,
    TitleMinor,
    Voting,
} from 'components'

import { HyperCommentsWidget } from 'containers'

import { COMMON_ERROR, TO_LANDING } from 'translations'

import {
    DETAILS_AUTHENTICATION_FAILS_FOR_ACTIONS,
    DETAILS_AUTHENTICATION_FAILS_FOR_COMMENTS,
    DETAILS_AUTHENTICATED_INFO_FOR_COMMENTS,
    DETAILS_AUTHORED_BY,
    DETAILS_BUTTON_LOAD_CAPTION,
    DETAILS_CHANGELOG_TITLE,
    DETAILS_CHANGELOG_VERSION,
    DETAILS_COMMENTS_LANGUAGE_SELECT_CAPTION,
    DETAILS_COMMENTS_TITLE,
    DETAILS_DESCRIPTION_LANGUAGE_SELECT_CAPTION,
    DETAILS_DESCRIPTION_TITLE,
    DETAILS_INSTALLATION_GUIDE_TITLE,
    DETAILS_TAGS_TITLE,
    DETAILS_UPLOADED_BY,
    messages,
} from './translations'

import styles from './Details.scss'

class Details extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            authorName: PropTypes.string,
            changelogs: PropTypes.arrayOf(PropTypes.shape({
                modVersion: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
            })),
            createdAt: PropTypes.string,
            cover: PropTypes.string,
            descriptions: PropTypes.arrayOf(PropTypes.shape({
                language: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
            })),
            downloads: PropTypes.number,
            gameVersion: PropTypes.string,
            installationGuides: PropTypes.arrayOf(PropTypes.shape({
                language: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
            })),
            isReported: PropTypes.bool,
            isSubscribed: PropTypes.bool,
            mark: PropTypes.number,
            markVotesCount: PropTypes.number,
            modVersion: PropTypes.string,
            ownerId: PropTypes.number,
            ownerName: PropTypes.string,
            screenshots: PropTypes.arrayOf(PropTypes.shape({
                height: PropTypes.number,
                id: PropTypes.number.isRequired,
                position: PropTypes.number.isRequired,
                source: PropTypes.string.isRequired,
                sourceFullSize: PropTypes.string.isRequired,
                sourceViewPage: PropTypes.string.isRequired,
                width: PropTypes.number,
            })),
            tags: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                caption: PropTypes.string.isRequired,
            })),
            title: PropTypes.string,
            updatedAt: PropTypes.string,
            userVote: PropTypes.oneOf([-1, 0, 1]),
            versions: PropTypes.arrayOf(PropTypes.shape({
                downloadUrl: PropTypes.string.isRequired,
                gameVersion: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
                modVersion: PropTypes.string.isRequired,
                versionFileOriginalName: PropTypes.string.isRequired,
                versionFileSize: PropTypes.number,
            })),
        }).isRequired,
        realm: PropTypes.string.isRequired,
        isAuthenticated: PropTypes.bool.isRequired,
        isError: PropTypes.bool.isRequired,
        isErrorNotFound: PropTypes.bool.isRequired,
        isFetched: PropTypes.bool.isRequired,
        isFetching: PropTypes.bool.isRequired,
        isModDownloadAccepted: PropTypes.bool,
        language: PropTypes.string.isRequired,

        history: ReactRouterPropTypes.history.isRequired,
        match: ReactRouterPropTypes.match.isRequired,

        intl: intlShape,

        fetchDetails: PropTypes.func.isRequired,
        onDownloadClick: PropTypes.func.isRequired,
        onFollowMod: PropTypes.func.isRequired,
        onLoginLinkClick: PropTypes.func.isRequired,
        onReportButtonClick: PropTypes.func.isRequired,
        onUnsubscribeMod: PropTypes.func.isRequired,
        voteForMod: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            commentsLanguage: null,
            descriptionsLanguage: null,
            isChangelogExpanded: false,
        }
    }

    componentWillMount() {
        this.modId = +this.props.match.params.modId.toString().replace(/\/$/, '')
        this.props.fetchDetails(this.modId)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isErrorNotFound) {
            pushHistoryWithErrorNotFound(this.props.history)
        }

        this.setDocumentTitle(nextProps)
    }

    setDocumentTitle(props) {
        if (props.data.title) {
            document.title = this.props.intl.formatMessage(messages.documentTitle, { modTitle: props.data.title })
        }
    }

    handleTagClick = (tagId) => {
        pushHistoryWithTags(this.props.history, [tagId])
    }

    handleVoteAction = (value) => {
        this.props.voteForMod(this.modId, value)
    }

    handleOwnerClick = () => {
        pushHistoryWithOwner(this.props.history, this.props.data.ownerId, this.props.data.ownerName)
    }

    handleAuthenticateLinkClick = (event) => {
        event.preventDefault()
        this.props.onLoginLinkClick()
    }

    handleCommentsLanguageChange = (commentsLanguage) => {
        this.setState({
            commentsLanguage,
        })
    }

    handleDescriptionsLanguageChange = (descriptionsLanguage) => {
        this.setState({
            descriptionsLanguage,
        })
    }

    handleLoadMoreChangelogItems = () => {
        this.setState({
            isChangelogExpanded: true,
        })
    }

    handleDownloadClick = (isModDownloaded) => {
        this.props.onDownloadClick(
            this.props.isModDownloadAccepted, isModDownloaded, this.modId, this.props.data.title, this.props.data.versions,
        )
    }

    handleReportButtonClick = () => {
        this.props.onReportButtonClick(this.modId)
    }

    handleFollowMod = () => {
        this.props.onFollowMod(this.modId)
    }

    handleUnsubscribeMod = () => {
        this.props.onUnsubscribeMod(this.modId)
    }

    renderTags() {
        return this.props.data.tags.map((tag, index) => (
            <div className={styles.tag} key={`mod-tag-${tag.id}-${index}`}>
                <Tag
                    id={tag.id}
                    caption={tag.caption}
                    type="light"
                    onClick={this.handleTagClick}
                />
            </div>
        ))
    }

    renderActions() {
        return this.props.isAuthenticated ? (
            <div className={styles.actions}>
                <div className={styles.action}>
                    <ActionSubscribe
                        isSubscribed={this.props.data.isSubscribed}
                        onFollowMod={this.handleFollowMod}
                        onUnsubscribeMod={this.handleUnsubscribeMod}
                    />
                </div>
                <div className={styles.action}>
                    <ActionReport
                        isCompleted={this.props.data.isReported}
                        onClick={this.handleReportButtonClick}
                    />
                </div>
            </div>
        ) : (
            <div className={classNames(styles.actions, styles['actions__disabled'])}>
                <div className={styles.action}>
                    <ActionSubscribe isDisabled />
                </div>
                <div className={styles.action}>
                    <ActionReport isDisabled />
                </div>
                <p className={styles.actionsMessage}>
                    {DETAILS_AUTHENTICATION_FAILS_FOR_ACTIONS(this.handleAuthenticateLinkClick)}
                </p>
            </div>
        )
    }

    renderDescriptions() {
        if (this.props.data.descriptions.length === 0) {
            return null
        }

        const language = this.state.descriptionsLanguage || this.props.language
        const description = this.props.data.descriptions.find((item) => item.language === language) ||
            this.props.data.descriptions[0]

        if (!description.content) {
            return null
        }

        const toggler = this.props.data.descriptions.length === 2 ? (
            <div className={styles.descriptionLanguageSelect}>
                <Caption>{DETAILS_DESCRIPTION_LANGUAGE_SELECT_CAPTION}</Caption>
                <LanguageSelectMini
                    checkedLanguage={language}
                    onLanguageChange={this.handleDescriptionsLanguageChange}
                />
            </div>
        ) : null

        return (
            <React.Fragment>
                <div className={styles.title} key="descriptions-toggler">
                    <TitleMinor>{DETAILS_DESCRIPTION_TITLE}</TitleMinor>
                    {toggler}
                </div>
                <Content isEditor key="descriptions-content">
                    <div dangerouslySetInnerHTML={{ __html: description.content }} />
                </Content>
            </React.Fragment>
        )
    }

    renderScreenshots() {
        if (this.props.data.screenshots.length === 0) {
            return
        }

        return (
            <Screenshots items={this.props.data.screenshots} />
        )
    }

    renderInstallationGuides() {
        if (this.props.data.installationGuides.length === 0) {
            return null
        }

        const language = this.state.descriptionsLanguage || this.props.language
        const installationGuide = this.props.data.installationGuides.find((item) => item.language === language) ||
            this.props.data.installationGuides[0]

        if (!installationGuide.content || !getStringLengthFromHtml(installationGuide.content)) {
            return null
        }

        return (
            <React.Fragment>
                <div className={styles.title} key="installation-guides-title">
                    <TitleMinor key="installation-guides-title">
                        {DETAILS_INSTALLATION_GUIDE_TITLE}
                    </TitleMinor>
                </div>
                <Content isEditor key="installation-guides-content">
                    <div dangerouslySetInnerHTML={{ __html: installationGuide.content }} />
                </Content>
            </React.Fragment>
        )
    }

    renderChangelogs() {
        if (this.props.data.changelogs.length === 0) {
            return null
        }

        const items = this.state.isChangelogExpanded ?
            this.props.data.changelogs :
            this.props.data.changelogs.slice(0, 5)

        const isButtonEnabled = this.props.data.changelogs.length > items.length

        return (
            <React.Fragment>
                <div className={styles.title}>
                    <TitleMinor>
                        {DETAILS_CHANGELOG_TITLE}
                    </TitleMinor>
                </div>
                <Content>
                    {items.map((item, idx) => (
                        <React.Fragment key={uniqueId(`changlelog-item-${idx}`)}>
                            <h4>{DETAILS_CHANGELOG_VERSION(item.modVersion)}</h4>
                            <p className={styles.changelogs}>{item.content}</p>
                            <hr />
                        </React.Fragment>
                    ))}
                </Content>
                {isButtonEnabled && !this.state.isChangelogExpanded ? (
                    <div className={styles.loadMore}>
                        <ButtonGrayOutlineLarge onClick={this.handleLoadMoreChangelogItems}>
                            {DETAILS_BUTTON_LOAD_CAPTION}
                        </ButtonGrayOutlineLarge>
                    </div>
                ) : null}
            </React.Fragment>
        )
    }

    renderHyperCommentsWidget() {
        let language = this.state.commentsLanguage || this.props.language

        if (this.props.data.descriptions.length === 1) {
            language = this.props.data.descriptions[0].language
        }

        return (
            <HyperCommentsWidget
                lang={language}
                modId={this.modId}
                readonly={!this.props.isAuthenticated}
            />
        )
    }

    renderErrorState() {
        if (!this.props.isError) {
            return null
        }

        return (
            <Error>{COMMON_ERROR}</Error>
        )
    }

    renderBlur() {
        return this.props.data.cover ? (
            <Blur className={styles.reactBlur} img={this.props.data.cover} blurRadius={40} />
        ) : null
    }

    renderContent() {
        if (this.props.isError) {
            return null
        }

        const classNameComments = classNames(styles.comments, styles[`comments__${this.props.language}`])

        return (
            <React.Fragment>
                <div className={styles.cover}>
                    {this.renderBlur()}
                </div>
                <div className={styles.base}>
                    <div className={styles.voting}>
                        <Voting
                            isAuthenticated={this.props.isAuthenticated}
                            mark={this.props.data.mark}
                            vote={this.props.data.markVotesCount}
                            userVote={this.props.data.userVote}
                            onVoteAction={this.handleVoteAction}
                        />
                    </div>

                    <div className={styles.header}>
                        <TitleMajor>
                            <div title={this.props.data.title}>
                                <ClampLines
                                    className={styles.clampLines}
                                    text={this.props.data.title}
                                    lines={2}
                                    buttons={false}
                                />
                            </div>
                        </TitleMajor>

                        <div className={styles.details}>
                            <div className={styles.summary}>
                                <div className={styles.tags}>
                                    <Caption>{DETAILS_TAGS_TITLE}</Caption>
                                    {this.renderTags()}
                                </div>
                                {this.props.data.authorName ? (
                                    <div className={styles.author}>
                                        <Caption>{DETAILS_AUTHORED_BY}</Caption>
                                        <span title={this.props.data.authorName}>{this.props.data.authorName}</span>
                                    </div>
                                ) : null}
                                <div className={styles.owner}>
                                    <Caption>{DETAILS_UPLOADED_BY}</Caption>
                                    <span onClick={this.handleOwnerClick} title={this.props.data.ownerName}>
                                        {this.props.data.ownerName}
                                    </span>
                                </div>
                            </div>

                            <div className={styles.modDetails}>
                                <ModDetails
                                    modName={this.props.data.title}
                                    createdAt={this.props.data.createdAt}
                                    downloads={this.props.data.downloads}
                                    gameVersion={this.props.data.gameVersion}
                                    modSize={first(this.props.data.versions).versionFileSize}
                                    modVersion={first(this.props.data.versions).modVersion}
                                    modVersionFile={first(this.props.data.versions).downloadUrl}
                                    modVersionFileOriginalName={first(this.props.data.versions).versionFileOriginalName}
                                    updatedAt={this.props.data.updatedAt}
                                    isModDownloadAccepted={this.props.isModDownloadAccepted}
                                    isSingleVersionAvailable={this.props.data.versions.length === 1}
                                    onDownloadClick={this.handleDownloadClick}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.body}>
                        {this.renderActions()}
                        {this.renderDescriptions()}
                        {this.renderScreenshots()}
                        {this.renderInstallationGuides()}
                        {this.renderChangelogs()}

                        <div className={styles.commentsTitle}>
                            <div className={styles.commentsTitleText}>{DETAILS_COMMENTS_TITLE}</div>

                            {this.props.data.descriptions.length === 2 && (
                                <div className={styles.commentsTitleLanguageSelect}>
                                    <Caption>{DETAILS_COMMENTS_LANGUAGE_SELECT_CAPTION}</Caption>
                                    <LanguageSelectMini
                                        checkedLanguage={this.state.commentsLanguage || this.props.language}
                                        onLanguageChange={this.handleCommentsLanguageChange}
                                    />
                                </div>
                            )}
                        </div>

                        {this.props.isAuthenticated ? (
                            <p className={styles.commentsInfo}>
                                {DETAILS_AUTHENTICATED_INFO_FOR_COMMENTS(getPrivacyPolicyUrlByRealm(this.props.realm))}
                            </p>
                        ) : (
                            <p className={styles.commentsMessage}>
                                {DETAILS_AUTHENTICATION_FAILS_FOR_COMMENTS(this.handleAuthenticateLinkClick)}
                            </p>
                        )}

                        <div className={classNameComments}>
                            {this.renderHyperCommentsWidget()}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        if (!this.props.isFetched || this.props.isFetching) {
            return null
        }

        return (
            <Main>
                <Back caption={TO_LANDING} to={urls.landing} />
                {this.renderContent()}
                {this.renderErrorState()}
            </Main>
        )
    }
}

export default injectIntl(Details)
