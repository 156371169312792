import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Paginator.scss'

const MAX_BUTTONS = 3

export default class Paginator extends React.PureComponent {
    static propTypes = {
        pages: PropTypes.number.isRequired,
        activePage: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
    }

    handlePageChange = (page) => {
        this.props.activePage !== page && this.props.onPageChange(page)
    }

    handlePrevChange = () => {
        this.props.activePage > 1 && this.props.onPageChange(this.props.activePage - 1)
    }

    handleNextChange = () => {
        this.props.activePage < this.props.pages && this.props.onPageChange(this.props.activePage + 1)
    }

    renderPageButtons() {
        const pageButtons = []

        const pages = this.props.pages
        const activePage = this.props.activePage

        let startPage = 1
        let endPage = pages

        if (MAX_BUTTONS < pages) {
            startPage = Math.max(
                Math.min(activePage - Math.floor(MAX_BUTTONS / 2), pages - MAX_BUTTONS + 1),
                1
            )

            endPage = startPage + MAX_BUTTONS - 1
        }

        if (startPage > 1) {
            pageButtons.push(
                <li
                    key="page-1"
                    className={styles.page}
                    onClick={() => { this.handlePageChange(1) }}
                >
                    1
                </li>
            )

            if (startPage > 2) {
                pageButtons.push(
                    <li
                        key="page-start-hellip"
                        className={styles.hellip}
                    >
                        &hellip;
                    </li>
                )
            }
        }

        for (let page = startPage; page <= endPage; ++page) {
            pageButtons.push(
                <li
                    key={`page-${page}`}
                    className={classNames(styles.page, { [styles.isActive]: page === activePage })}
                    onClick={() => { this.handlePageChange(page) }}
                >
                    {page}
                </li>
            )
        }

        if (endPage < pages) {
            if (endPage < pages - 1) {
                pageButtons.push(
                    <li
                        key="page-end-hellip"
                        className={styles.hellip}
                    >
                        &hellip;
                    </li>
                )
            }

            pageButtons.push(
                <li
                    key={`page-${pages}`}
                    className={styles.page}
                    onClick={() => { this.handlePageChange(pages) }}
                >
                    {pages}
                </li>
            )
        }

        if (pages > 1) {
            pageButtons.unshift(
                <li
                    key="page-prev"
                    className={classNames(styles.prev, { [styles.isDisabled]: activePage === 1 })}
                    onClick={() => { this.handlePrevChange() }}
                />
            )

            pageButtons.push(
                <li
                    key="page-next"
                    className={classNames(styles.next, { [styles.isDisabled]: activePage === pages })}
                    onClick={() => { this.handleNextChange() }}
                />
            )
        }

        return pageButtons
    }

    render() {
        return (
            <ul className={styles.base}>
                {this.renderPageButtons()}
            </ul>
        )
    }
}
