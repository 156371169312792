import React from 'react'
import { connect } from 'react-redux'

import { openDialog } from 'actions/Dialog'
import CommonMenu from 'components/CommonMenu/CommonMenu'
import { DialogLogin } from 'containers'


const mapStateToProps = (state) => ({
    spaId: state.currentAccount.spaId,
    spaUsername: state.currentAccount.spaUsername,
    isFetching: state.currentAccount.isFetching,
})

const mapDispatchToProps = (dispatch) => ({
    onLoginClick() {
        dispatch(openDialog(<DialogLogin />))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(CommonMenu)
