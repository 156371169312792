import { cloneDeep, isInteger, isString } from 'lodash'

export const toggleList = (list, item) => {
    const newList = cloneDeep(list)

    if (newList.includes(item)) {
        const index = newList.indexOf(item)
        newList.splice(index, 1)
    } else {
        newList.push(item)
    }

    return newList
}


export const parseIdsQueryPart = (rawString) => {
    if (!isString(rawString)) {
        return null
    }

    const items = rawString.split(',').map((item) => +item).filter((item) => isInteger(item))
    if (items.length === 0) {
        return null
    }
    return items
}
