import { connect } from 'react-redux'

import { closeDialog } from 'actions/Dialog'
import {
    setIEAlertAccepted,
} from 'actions/CurrentAccount'

import DialogIEAlert from 'components/Dialogs/DialogIEAlert/DialogIEAlert'

const mapDispatchToProps = (dispatch) => ({
    onCloseDialog: () => {
        dispatch(setIEAlertAccepted())
        dispatch(closeDialog())
    },
})

export default connect(null, mapDispatchToProps)(DialogIEAlert)
