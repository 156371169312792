import { connect } from 'react-redux'
import { union } from 'lodash'

import * as galleryActions from 'actions/Gallery'
import * as searchResultActions from 'actions/SearchResult'

import FilterGameVersion from 'components/UI/FilterGameVersion/FilterGameVersion'
import { ALL_GAME_VERSIONS } from 'translations'
import settings from 'settings'

const mapStateToProps = (state, ownProps) => ({
    gameVersions: union(settings.gameVersions, [ALL_GAME_VERSIONS]),
    isUsedForSearch: !!ownProps.isUsedForSearch,
    selectedGameVersion: ownProps.isUsedForSearch ?
        state.searchResult.overallFilters.gameVersion :
        state.gallery.overallFilters.gameVersion,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    onChange: (gameVersionId) => {
        const action = ownProps.isUsedForSearch ?
            searchResultActions.changeGameVersionFilter :
            galleryActions.changeOverallGameVersionFilter
        dispatch(action(gameVersionId))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterGameVersion)
