import apiUrls from 'apiUrls'
import settings from 'settings'

export default class CommonMenu {
    constructor(initialData) {
        this.initialData = initialData

        const loader = document.getElementById('common_menu_loader')
        if (!loader) {
            return
        }

        loader.onload = this.onLoad
        loader.onerror = this.onError
        loader.setAttribute('src', loader.getAttribute('data-src'))
    }

    onLoad = () => {
        window.WG.CommonMenu
            .update({
                user_id: this.initialData.spaId,
                user_name: this.initialData.spaUsername,
            })
            .on('login', this.loginHandler)
            .on('logout', this.logoutHandler)
    }

    onError = () => {
        console.warn('Failed to load Common Menu') // eslint-disable-line no-console
    }

    loginHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.initialData.onLogin()
    }

    logoutHandler = (event) => {
        event.preventDefault()

        const url = event.target.href

        const form = document.createElement('form')
        form.method = 'POST'
        form.action = url

        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'csrfmiddlewaretoken'
        input.value = settings.csrfToken
        form.appendChild(input)

        document.body.appendChild(form)
        form.submit()
    }

    setFetching(isFetching) {
        if (isFetching) {
            window.WG.CommonMenu.showLoginSpinner()
        } else {
            window.WG.CommonMenu.hideLoginSpinner()
        }
    }
}

export const login = (realm) => {
    window.location = apiUrls.login[realm]
}
