import React from 'react'
import { FormattedMessage, FormattedHTMLMessage, defineMessages } from 'react-intl'

export const GALLERY_POPULAR_MODS = (
    <FormattedMessage
        id="gallery.popular.mods"
        defaultMessage="Популярные моды"
    />
)

export const GALLERY_LOAD_POPULAR_MODS = (
    <FormattedMessage
        id="gallery.load.popular.mods"
        defaultMessage="Показать больше популярных"
    />
)

export const GALLERY_NEW_MODS = (
    <FormattedMessage
        id="gallery.new.mods"
        defaultMessage="Новые моды"
    />
)

export const GALLERY_LOAD_NEW_MODS = (
    <FormattedMessage
        id="gallery.load.new.mods"
        defaultMessage="Показать больше новых"
    />
)

export const GALLERY_UPDATED_MODS = (
    <FormattedMessage
        id="gallery.updated.mods"
        defaultMessage="Обновленные моды"
    />
)

export const GALLERY_LOAD_UPDATED_MODS = (
    <FormattedMessage
        id="gallery.load.updated.mods"
        defaultMessage="Показать больше обновленных"
    />
)

export const GALLERY_FILTERED_BY_TAGS_CAPTION = (categoriesCount) => (
    <FormattedHTMLMessage
        id="gallery.filtered.by.tags.caption"
        defaultMessage="Выбрано категорий: <mark>{categoriesCount}</mark>"
        values={{
            categoriesCount,
        }}
    />
)

export const GALLERY_LOAD_FILTERED_MODS = (
    <FormattedMessage
        id="gallery.load.filtered.mods"
        defaultMessage="Показать еще"
    />
)

export const GALLERY_ORDERING_BY_RATING_FILTERED_MODS = (
    <FormattedMessage
        id="gallery.ordering.by.rating.filtered.mods"
        defaultMessage="По популярности"
    />
)

export const GALLERY_ORDERING_BY_CHANGED_AT_FILTERED_MODS = (
    <FormattedMessage
        id="gallery.ordering.by.changed.at.filtered.mods"
        defaultMessage="По дате"
    />
)

export const GALLERY_FILTERED_EMPTY = (
    <FormattedMessage
        id="gallery.filtered.empty"
        defaultMessage="В данный момент моды в выбранной категории отсутствуют. Выберите другую категорию."
    />
)

export const GALLERY_FILTERED_RECOMMENDED_EMPTY = (
    <FormattedMessage
        id="gallery.filtered.recommended.empty"
        defaultMessage="Популярных модов для выбранной версии игры пока нет. Они обязательно появятся в ближайшее время."
    />
)

export const messages = defineMessages({
    landingTitle: {
        id: 'gallery.landing.title',
        defaultMessage: 'Моды для World of Tanks — Скачать на официальном сайте',
    },
    filterByTagTitle: {
        id: 'gallery.filter.by.tag.title',
        defaultMessage: 'Скачать моды по тегу {tagTitle} — Моды для World of Tanks',
    },
})
