import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './ButtonRedLarge.scss'

export default class ButtonRedLarge extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        glyph: PropTypes.oneOf(['speakers']).isRequired,
        onClick: PropTypes.func.isRequired,
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles[`base__${this.props.glyph}`]]: this.props.glyph,
        })

        return (
            <button className={classNameBase} onClick={this.props.onClick}>
                {this.props.children}
            </button>
        )
    }
}
