import React from 'react'

import styles from './Check.scss'

export default class Check extends React.PureComponent {
    render() {
        return (
            <span className={styles.base} />
        )
    }
}
