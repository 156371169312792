import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'

export const SUBSCRIPTIONS_ITEM_VERSION = (version) => (
    <FormattedHTMLMessage
        id="subscriptions.item.version"
        defaultMessage="Версия мода <mark>{version}</mark>"
        values={{
            version,
        }}
    />
)

export const SUBSCRIPTIONS_ITEM_UDATED_DATE = (date) => (
    <FormattedHTMLMessage
        id="subscriptions.item.updated_date"
        defaultMessage="Обновлён: <mark>{date}</mark>"
        values={{
            date,
        }}
    />
)

export const SUBSCRIPTIONS_ITEM_DOWNLOAD_LABEL = (size) => (
    <FormattedHTMLMessage
        id="subscriptions.item.download_lbl"
        defaultMessage="Cкачать мод <mark>({size})</mark>"
        values={{
            size,
        }}
    />
)
