import React from 'react'
import { FormattedMessage } from 'react-intl'

export const EDIT_UPLOAD_UPDATE_ADD_LABEL = (
    <FormattedMessage
        id="edit.upload.update.add.label"
        defaultMessage="Добавление обновления"
    />
)

export const EDIT_UPLOAD_UPDATE_ADD_BUTTON = (
    <FormattedMessage
        id="edit.upload.update.add.button"
        defaultMessage="Добавить обновление"
    />
)
