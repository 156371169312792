import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Table.scss'

export default class Table extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        isEmpty: PropTypes.bool,
        isOwn: PropTypes.bool,
        isProcessing: PropTypes.bool,
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isEmpty]: this.props.isEmpty,
            [styles.isOwn]: this.props.isOwn,
            [styles.isProcessing]: this.props.isProcessing,
        })

        return (
            <table className={classNameBase}>{this.props.children}</table>
        )
    }
}
