import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import ReactRouterPropTypes from 'react-router-prop-types'
import PropTypes from 'prop-types'

import { urls, pushHistoryWithErrorNotFound } from 'utils/routing'

import {
    Back,
    Content,
    Error,
    Main,
    TitleMajor,
} from 'components'

import {
    COMMON_ERROR,
    TO_LANDING,
} from 'translations'

import { messages } from './translations'

import styles from './ContentPage.scss'

class ContentPage extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            content: PropTypes.string,
            title: PropTypes.string,
        }).isRequired,
        isError: PropTypes.bool.isRequired,
        isErrorNotFound: PropTypes.bool.isRequired,
        isFetched: PropTypes.bool.isRequired,
        isFetching: PropTypes.bool.isRequired,

        history: ReactRouterPropTypes.history.isRequired,
        match: ReactRouterPropTypes.match.isRequired,

        intl: intlShape,

        fetchContentPage: PropTypes.func.isRequired,
    }

    componentWillMount() {
        this.props.fetchContentPage(this.getSlug(this.props))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isErrorNotFound) {
            pushHistoryWithErrorNotFound(this.props.history)
        }

        if (this.props.match !== nextProps.match) {
            this.props.fetchContentPage(this.getSlug(nextProps))
        }

        this.setDocumentTitle(nextProps)
    }

    setDocumentTitle(props) {
        if (props.data.title) {
            document.title = this.props.intl.formatMessage(messages.documentTitle, { pageTitle: props.data.title })
        }
    }

    getSlug(props) {
        return props.match.params.slug.toString().replace(/\/$/, '')
    }


    renderTitle() {
        if (this.props.isError) {
            return null
        }

        return (
            <TitleMajor>{this.props.data.title}</TitleMajor>
        )
    }

    renderContent() {
        if (this.props.isError) {
            return (
                <Error>{COMMON_ERROR}</Error>
            )
        }

        return (
            <div className={styles.inner}>
                <Content>
                    <div dangerouslySetInnerHTML={{ __html: this.props.data.content }} />
                </Content>
            </div>
        )
    }

    render() {
        if (!this.props.isFetched || this.props.isFetching) {
            return null
        }

        return (
            <Main>
                <div className={styles.base}>
                    <div className={styles.head}>
                        <Back caption={TO_LANDING} to={urls.landing} />
                        {this.renderTitle()}
                    </div>
                    <div className={styles.body}>
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
}

export default injectIntl(ContentPage)