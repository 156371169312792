import React from 'react'
import { FormattedMessage } from 'react-intl'

export const EDIT_UPLOAD_UPDATE_FORM_LABEL = (
    <FormattedMessage
        id="edit.upload.update.form.label"
        defaultMessage="Добавление обновления"
    />
)

export const EDIT_UPLOAD_UPDATE_FORM_CANCEL_CAPTION = (
    <FormattedMessage
        id="edit.upload.update.form.cancel.caption"
        defaultMessage="Отменить обновление"
    />
)

export const EDIT_UPLOAD_UPDATE_FORM_PUBLISH_LABEL = (
    <FormattedMessage
        id="edit.upload.update.form.publish.label"
        defaultMessage="Публикация"
    />
)

export const EDIT_UPLOAD_UPDATE_FORM_PUBLISH_CHECKBOX_LABEL = (
    <FormattedMessage
        id="edit.upload.update.form.publish.checkbox.label"
        defaultMessage="Опубликовать автоматически после успешной проверки"
    />
)

export const EDIT_UPLOAD_UPDATE_BUTTON = (
    <FormattedMessage
        id="edit.upload.update.button"
        defaultMessage="Отправить на проверку"
    />
)
