import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { urls } from 'utils/routing'

import {
    Back,
    Divider,
    Error,
    Main,
    TitleMajor,
} from 'components'

import NewsHeadersList from './components/NewsHeadersList/NewsHeadersList'
import NewsContentsList from './components/NewsContentsList/NewsContentsList'

import { COMMON_ERROR, TO_LANDING } from 'translations'
import { NEWS_PAGE_TITLE, EMPTY_NEWS, messages } from './translations'

import styles from './News.scss'

class News extends React.Component {
    static propTypes = {
        count: PropTypes.number.isRequired,
        isError: PropTypes.bool.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            content: PropTypes.string.isRequired,
            publishedAt: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })),
        page: PropTypes.number.isRequired,

        intl: intlShape,

        onChangeNewsPage: PropTypes.func.isRequired,
        fetchNews: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            activeItemId: props.items.length > 0 ? props.items[0].id : null,
            scrollToActiveItem: false,

        }
    }

    componentWillMount() {
        document.title = this.props.intl.formatMessage(messages.newsTitle)
        Promise.resolve(
            this.props.onChangeNewsPage(1)
        ).then(()=>{
            this.props.fetchNews()
        })
    }

    componentWillReceiveProps(nextProps) {
        if (get(nextProps, 'items[0].id') !== get(this.props, 'items[0].id')) {
            this.setState({ activeItemId: nextProps.items[0].id })
        }
    }

    handleActiveItemChanged = (activeItemId) => {
        this.setState({ 
            activeItemId,
            scrollToActiveItem: false,
        })
    }

    handleItemClick = (activeItemId) => {
        this.setState({ 
            activeItemId, 
            scrollToActiveItem: true,
        })
    }

    handleChangeNewsPage = (page) => {
        this.setState({
            activeItemId: null,
        })

        this.props.onChangeNewsPage(page)
    }

    renderError() {
        return (
            <Main>
                <div className={styles.base}>
                    {this.renderHead()}
                    <Error>{COMMON_ERROR}</Error>
                </div>
            </Main>
        )
    }

    renderEmpty() {
        return (
            <Main>
                <div className={styles.base}>
                    {this.renderHead()}
                    <Error>{EMPTY_NEWS}</Error>
                </div>
            </Main>
        )
    }

    renderHead() {
        return (
            <div className={styles.head}>
                <Back caption={TO_LANDING} to={urls.landing} />
                <TitleMajor>{NEWS_PAGE_TITLE}</TitleMajor>
            </div>
        )
    }

    render() {
        if (this.props.isError) {
            return this.renderError()
        } else if (this.props.items && this.props.items.length === 0) {
            return this.renderEmpty()
        }
        
        return (
            <Main>
                <div className={styles.base}>
                    <div className={styles.leftColumn}>
                        {this.renderHead()}
                        <Divider />
                        <NewsHeadersList
                            activeItemId={this.state.activeItemId}
                            count={this.props.count}
                            items={this.props.items}
                            page={this.props.page}
                            onChangeNewsPage={this.handleChangeNewsPage}
                            onItemClick={this.handleItemClick}
                        />
                    </div>
                    <div className={styles.rightColumn}>
                        <NewsContentsList
                            items={this.props.items}
                            activeItemId={this.state.activeItemId}
                            scrollToActiveItem={this.state.scrollToActiveItem}
                            onActiveItemChanged={this.handleActiveItemChanged} 
                        />
                    </div>
                </div>
            </Main>
        )
    }
}

export default injectIntl(News)