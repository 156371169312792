import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

export const EDIT_MOD_DRAFT_PAGE_TITLE = (
    <FormattedMessage
        id="edit.mod.draft.page.title"
        defaultMessage="Редактирование черновика"
    />
)

export const EDIT_MOD_INFO = (
    <FormattedMessage
        id="edit.mod.info"
        defaultMessage="Чтобы добавить мод, заполните поля ниже. Ваш мод будет опубликован после успешного прохождения проверки."
    />
)

export const EDIT_MOD_NOTIFICATION_REQUIRED_CONTENT = (
    <FormattedMessage
        id="edit.mod.notification.required.content"
        defaultMessage="Для публикации мода, вам необходимо заполнить все поля, отмеченные {required}{br}{br}Для сохранения черновика достаточно заполнить поле «НАЗВАНИЕ МОДА»."
        values={{
            required: (<mark>*</mark>),
            br: <br />,
        }}
    />
)

export const messages = defineMessages({
    editModTitle: {
        id: 'edit.mod.title',
        defaultMessage: 'Редактирование мода {modTitle} — Моды для World of Tanks',
    },
})