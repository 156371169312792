import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { syncHistoryWithStore } from 'react-router-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import { templateSettings } from 'lodash'
import { composeWithDevTools } from 'redux-devtools-extension'
import persistState from 'redux-localstorage'

import middlewares, { history } from 'middlewares'
import rootReducer from 'reducers'
import { activateFormattingLocale, registerFormattingLocales } from 'utils/formatting'
import { preloadedStateValidator } from 'utils/validators'
import settings from 'settings'

import {
    IntlWrapper,
    MainWrapper,
} from 'containers'

import 'stylesheets/styles.scss'

templateSettings.interpolate = /\$\(([\s\S]+?)\)/g

// eslint-disable-next-line no-undef
__webpack_public_path__ = `${settings.staticUrl}modportal_assets/`

const enhancer = compose(
    applyMiddleware(...middlewares),
    persistState('persistedValues', { key: 'modpPersistedValues' }),
)
const store = createStore(
    enableBatching(rootReducer),
    preloadedStateValidator(window.__PRELOADED_STATE__),
    composeWithDevTools(enhancer),
)

syncHistoryWithStore(history, store)

registerFormattingLocales()
activateFormattingLocale(window.__PRELOADED_STATE__.currentAccount.lang)

const app = () => (
    <Provider store={store}>
        <IntlWrapper>
            <Router history={history}>
                <MainWrapper />
            </Router>
        </IntlWrapper>
    </Provider>
)

const main = () => {
    ReactDOM.render(
        app(store),
        document.getElementById('app'),
    )
}

if (!global.Intl) {
    require.ensure([
        'intl',
    ], function(require) {
        require('intl')
        main()
    })
} else {
    main()
}
