import settings from 'settings'

export const urls = {
    contentPage: '/pages/:slug(.*)/',
    create: '/modder-section/create/',
    details: '/:modId/',
    edit: '/:modId/edit/',
    errorNotFound: '/error-not-found/',
    landing: '/',
    modderSection: '/modder-section/',
    news: '/news/',
    search: '/search/',
    subscriptions: '/followed-mods/',
}

export const getTermsOfUse = (language) => `/pages/terms_of_use_${language}`
export const getTermsOfUsePortal = (language) => `/pages/terms_of_use_portal_${language}`
export const getDetailsUrl = (modId) => urls.details.replace(/:modId/g, modId)
export const getEditUrl = (modId) => urls.edit.replace(/:modId/g, modId)

export const pushHistoryWithCreate = (history) => {
    history.push({
        pathname: urls.create,
        search: '',
    })
}

export const pushHistoryWithDetails = (history, modId) => {
    history.push({
        pathname: urls.details.replace(/:modId/g, modId),
    })
}

export const pushHistoryWithEdit = (history, modId) => {
    history.push({
        pathname: urls.edit.replace(/:modId/g, modId),
    })
}

export const pushHistoryWithErrorNotFound = (history) => {
    history.replace({
        pathname: urls.errorNotFound,
        search: '',
    })
}

export const pushHistoryWithLanding = (history) => {
    history.push({
        pathname: urls.landing,
        search: '',
    })
}

export const pushHistoryWithModderSection = (history) => {
    history.push({
        pathname: urls.modderSection,
        search: '',
    })
}

export const pushHistoryWithOwner = (history, ownerId, ownerName) => {
    history.push({
        pathname: urls.search,
        search: `?owner=${ownerId}`,
        state: { ownerName },
    })
}

export const pushHistoryWithTags = (history, tagIds) => {
    history.push({
        pathname: urls.landing,
        search: `?tags=${tagIds.join(',')}`,
    })
}

export const pushHistoryWithTitle = (history, title) => {
    history.push({
        pathname: urls.search,
        search: `?title=${title}`,
        state: { searchTitle: title },
    })
}

export class BaseUrlHandler {
    constructor(location, basename) {
        this.baseUrl = `${location.protocol}//${location.hostname}${basename}`
        this.baseUrlAtTheBeginningRe = new RegExp(`^${this.baseUrl}`)
    }

    startsWithBaseUrl = (url) => {
        return url.startsWith(this.baseUrl)
    }

    stripBaseUrl = (url) => {
        return url.replace(this.baseUrlAtTheBeginningRe, '/')
    }
}

const urlHandler = new BaseUrlHandler(location, settings.basename)
export const startsWithBaseUrl = urlHandler.startsWithBaseUrl
export const stripBaseUrl = urlHandler.stripBaseUrl

export const getPrivacyPolicyUrlByRealm = (realm) => {
    const mapping = {
        eu: 'http://legal.eu.wargaming.net/en/privacy-policy/',
        us: 'http://legal.na.wargaming.net/en/privacy-policy/',
        ru: 'http://legal.ru.wargaming.net/ru/privacy-policy/',
        sg: 'http://legal.asia.wargaming.net/en/privacy-policy/',
    }
    return mapping[realm] || '#'
}
