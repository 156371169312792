import React from 'react'
import PropTypes from 'prop-types'

import {
    ButtonYellowSmall,
    Caption,
} from 'components'

import { ModVersionUploadForm } from 'containers'

import {
    EDIT_UPLOAD_UPDATE_ADD_LABEL,
    EDIT_UPLOAD_UPDATE_ADD_BUTTON,
} from './translations'

import styles from './EditUploadUpdateAdd.scss'

export default class EditUploadUpdateAdd extends React.PureComponent {
    static propTypes = {
        isModVersionUploadFormVisible: PropTypes.bool.isRequired,
        modId: PropTypes.number.isRequired,

        onSetModVersionUploadFormVisibility: PropTypes.func.isRequired,
    }

    handleSetFormVisible = () => {
        this.props.onSetModVersionUploadFormVisibility(!this.props.isModVersionUploadFormVisible)
    }

    renderButton() {
        if (this.props.isModVersionUploadFormVisible) {
            return
        }

        return (
            <div className={styles.row}>
                <div className={styles.label}>
                    <Caption isLarge>
                        {EDIT_UPLOAD_UPDATE_ADD_LABEL}
                    </Caption>
                </div>
                <div className={styles.field}>
                    <ButtonYellowSmall glyph="plus" onClick={this.handleSetFormVisible}>
                        {EDIT_UPLOAD_UPDATE_ADD_BUTTON}
                    </ButtonYellowSmall>
                </div>
            </div>
        )
    }

    renderForm() {
        if (!this.props.isModVersionUploadFormVisible) {
            return
        }

        return (
            <ModVersionUploadForm
                modId={this.props.modId}
                onFormClose={this.handleSetFormVisible}
            />
        )
    }

    render() {
        return (
            <div className={styles.base}>
                {this.renderButton()}
                {this.renderForm()}
            </div>
        )
    }
}
