import { get, intersection, isEmpty, keys, last, orderBy } from 'lodash'

import { snakeFieldsToCamel } from 'utils/strings'
import { MOD_VERSION_STATUS, MOD_STATE } from 'utils/constants'

const getModTitle = (rawItem, currentLanguageCode) => {
    if (isEmpty(rawItem.localizations)) {
        // eslint-disable-next-line no-console
        console.warn('No localizations found for mod', rawItem.id)
        return '??No title??'
    }
    const filteredData = rawItem.localizations.filter((item) => item.lang.code === currentLanguageCode)
    return isEmpty(filteredData) ? rawItem.localizations[0].title : filteredData[0].title
}

const getTagsData = (tagIds, state) => {
    const stateTagIds = keys(state.tags).map((id) => +id)
    const intersectedIds = intersection(tagIds, stateTagIds)
    return intersectedIds.map((tagId) => ({ id: tagId, caption: state.tags[tagId].title }))
}

export const processGalleryItems = (rawResponseData, state) => {
    const currentLanguageCode = state.locale.language
    try {
        return get(rawResponseData, 'results', []).map((rawItem) => {
            const sortedVersions = orderBy(rawItem.versions, ['id'], ['desc'])
            return {
                authorName: rawItem.author_name,
                cover: rawItem.cover_catalog,
                gameVersion: sortedVersions[0].game_version.version,
                id: rawItem.id,
                mark: parseFloat(rawItem.mark),
                modVersion: sortedVersions[0].version,
                ownerId: rawItem.owner.pk,
                ownerName: rawItem.owner.spa_username,
                tags: getTagsData(rawItem.tags, state),
                title: getModTitle(rawItem, currentLanguageCode),
                versions: sortedVersions.map((item) => ({
                    downloadUrl: item.download_url,
                    gameVersion: item.game_version.version,
                    gameVersionId: item.game_version.id,
                    id: item.id,
                    modVersion: item.version,
                    versionFileOriginalName: last(item.version_file_original_name.split('/')),
                    versionFileSize: item.version_file_size,
                })),
            }
        })
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
        throw e
    }
}

export const processSubscriptions = (rawResponseData) => {
    try {
        return rawResponseData.map((rawItem) => {
            const sortedVersions = orderBy(rawItem.mod.versions, ['id'], ['desc'])
            return {
                id: rawItem.id,
                isUpdated: rawItem.is_updated,
                mod: {
                    cover: rawItem.mod.cover_catalog,
                    id: rawItem.mod.id,
                    mark: parseFloat(rawItem.mod.mark),
                    modVersion: get(sortedVersions, '[0].version'),
                    title: rawItem.mod.title,
                    versions: sortedVersions.map((item) => ({
                        createdAt: get(item, 'created_at'),
                        downloadUrl: item.download_url,
                        id: item.id,
                        modVersion: item.version,
                        versionFileOriginalName: last(item.version_file_original_name.split('/')),
                        versionFileSize: item.version_file_size,
                    })),
                },
            }
        })
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
        throw e
    }
}

export const processNews = (rawResponseData) => {
    try {
        return rawResponseData.map((rawItem) => ({
            id: rawItem.id,
            content: rawItem.content,
            title: rawItem.title,
            publishedAt: rawItem.published_at,
        }))
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
    }
}

export const processModderSectionItems = (rawResponseData, state) => {
    const currentLanguageCode = state.locale.language
    try {
        return rawResponseData.map((rawItem) => {
            const sortedVersions = orderBy(rawItem.versions, ['id'], ['desc'])
            return {
                authorName: rawItem.author_name,
                cover: rawItem.cover_catalog,
                gameVersion: get(sortedVersions, '[0].game_version.version'),
                id: rawItem.id,
                isHidden: rawItem.is_hidden,
                mark: parseFloat(rawItem.mark),
                modVersion: get(sortedVersions, '[0].version'),
                ownerId: rawItem.owner.pk,
                ownerName: rawItem.owner.spa_username,
                tags: getTagsData(rawItem.tags, state),
                title: getModTitle(rawItem, currentLanguageCode),
                versions: sortedVersions.map((item) => ({
                    gameVersion: get(item, 'game_version.version'),
                    gameVersionId: get(item, 'game_version.id'),
                    id: item.id,
                    modVersion: item.version,
                    status: item.status,
                    versionFile: item.version_file,
                    versionFileOriginalName: last(item.version_file_original_name.split('/')),
                    versionFileSize: item.version_file_size,
                })),
                state: rawItem.state,
            }
        })
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
        throw e
    }
}

export const processModDetailsData = (rawResponseData, state) => {
    const currentLanguageCode = state.locale.language
    const versionsFilterPredicate = (item) => (
        item.status === MOD_VERSION_STATUS.PUBLISHED &&
        item.is_visible
    )
    const sortedVersions = orderBy(rawResponseData.versions, ['id'], ['desc'])

    return {
        authorName: rawResponseData.author_name,
        changelogs: rawResponseData.change_log.map((item) => ({
            modVersion: item.version,
            content: item.body,
        })),
        createdAt: rawResponseData.created_at,
        cover: rawResponseData.cover,
        descriptions: rawResponseData.localizations.map((item) => ({
            language: item.lang.code,
            content: item.description,
        })),
        downloads: rawResponseData.downloads,
        gameVersion: sortedVersions[0].game_version.version,
        installationGuides: rawResponseData.localizations.map((item) => ({
            language: item.lang.code,
            content: item.installation_guide,
        })),
        isSubscribed: rawResponseData.is_following,
        isReported: rawResponseData.is_reported,
        mark: parseFloat(rawResponseData.mark),
        markVotesCount: rawResponseData.mark_votes_count,
        modVersion: sortedVersions[0].version,
        ownerId: rawResponseData.owner.pk,
        ownerName: rawResponseData.owner.spa_username,
        screenshots: rawResponseData.screenshots.map((item) => snakeFieldsToCamel(item)),
        tags: getTagsData(rawResponseData.tags, state),
        title: getModTitle(rawResponseData, currentLanguageCode),
        updatedAt: rawResponseData.updated_at,
        userVote: rawResponseData.user_vote,
        versions: sortedVersions.filter(versionsFilterPredicate).map((item) => ({
            downloadUrl: item.download_url,
            gameVersion: item.game_version.version,
            gameVersionId: item.game_version.id,
            id: item.id,
            modVersion: item.version,
            versionFileOriginalName: last(item.version_file_original_name.split('/')),
            versionFileSize: item.version_file_size,
        })),
    }
}

export const processModEditDetailsData = (rawResponseData, state) => {
    const currentLanguageCode = state.locale.language
    const byLanguagesReducer = (accumulator, item) => {
        accumulator[item.lang.code] = {
            description: item.description,
            title: item.title,
            installationGuide: item.installation_guide,
        }
        return accumulator
    }

    return {
        authorName: rawResponseData.author_name,
        byLanguages: rawResponseData.localizations.reduce(byLanguagesReducer, {}),
        categories: getTagsData(rawResponseData.tags, state),
        changelog: processChangelogHistoryItems(rawResponseData).changelogForCurrentVersion,
        cover: rawResponseData.cover,
        isHidden: rawResponseData.is_hidden,
        screenshots: rawResponseData.screenshots.map((item) => ({
            position: item.position,
            screenshotId: item.id,
            source: item.source,
        })),
        state: rawResponseData.state,
        status: rawResponseData.status,
        title: getModTitle(rawResponseData, currentLanguageCode),
        versions: orderBy(rawResponseData.versions, ['id'], ['desc']).map((item) => ({
            comment: item.comment,
            createdAt: item.created_at,
            gameVersion: item.game_version.version,
            gameVersionId: item.game_version.id,
            id: item.id,
            isVisible: item.is_visible,
            modVersion: item.version,
            status: item.status,
            versionFile: item.version_file,
            versionFileOriginalName: last(item.version_file_original_name.split('/')),
            versionFileSize: item.version_file_size,
        })),
    }
}

export const processModEditComments = (rawResponseData) => {
    return rawResponseData.map((item) => {
        const result = snakeFieldsToCamel(item)
        result.modVersionId = result.modVersionData.id
        result.modVersion = result.modVersionData.version
        return result
    })
}

export const processModEditVersionAdd = (rawResponseData) => {
    return {
        createdAt: rawResponseData.created_at,
        gameVersion: rawResponseData.game_version_data.version,
        gameVersionId: rawResponseData.game_version_data.id,
        id: rawResponseData.id,
        isVisible: rawResponseData.is_visible,
        modVersion: rawResponseData.version,
        status: rawResponseData.status,
        versionFile: rawResponseData.version_file,
        versionFileOriginalName: last(rawResponseData.version_file_original_name.split('/')),
        versionFileSize: rawResponseData.version_file_size,
    }
}

export const processChangelogHistoryItems = (rawResponseData) => {
    let changelogForCurrentVersion = null
    let changelogForPreviousVersions = rawResponseData.change_log
    const modState = rawResponseData.state

    if ([MOD_STATE.DRAFT, MOD_STATE.REJECTED].includes(modState)) {
        const modVersion = rawResponseData.versions[0].version
        changelogForCurrentVersion = changelogForPreviousVersions.find(item => item.version === modVersion) || null
        if (changelogForCurrentVersion) {
            changelogForPreviousVersions = changelogForPreviousVersions.filter(item => item.version !== modVersion)
        }
    }

    const result = {
        changelogForPreviousVersions: changelogForPreviousVersions.map((item, index) => ({
            version: item.version,
            content: item.body,
            id: index,
        })),
        changelogForCurrentVersion: changelogForCurrentVersion ? {
            version: changelogForCurrentVersion.version,
            content: changelogForCurrentVersion.body,
        } : null,
    }

    return result
}
