import React from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { keys, unionBy } from 'lodash'

import { openDialog } from 'actions/Dialog'
import {
    addChangelogHistoryItem,
    clearChangelogHistoryItems,
    editChangelogHistoryItem,
    setChangelogHistoryOrdering,
} from 'actions/ChangelogHistory'
import { saveMod } from 'actions/CreateMod'

import CreateMod from 'components/CreateMod/CreateMod'

import {
    DialogConfirmationChangelogHistoryItemRemove,
    DialogEdgeAlert,
    DialogIEAlert,
} from 'containers'

import settings from 'settings'

const mapStateToProps = (state) => ({
    categories: keys(state.tags).map((tagId) => ({ id: +tagId, caption: state.tags[tagId].title })),
    changelogHistory: state.changelogHistory.items,
    gameVersions: unionBy(settings.unreleasedGameVersions, settings.gameVersions, 'id'),
    isIEAlertAccepted: state.persistedValues.isIEAlertAccepted,
    isEdgeAlertAccepted: state.persistedValues.isEdgeAlertAccepted,
    isUserBanned: state.currentAccount.isBanned,
    isUserLoggedIn: !!state.currentAccount.spaId,
    isUserStatisticsSufficient: state.currentAccount.isUserStatisticsSufficient,
    language: state.currentAccount.lang,
    realm: state.currentAccount.realm,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    onChangelogConfirmationDialogShow: (id) => {
        dispatch(openDialog(<DialogConfirmationChangelogHistoryItemRemove id={id} />))
    },
    onChangelogHistoryItemAdd: (content, version) => {
        dispatch(addChangelogHistoryItem(content, version))
    },
    onChangelogHistoryItemEdit: (id, content, version) => {
        dispatch(editChangelogHistoryItem(id, content, version))
    },
    onChangelogHistoryItemsReordered: (orderedIds) => {
        dispatch(setChangelogHistoryOrdering(orderedIds))
    },
    onComponentWillMount: () => {
        dispatch(clearChangelogHistoryItems())
    },
    onFormSubmit: (formData) => {
        dispatch(saveMod(formData, ownProps.history))
    },
    onShowEdgeConfirmationDialog: () => {
        dispatch(openDialog(<DialogEdgeAlert />))
    },
    onShowIEConfirmationDialog: () => {
        dispatch(openDialog(<DialogIEAlert />))
    },
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CreateMod),
)
