import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

export const ERROR_EMPTY_SUBSCRIPTIONS_MESSAGE = (
    <FormattedHTMLMessage
        id="error.empty_subscriptions.message"
        defaultMessage="У вас ещё нет подписок. Чтобы подписаться на мод,<br />на странице мода нажмите на кнопку:"
    />
)

export const ERROR_EMPTY_SUBSCRIPTIONS_BUTTON = (
    <FormattedMessage
        id="error.empty_subscriptions.button"
        defaultMessage="К списку модов"
    />
)
