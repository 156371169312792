import { keys, map, union } from 'lodash'
import { batchActions } from 'redux-batched-actions'
import { parse } from 'query-string'

import {
    GALLERY_ITEMS_FILTER_TYPES,
    GALLERY_ITEMS_FILTERED_PER_PAGE,
    GALLERY_ITEMS_PER_PAGE,
    GALLERY_ITEMS_TYPES,
} from 'utils/constants'
import { fetchWithSpinner, fetchWrapper as fetch } from 'utils/fetch'
import { processGalleryItems } from 'utils/responseProcessing'
import { parseIdsQueryPart } from 'utils/filtration'
import urls from 'apiUrls'

/*
    Fetching data displayed at the landing page
 */
export const INCREMENT_FETCHING_OFFSET = 'INCREMENT_FETCHING_OFFSET'
export const RESET_ITEMS = 'RESET_ITEMS'
export const RESET_FILTER = 'RESET_FILTER'
export const SET_ERROR_FETCHING_GALLERY_ITEMS = 'SET_ERROR_FETCHING_GALLERY_ITEMS'
export const START_FETCHING_GALLERY_ITEMS = 'START_FETCHING_GALLERY_ITEMS'
export const STOP_FETCHING_GALLERY_ITEMS = 'STOP_FETCHING_GALLERY_ITEMS'
export const UPDATE_GALLERY_ITEMS = 'UPDATE_GALLERY_ITEMS'

export const startFetchingGalleryItems = (itemsType) => ({
    type: START_FETCHING_GALLERY_ITEMS,
    payload: {
        itemsType,
    },
})

export const stopFetchingGalleryItems = (itemsType) => ({
    type: STOP_FETCHING_GALLERY_ITEMS,
    payload: {
        itemsType,
    },
})

export const updateGalleryItems = (itemsType, rawResponseData, state) => ({
    type: UPDATE_GALLERY_ITEMS,
    payload: {
        items: processGalleryItems(rawResponseData, state),
        count: rawResponseData.count,
        itemsType,
    },
})

export const setErrorFetchingGalleryItems = (itemsType, errorData) => ({
    type: SET_ERROR_FETCHING_GALLERY_ITEMS,
    payload: {
        itemsType,
        errorData,
    },
})

export const incrementFetchingOffset = (itemsType) => ({
    type: INCREMENT_FETCHING_OFFSET,
    payload: { itemsType },
})

export const resetGalleryItems = () => ({
    type: RESET_ITEMS,
})

export const resetGalleryFilter = () => ({
    type: RESET_FILTER,
})

const prepareQueryParamsForFetching = (itemsType, state) => {
    let query

    switch (itemsType) {
        case GALLERY_ITEMS_TYPES.RECOMMENDED: {
            query = {
                limit: GALLERY_ITEMS_PER_PAGE[itemsType],
                offset: state.gallery[itemsType].offset,
                ordering: '-rating',
                recommended_only: 1,
            }
            if (state.gallery.overallFilters.gameVersion !== 'all') {
                query.game_version_id = state.gallery.overallFilters.gameVersion
            }
            break
        }
        case GALLERY_ITEMS_TYPES.NEW: {
            query = {
                limit: GALLERY_ITEMS_PER_PAGE[itemsType],
                offset: state.gallery[itemsType].offset,
                ordering: '-first_time_published_at',
            }
            break
        }
        case GALLERY_ITEMS_TYPES.UPDATED: {
            query = {
                limit: GALLERY_ITEMS_PER_PAGE[itemsType],
                offset: state.gallery[itemsType].offset,
                ordering: '-updated_at',
            }
            break
        }
    }
    query.language = state.persistedValues.overallLanguageFilter || state.gallery.overallFilters.language
    query.realm = state.currentAccount.realm
    return query
}

const getFilterParamsFromQuery = (location, state) => {
    const queryString = parse(location.search)
    if (!keys(queryString).includes(GALLERY_ITEMS_FILTER_TYPES.TAGS)) {
        return null
    }

    const tagIds = parseIdsQueryPart(queryString[GALLERY_ITEMS_FILTER_TYPES.TAGS])
    if (!tagIds) {
        return null
    }

    return {
        argument: tagIds,
        ordering: state.gallery.filtered.filterParams.ordering,
        type: GALLERY_ITEMS_FILTER_TYPES.TAGS,
    }
}

export const fetchAllGalleryItemsInitially = (location) => {
    // Fetches all items for landing, with or without filtering by tags depending on `location` parameter.
    // Invoked during the Gallery component mounting or props receiving with modified location.
    return (dispatch, getState) => {
        const state = getState()
        const filterParamsFromQuery = getFilterParamsFromQuery(location, state)

        dispatch(resetGalleryItems())
        if (filterParamsFromQuery) {
            dispatch(filterGalleryItems(filterParamsFromQuery))
        } else {
            dispatch(resetGalleryFilter())
            dispatch(fetchGalleryStartPage())
        }
    }
}

export const fetchGalleryItemsByType = (itemsType, withSpinner = true) => {
    // Fetches items for landing section specified by `itemsType`. Invoked from Gallery component by `Load More` click
    // or by some actions.
    return (dispatch, getState) => {
        const state = getState()
        if (state.gallery[itemsType].isFetchedAll) {
            return
        }

        dispatch(startFetchingGalleryItems(itemsType))

        const query = prepareQueryParamsForFetching(itemsType, state)
        const fetchPromise = fetch(urls.mods, { query }).promise
            .then((rawResponseData) => {
                dispatch(batchActions([
                    updateGalleryItems(itemsType, rawResponseData, state),
                    stopFetchingGalleryItems(itemsType),
                ], UPDATE_GALLERY_ITEMS))
            }, (errorData) => {
                dispatch(batchActions([
                    setErrorFetchingGalleryItems(itemsType, errorData),
                    stopFetchingGalleryItems(itemsType),
                ], SET_ERROR_FETCHING_GALLERY_ITEMS))
            })
        return withSpinner ? fetchWithSpinner(dispatch, fetchPromise) : fetchPromise
    }
}

export const fetchGalleryStartPage = () => {
    return (dispatch, getState) => {
        const state = getState()

        let actions = map(GALLERY_ITEMS_TYPES, (itemsType) => startFetchingGalleryItems(itemsType))
        dispatch(batchActions(actions, START_FETCHING_GALLERY_ITEMS))

        const query = {
            language: state.persistedValues.overallLanguageFilter || state.gallery.overallFilters.language,
            [`limit_${GALLERY_ITEMS_TYPES.RECOMMENDED}`]: GALLERY_ITEMS_PER_PAGE[GALLERY_ITEMS_TYPES.RECOMMENDED],
            [`limit_${GALLERY_ITEMS_TYPES.NEW}`]: GALLERY_ITEMS_PER_PAGE[GALLERY_ITEMS_TYPES.NEW],
            [`limit_${GALLERY_ITEMS_TYPES.UPDATED}`]: GALLERY_ITEMS_PER_PAGE[GALLERY_ITEMS_TYPES.UPDATED],
        }
        if (state.gallery.overallFilters.gameVersion !== 'all') {
            query.game_version_id = state.gallery.overallFilters.gameVersion
        }
        const fetchPromise = fetch(urls.modsStartPage, { query }).promise
            .then((rawResponseData) => {
                actions = map(GALLERY_ITEMS_TYPES, (itemsType) =>
                    updateGalleryItems(itemsType, rawResponseData[itemsType], state))
                actions = union(actions, map(GALLERY_ITEMS_TYPES, (itemsType) =>
                    stopFetchingGalleryItems(itemsType),
                ))
                dispatch(batchActions(actions, UPDATE_GALLERY_ITEMS))
            }, (errorData) => {
                actions = map(GALLERY_ITEMS_TYPES, (itemsType) => setErrorFetchingGalleryItems(itemsType, errorData))
                actions = union(actions, map(GALLERY_ITEMS_TYPES, (itemsType) => stopFetchingGalleryItems(itemsType)))
                dispatch(batchActions(actions, SET_ERROR_FETCHING_GALLERY_ITEMS))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

/*
    Fetching data displayed at the special pages with "filtering", e.g. search results, author's mods.
*/
export const CHANGE_GALLERY_FILTERED_ITEMS_ORDERING = 'CHANGE_GALLERY_FILTERED_ITEMS_ORDERING'
export const INCREMENT_FILTERING_OFFSET = 'INCREMENT_FILTERING_OFFSET'
export const SET_ERROR_FILTERING_GALLERY_ITEMS = 'SET_ERROR_FILTERING_GALLERY_ITEMS'
export const START_FILTERING_GALLERY_ITEMS = 'START_FILTERING_GALLERY_ITEMS'
export const STOP_FILTERING_GALLERY_ITEMS = 'STOP_FILTERING_GALLERY_ITEMS'
export const UPDATE_GALLERY_FILTERED_ITEMS = 'UPDATE_GALLERY_FILTERED_ITEMS'

export const startFilteringGalleryItems = () => ({
    type: START_FILTERING_GALLERY_ITEMS,
})

export const stopFilteringGalleryItems = () => ({
    type: STOP_FILTERING_GALLERY_ITEMS,
})

export const updateGalleryFilteredItems = (filterParams, rawResponseData, state) => ({
    type: UPDATE_GALLERY_FILTERED_ITEMS,
    payload: {
        items: processGalleryItems(rawResponseData, state),
        count: rawResponseData.count,
        filterParams,
    },
})

export const setErrorFilteringGalleryItems = (errorData) => ({
    type: SET_ERROR_FILTERING_GALLERY_ITEMS,
    payload: {
        errorData,
    },
})

export const incrementFilteringOffset = () => ({
    type: INCREMENT_FILTERING_OFFSET,
})

export const changeGalleryFilteredItemsOrdering = (ordering) => ({
    type: CHANGE_GALLERY_FILTERED_ITEMS_ORDERING,
    payload: {
        ordering,
    },
})

const prepareQueryParamsForFiltering = (filterParams, state) => {
    const { type, argument } = filterParams
    let query = {
        language: state.gallery.overallFilters.language,
    }

    if (type === GALLERY_ITEMS_FILTER_TYPES.TAGS) {
        query = {
            ...query,
            limit: GALLERY_ITEMS_FILTERED_PER_PAGE,
            offset: state.gallery.filtered.offset,
            ordering: filterParams.ordering,
            tag_ids: argument.join(','),
        }
    }

    if (state.gallery.overallFilters.gameVersion !== 'all') {
        query.game_version_id = state.gallery.overallFilters.gameVersion
    }

    return query
}

export const filterGalleryItems = (filterParams) => {
    return (dispatch, getState) => {
        const state = getState()

        dispatch(startFilteringGalleryItems())

        const query = prepareQueryParamsForFiltering(filterParams, state)
        const fetchPromise = fetch(urls.mods, { query }).promise
            .then((rawResponseData) => {
                dispatch(batchActions([
                    updateGalleryFilteredItems(filterParams, rawResponseData, state),
                    stopFilteringGalleryItems(),
                ], UPDATE_GALLERY_FILTERED_ITEMS))
            }, (errorData) => {
                dispatch(batchActions([
                    setErrorFilteringGalleryItems(errorData),
                    stopFilteringGalleryItems(),
                ], SET_ERROR_FILTERING_GALLERY_ITEMS))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

/*
    Overall filters applied for both fetched and filtered items
*/
export const CLEAR_ALREADY_RECEIVED_ITEMS = 'CLEAR_ALREADY_RECEIVED_ITEMS'
export const SET_OVERALL_GAME_VERSION_FILTER = 'SET_OVERALL_GAME_VERSION_FILTER'
export const SET_OVERALL_LANGUAGE_FILTER = 'SET_OVERALL_LANGUAGE_FILTER'

export const setOverallLanguageFilter = (language) => ({
    type: SET_OVERALL_LANGUAGE_FILTER,
    payload: {
        language,
    },
})

export const setOverallGameVersionFilter = (gameVersionId) => ({
    type: SET_OVERALL_GAME_VERSION_FILTER,
    payload: {
        gameVersionId,
    },
})

export const clearAlreadyReceivedItems = () => ({
    type: CLEAR_ALREADY_RECEIVED_ITEMS,
})


export const changeOverallLanguageFilter = (language) => {
    return (dispatch, getState) => {
        const state = getState()

        dispatch(batchActions([
            setOverallLanguageFilter(language),
            clearAlreadyReceivedItems(),
        ], SET_OVERALL_LANGUAGE_FILTER))

        if (state.gallery.filtered.filterParams.type) {
            dispatch(filterGalleryItems(state.gallery.filtered.filterParams))
        } else {
            dispatch(fetchGalleryStartPage())
        }
    }
}

export const changeOverallGameVersionFilter = (gameVersionId) => {
    return (dispatch, getState) => {
        const state = getState()

        dispatch(batchActions([
            setOverallGameVersionFilter(gameVersionId),
            clearAlreadyReceivedItems(),
        ], SET_OVERALL_GAME_VERSION_FILTER))

        if (state.gallery.filtered.filterParams.type) {
            dispatch(filterGalleryItems(state.gallery.filtered.filterParams))
        } else {
            dispatch(fetchGalleryStartPage())
        }
    }
}
