import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Tag.scss'

export default class Tag extends React.PureComponent {
    static propTypes = {
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        caption: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]).isRequired,
        type: PropTypes.oneOf(['light', 'warning']),
        isChecked: PropTypes.bool,
        isError: PropTypes.bool,
        isDisabled: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    }

    handleClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.props.isDisabled ? null : this.props.onClick(this.props.id)
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isChecked]: this.props.isChecked,
            [styles.isError]: this.props.isError,
            [styles.isDisabled]: this.props.isDisabled,
            [styles[`base__${this.props.type}`]]: this.props.type,
        })

        return (
            <div className={classNameBase} onClick={this.handleClick}>
                <div className={styles.caption}>
                    {this.props.caption}
                </div>
                <span className={classNames(styles.hole, styles['hole__left'])} />
                <span className={classNames(styles.hole, styles['hole__right'])} />
            </div>
        )
    }
}
