import React from 'react'
import { FormattedMessage } from 'react-intl'

export const NAV_LINK_MAIN = (
    <FormattedMessage
        id="nav.link.main"
        defaultMessage="Главная"
    />
)

export const NAV_LINK_NEWS = (
    <FormattedMessage
        id="nav.link.news"
        defaultMessage="Новости"
    />
)

export const NAV_LINK_SUBSCRIPTIONS = (
    <FormattedMessage
        id="nav.link.subscriptions"
        defaultMessage="Мои подписки"
    />
)

export const NAV_LINK_MODDER_SECTION = (
    <FormattedMessage
        id="nav.link.modder.section"
        defaultMessage="Раздел мододела"
    />
)
