import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'

import styles from './ButtonYellowOutlineLarge.scss'

export default class ButtonYellowOutlineLarge extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        isWide: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    }

    render() {
        const classNameBase = className(styles.base, {
            [styles.isWide]: this.props.isWide,
        })

        return (
            <button className={classNameBase} onClick={this.props.onClick}>
                {this.props.children}
            </button>
        )
    }
}
