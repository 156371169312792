import React from 'react'
import { FormattedHTMLMessage, FormattedMessage, defineMessages } from 'react-intl'

export const SUBSCRIPTIONS_PAGE_TITLE = (count) => (
    <FormattedHTMLMessage
        id="subscriptions.page.title"
        defaultMessage="Мои подписки: <mark>{count}</mark>"
        values={{
            count,
        }}
    />
)

export const SUBSCRIPTIONS_PAGE_TITLE_WITHOUT_COUNTER = () => (
    <FormattedMessage
        id="subscriptions.page.title.without_counter"
        defaultMessage="Мои подписки"
    />
)

export const SUBSCRIPTIONS_PAGE_UNAUTHORIZED_MESSAGE = (
    <FormattedMessage
        id="subscription.unauthorized"
        defaultMessage="Чтобы увидеть ваши подписки, необходимо войти на сайт."
    />
)

export const SUBSCRIPTIONS_PAGE_LOGIN = (
    <FormattedMessage
        id="subscription.login"
        defaultMessage="Войти"
    />
)


export const SUBSCRIPTIONS_PAGE_LAST_UPDATED_TITLE = (count) => (
    <FormattedHTMLMessage
        id="subscriptions.page.title.last_updated"
        defaultMessage="Последние обновления: <mark>{count}</mark>"
        values={{
            count,
        }}
    />
)

export const SUBSCRIPTIONS_PAGE_FAVORITES_TITLE = (count) => (
    <FormattedHTMLMessage
        id="subscriptions.page.title.favorites"
        defaultMessage="Избранные: <mark>{count}</mark>"
        values={{
            count,
        }}
    />
)

export const SUBSCRIPTIONS_PAGE_MOD_DOWNLOAD_ALL_LBL = (size) => (
    <FormattedHTMLMessage
        id="subscriptions.page.mod.download_all_lbl"
        defaultMessage="Скачать все обновления <mark>({size})</mark>"
        values={{
            size,
        }}
    />
)

export const SUBSCRIPTIONS_PAGE_MOD_DOWNLOAD_FAVORITES = (size) => (
    <FormattedHTMLMessage
        id="subscriptions.page.mod.download_favorites"
        defaultMessage="Скачать все избранные <mark>({size})</mark>"
        values={{
            size,
        }}
    />
)

export const messages = defineMessages({
    subscriptionsTitle: {
        id: 'subscriptions.title',
        defaultMessage: 'Мои подписки — Моды для World of Tanks',
    },
})