import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Tabs.scss'

const OFFSET_STEP = 200
const BUTTONS_WIDTH = 68

export default class Tabs extends React.PureComponent {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            caption: PropTypes.node.isRequired,
        })).isRequired,
        checkedItem: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        onItemChange: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            diffWidth: 0,
            positionLeft: 0,
        }
    }

    baseRefCallback(el) {
        if (el) {
            this._base = el
            this.calculateWidth()
        }
    }

    innerRefCallback(el) {
        if (el) {
            this._inner = el
            this.calculateWidth()
        }
    }

    calculateWidth() {
        if (!(this._base && this._inner)) return

        this.baseWidth = this._base.getBoundingClientRect().width
        this.innerWidth = this._inner.getBoundingClientRect().width

        this.setState({
            diffWidth: this.innerWidth - this.baseWidth,
        })
    }

    handleItemChange = (value) => {
        value !== this.props.checkedItem && this.props.onItemChange(value)
    }

    handlePrevClick = () => {
        let positionLeft = this.state.positionLeft
        positionLeft = Math.abs(positionLeft) < OFFSET_STEP ? 0 : positionLeft + OFFSET_STEP

        this.setState({ positionLeft })
    }

    handleNextClick = () => {
        let positionLeft = this.state.positionLeft
        positionLeft = this.state.diffWidth - Math.abs(positionLeft) < OFFSET_STEP ? -this.state.diffWidth - BUTTONS_WIDTH : positionLeft - OFFSET_STEP,

        this.setState({ positionLeft })
    }

    renderItems() {
        return this.props.items.map((item) => {
            const classNameItem = classNames(styles.item, {
                [styles.isActive] : item.value === this.props.checkedItem,
            })

            return (
                <div
                    key={`tab-${item.value}`}
                    className={classNameItem}
                    onClick={() => this.handleItemChange(item.value)}
                >
                    {item.caption}
                </div>
            )
        })
    }

    renderButtons() {
        const classNamePrev = classNames(styles.button, styles['button__prev'], {
            [styles.isDisabled] : this.state.positionLeft === 0,
        })

        const classNameNext = classNames(styles.button, styles['button__next'], {
            [styles.isDisabled] : this.state.positionLeft === -this.state.diffWidth - BUTTONS_WIDTH,
        })

        return this.state.diffWidth > 0 ? (
            <div className={styles.buttons}>
                <div className={classNamePrev} onClick={this.handlePrevClick} />
                <div className={classNameNext} onClick={this.handleNextClick} />
            </div>
        ) : null
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isWide] : this.state.diffWidth > 0,
        })

        return (
            <div
                className={classNameBase}
                ref={(el) => { this.baseRefCallback(el) }}
            >
                <div className={styles.container}>
                    <div
                        className={styles.inner}
                        style={{ left: this.state.positionLeft }}
                        ref={(el) => { this.innerRefCallback(el) }}
                    >
                        {this.renderItems()}
                    </div>
                </div>
                {this.renderButtons()}
            </div>
        )
    }
}
