import { START_FETCHING, STOP_FETCHING } from 'actions/Processing'

export const processing = (state = { counter: 0 }, action) => {
    switch(action.type) {
        case START_FETCHING:
            return {
                counter: state.counter + 1,
            }
        case STOP_FETCHING:
            return {
                counter: state.counter - 1,
            }
        default:
            return state
    }
}
