import { isNaN } from 'lodash'

export const calculateMark = (mark) => {
    if (isNaN(mark)) {
        return {
            mark: '?',
            percent: 0,
        }
    }

    return {
        mark: mark ? mark.toFixed(1) : 0,
        percent: mark ? (mark.toFixed(1) * 100) / 5 : 0,
    }
}
