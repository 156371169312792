import { connect } from 'react-redux'
import { unionBy } from 'lodash'

import { addModVersion } from 'actions/EditMod'

import ModVersionUploadForm from 'components/Edit/ModVersionUploadForm/ModVersionUploadForm'

import settings from 'settings'

const mapStateToProps = () => ({
    gameVersions: unionBy(settings.unreleasedGameVersions, settings.gameVersions, 'id'),
})

const mapDispatchToProps = (dispatch) => ({
    onFormSubmit: (modId, formData) => {
        dispatch(addModVersion(modId, formData))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(ModVersionUploadForm)
