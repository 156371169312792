import { connect } from 'react-redux'

import {
    addComment,
} from 'actions/EditMod'

import EditComments from 'components/Edit/EditComments/EditComments'

const mapStateToProps = (state) => ({
    comments: state.editMod.comments,
    isErrorComments: state.editMod.isErrorComments,
    isFetchedComments: state.editMod.isFetchedComments,
})

const mapDispatchToProps = (dispatch) => ({
    onCommentAdd: (modId, content) => {
        dispatch(addComment(modId, content))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(EditComments)

