import { batchActions } from 'redux-batched-actions'

import { fetchWithSpinner, fetchWrapper as fetch } from 'utils/fetch'
import { processNews } from 'utils/responseProcessing'
import urls from 'apiUrls'
import {NEWS_ITEMS_LIMIT} from 'utils/constants'

export const SET_ERROR_FETCHING_NEWS = 'SET_ERROR_FETCHING_NEWS'
export const START_FETCHING_NEWS = 'START_FETCHING_NEWS'
export const STOP_FETCHING_NEWS = 'STOP_FETCHING_NEWS'
export const UPDATE_ITEMS_NEWS = 'UPDATE_ITEMS_NEWS'
export const CHANGE_NEWS_PAGE = 'CHANGE_NEWS_PAGE'

const startFetchingNews = () => ({
    type: START_FETCHING_NEWS,
})

const stopFetchingNews = () => ({
    type: STOP_FETCHING_NEWS,
})

const updateItemsNews = (rawResponseData) => ({
    type: UPDATE_ITEMS_NEWS,
    payload: {
        items: processNews(rawResponseData.results),
        count: rawResponseData.count,
    },
})

export const changeNewsPage = (page) => ({
    type: CHANGE_NEWS_PAGE,
    payload: {
        page,
    },
})


const setErrorFetchingNews = (errorData) => ({
    type: SET_ERROR_FETCHING_NEWS,
    payload: {
        errorData,
    },
})

export const fetchNews = () => {
    return (dispatch, getState) => {
        const url = urls.news
        dispatch(startFetchingNews())

        const state = getState()
        const query = {
            limit: NEWS_ITEMS_LIMIT,
            offset: (state.news.page - 1) * NEWS_ITEMS_LIMIT,
        }

        const fetchPromise = fetch(url, { query }).promise
            .then((rawResponseData) => {
                dispatch(batchActions([
                    updateItemsNews(rawResponseData),
                    stopFetchingNews(),
                ], UPDATE_ITEMS_NEWS))
            }, (errorData) => {
                dispatch(batchActions([
                    setErrorFetchingNews(errorData),
                    stopFetchingNews(),
                ], SET_ERROR_FETCHING_NEWS))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}
