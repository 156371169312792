import { get, first, keys, values } from 'lodash'
import { parse } from 'query-string'
import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import ReactRouterPropTypes from 'react-router-prop-types'
import PropTypes from 'prop-types'

import { urls } from 'utils/routing'

import {
    Back,
    ButtonGrayOutlineLarge,
    Error,
    Main,
    Mod,
    TitleMajor,
    Toggle,
} from 'components'

import { FilterGameVersion, FilterLanguage } from 'containers'

import {
    SEARCH_RESULT_FILTER_TYPES,
    SEARCH_RESULT_ITEMS_ORDERING,
} from 'utils/constants'

import {
    COMMON_ERROR,
    LOAD_MORE,
    ORDERING_BY_CHANGED_AT_FILTERED_MODS,
    ORDERING_BY_RATING_FILTERED_MODS,
    TO_LANDING,
} from 'translations'

import {
    messages,
    SEARCH_RESULT_EMPTY,
    SEARCH_RESULT_EMPTY_BY_OWNER,
    SEARCH_RESULT_PAGE_TITLE_BY_OWNER,
    SEARCH_RESULT_PAGE_TITLE_BY_TITLE,
} from './translations'

import styles from './SearchResult.scss'

class SearchResult extends React.Component {
    static propTypes = {
        filtered: PropTypes.shape({
            count: PropTypes.number.isRequired,
            filterParams: PropTypes.shape({
                type: PropTypes.oneOf(values(SEARCH_RESULT_FILTER_TYPES)),
                argument: PropTypes.string,
                ordering: PropTypes.oneOf([
                    SEARCH_RESULT_ITEMS_ORDERING.CHANGED_AT,
                    SEARCH_RESULT_ITEMS_ORDERING.RATING,
                ]),
            }).isRequired,
            isError: PropTypes.bool.isRequired,
            isFetching: PropTypes.bool.isRequired,
            isFetchedAll: PropTypes.bool.isRequired,
            items: PropTypes.arrayOf(PropTypes.shape({
                cover: PropTypes.string,
                gameVersion: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
                mark: PropTypes.number.isRequired,
                modVersion: PropTypes.string.isRequired,
                ownerId: PropTypes.number.isRequired,
                ownerName: PropTypes.string.isRequired,
                tags: PropTypes.arrayOf(PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    caption: PropTypes.string.isRequired,
                })).isRequired,
                title: PropTypes.string.isRequired,
            })).isRequired,
        }),
        isModDownloadAccepted: PropTypes.bool,

        location: ReactRouterPropTypes.location.isRequired,

        intl: intlShape,

        changeItemsOrdering: PropTypes.func.isRequired,
        fetchItems: PropTypes.func.isRequired,
        loadMoreItems: PropTypes.func.isRequired,
        onDownloadClick: PropTypes.func.isRequired,
        resetComponent: PropTypes.func.isRequired,
    }

    componentWillMount() {
        this.props.fetchItems(this.getFilterParams(this.props))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            this.props.fetchItems(this.getFilterParams(nextProps))
        }

        this.setDocumentTitle(nextProps)
    }

    componentWillUnmount() {
        this.props.resetComponent()
    }

    setDocumentTitle(props) {
        let formattedMessage

        if (props.filtered.filterParams.type === SEARCH_RESULT_FILTER_TYPES.TITLE) {
            const title = props.filtered.filterParams.argument
            formattedMessage = this.props.intl.formatMessage(messages.filterByTitle, { title })
        } else {
            const ownerName = this.getOwnerName(props)
            formattedMessage = this.props.intl.formatMessage(messages.filterByOwner, { ownerName })
        }

        document.title = formattedMessage
    }

    getFilterParams(props) {
        const queryString = parse(props.location.search)
        const type = keys(queryString)[0]
        return {
            argument: queryString[type],
            ordering: props.filtered.filterParams.ordering,
            type,
        }
    }

    getOwnerName(props) {
        return props.filtered.items.length ? (
            props.filtered.items[0].ownerName
        ) : get(props.location, 'state.ownerName')
    }

    getSearchTerm(props) {
        return this.props.filtered.filterParams.argument || get(props.location, 'state.searchTitle')
    }

    handleLoadMoreClick = () => {
        this.props.loadMoreItems(this.props.filtered.filterParams)
    }

    handleOrderingChanged = (ordering) => {
        const filterParams = {
            ...this.props.filtered.filterParams,
            ordering,
        }
        this.props.changeItemsOrdering(filterParams)
    }

    handleDownloadClick = (modId, isModDownloaded) => {
        const { title, versions } = this.props.filtered.items.find((item) => (item.id === modId))
        this.props.onDownloadClick(this.props.isModDownloadAccepted, isModDownloaded, modId, title, versions)
    }

    renderTitleMajor() {
        let title

        const count = this.props.filtered.count
        const countTitle = count ? <mark>{count}</mark> : <span className={styles.muted}>{count}</span>

        if (this.props.filtered.filterParams.type === SEARCH_RESULT_FILTER_TYPES.OWNER || get(this.props.location, 'state.ownerName')) {
            const ownerName = <mark>{this.getOwnerName(this.props)}</mark>
            title = SEARCH_RESULT_PAGE_TITLE_BY_OWNER(ownerName, countTitle)
        } else {
            const searchTerm = <mark>“{this.getSearchTerm(this.props)}”</mark>
            title = SEARCH_RESULT_PAGE_TITLE_BY_TITLE(searchTerm, countTitle)
        }

        return (
            <TitleMajor>{title}</TitleMajor>
        )
    }

    renderItem(item) {
        const lastVersion = first(item.versions)

        return (
            <div className={styles.mod} key={`gallery-item-id-${item.id}`}>
                <Mod
                    authorName={item.authorName}
                    cover={item.cover}
                    gameVersion={item.gameVersion}
                    id={item.id}
                    mark={item.mark}
                    modSize={lastVersion.versionFileSize}
                    modVersion={item.modVersion}
                    modVersionFile={lastVersion.downloadUrl}
                    modVersionFileOriginalName={lastVersion.versionFileOriginalName}
                    ownerId={item.ownerId}
                    ownerName={item.ownerName}
                    tags={item.tags}
                    title={item.title}

                    isModDownloadAccepted={this.props.isModDownloadAccepted}
                    isSingleVersionAvailable={item.versions.length === 1}

                    onDownloadClick={this.handleDownloadClick}
                />
            </div>
        )
    }

    renderItems() {
        if (this.props.filtered.isError) {
            return <Error>{COMMON_ERROR}</Error>
        }

        if (this.props.filtered.items.length === 0 && get(this.props.location, 'state.ownerName') && !this.props.filtered.isFetching) {
            return <Error>{SEARCH_RESULT_EMPTY_BY_OWNER}</Error>
        }

        return this.props.filtered.items.length === 0 && !this.props.filtered.isFetching ? (
            <Error>{SEARCH_RESULT_EMPTY}</Error>
        ) : (
            <div className={styles.inner}>
                {this.props.filtered.items.map((item) => this.renderItem(item))}
            </div>
        )
    }

    renderLoadMoreButton() {
        if (this.props.filtered.isFetchedAll || this.props.filtered.items.length === 0) {
            return null
        }

        return (
            <div className={styles.button}>
                <ButtonGrayOutlineLarge onClick={this.handleLoadMoreClick}>
                    {LOAD_MORE}
                </ButtonGrayOutlineLarge>
            </div>
        )
    }

    render() {
        return (
            <Main>
                <div className={styles.base}>
                    <div className={styles.head}>
                        <Back caption={TO_LANDING} to={urls.landing} />
                        {this.renderTitleMajor()}
                        <div className={styles.filter}>
                            <FilterLanguage isUsedForSearch />
                            <div className={styles.filterAdditional}>
                                <div className={styles.toggle}>
                                    <Toggle
                                        items={[
                                            {
                                                value: SEARCH_RESULT_ITEMS_ORDERING.RATING,
                                                caption: ORDERING_BY_RATING_FILTERED_MODS,
                                            },
                                            {
                                                value: SEARCH_RESULT_ITEMS_ORDERING.CHANGED_AT,
                                                caption: ORDERING_BY_CHANGED_AT_FILTERED_MODS,
                                            },
                                        ]}
                                        isDisabled={this.props.filtered.items.length === 0}
                                        checkedItem={this.props.filtered.filterParams.ordering}
                                        onItemChange={this.handleOrderingChanged}
                                    />
                                </div>
                                <FilterGameVersion isUsedForSearch />
                            </div>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <section className={styles.section}>
                            {this.renderItems()}
                            {this.renderLoadMoreButton()}
                        </section>
                    </div>
                </div>
            </Main>
        )
    }
}

export default injectIntl(SearchResult)
