import React from 'react'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'

export const EDIT_COMMENTS_ITEM_MOD_SUMMARY_VERSION = (modVersion, gameVersion) => (
    <FormattedHTMLMessage
        id="edit.comments.mod.summary.version"
        defaultMessage="<mark>{modVersion}</mark>{NBSP}для{NBSP}<mark>WoT{NBSP}{gameVersion}</mark>"
        values={{
            NBSP: '\u00a0',
            modVersion,
            gameVersion,
        }}
    />
)

export const EDIT_COMMENTS_MODERATOR_CAPTION = (
    <FormattedMessage
        id="edit.comments.moderator.caption"
        defaultMessage="Модератор"
    />
)
