import React from 'react'
import { FormattedMessage } from 'react-intl'
import { MOD_STATE, MOD_VERSION_STATUS } from 'utils/constants'

import { formatWithSeparatedThousands } from 'utils/formatting'

import { ExternalLink } from 'components'

export const TO_LANDING = (
    <FormattedMessage
        id="to.landing"
        defaultMessage="На главную страницу"
    />
)

export const TO_MODDER_SECTION = (
    <FormattedMessage
        id="to.modder.section"
        defaultMessage="К моим модам"
    />
)

export const LOAD_MORE = (
    <FormattedMessage
        id="load.more"
        defaultMessage="Показать больше"
    />
)

export const ORDERING_BY_RATING_FILTERED_MODS = (
    <FormattedMessage
        id="ordering.by.rating.filtered.mods"
        defaultMessage="По популярности"
    />
)

export const ORDERING_BY_CHANGED_AT_FILTERED_MODS = (
    <FormattedMessage
        id="ordering.by.changed.at.filtered.mods"
        defaultMessage="По дате"
    />
)

export const COMMON_ERROR = (
    <FormattedMessage
        id="error"
        defaultMessage="Что-то пошло не так. Попробуйте обновить страницу."
    />
)

export const GO_TO_LANDING = (
    <FormattedMessage
        id="go.to.landing"
        defaultMessage="На главную страницу"
    />
)

const published = (
    <FormattedMessage
        id="mod.state.published"
        defaultMessage="Опубликован"
    />
)

const draft = (
    <FormattedMessage
        id="mod.state.draft"
        defaultMessage="Черновик"
    />
)

const rejected = (
    <FormattedMessage
        id="mod.state.declined"
        defaultMessage="Отклонён"
    />
)

const inReview = (
    <FormattedMessage
        id="mod.state.onmoderation"
        defaultMessage="Проверяется"
    />
)

const hidden = (
    <FormattedMessage
        id="mod.state.hidden"
        defaultMessage="Скрыт"
    />
)

const publishedUpdateInReview = (
    <FormattedMessage
        id="mod.state.publishedUpdateInReview"
        defaultMessage="Обновление проверяется"
    />
)

const publishedUpdateRejected = (
    <FormattedMessage
        id="mod.state.publishedUpdateRejected"
        defaultMessage="Обновление отклонено"
    />
)

export const RESET_CAPTION = (
    <FormattedMessage
        id="reset.caption"
        defaultMessage="Сбросить"
    />
)

export const CANCEL_CAPTION = (
    <FormattedMessage
        id="cancel.caption"
        defaultMessage="Отменить"
    />
)

export const SAVE_BUTTON_CAPTION = (
    <FormattedMessage
        id="save.button.caption"
        defaultMessage="Сохранить"
    />
)

export const MOD_STATE_TITLES = {
    [MOD_STATE.PUBLISHED]: published,
    [MOD_STATE.DRAFT]: draft,
    [MOD_STATE.REJECTED]: rejected,
    [MOD_STATE.IN_REVIEW]: inReview,
    [MOD_STATE.HIDDEN]: hidden,
    [MOD_STATE.PUBLISHED_UPDATE_IN_REVIEW]: publishedUpdateInReview,
    [MOD_STATE.PUBLISHED_UPDATE_REJECTED]: publishedUpdateRejected,
}

export const MOD_VERSION_STATUS_TITLES = {
    [MOD_VERSION_STATUS.PENDING_REVIEW]: (
        <FormattedMessage
            id="mod.version.status.pending_review"
            defaultMessage="Проверяется"
        />
    ),
    [MOD_VERSION_STATUS.PUBLISHED]: (
        <FormattedMessage
            id="mod.version.status.published"
            defaultMessage="Опубликовано"
        />
    ),
    [MOD_VERSION_STATUS.REJECTED]: (
        <FormattedMessage
            id="mod.version.status.rejected"
            defaultMessage="Отклонено"
        />
    ),
}

export const FIELD_MAX_LIMIT_EXCEED_ERROR = (maxChars) => {
    return (
        <FormattedMessage
            id="field.max.limit.exceed.error"
            defaultMessage="Превышен допустимый лимит количества символов: {maxChars}"
            values={{
                maxChars: formatWithSeparatedThousands(maxChars),
            }}
        />
    )
}

export const FIELD_MIN_LIMIT_EXCEED_ERROR = (minChars) => {
    return (
        <FormattedMessage
            id="field.min.limit.exceed.error"
            defaultMessage="Вы не ввели минимальное количество символов: {minChars}"
            values={{ minChars }}
        />
    )
}

export const PUBLISH_ATTENTION_1 = (link) => (
    <FormattedMessage
        id="publish.attention.1"
        defaultMessage="В целях безопасности не указывайте здесь личную информацию о себе или о ком-либо другом, за исключением поля «Автор мода», так как мы не сможем защитить её в соответствии с {link}."
        values={{
            link: (
                <ExternalLink to={link}>
                    <FormattedMessage
                        id="create.attention.1.link"
                        defaultMessage="Политикой конфиденциальности Wargaming"
                    />
                </ExternalLink>
            ),
        }}
    />
)

export const PUBLISH_ATTENTION_2 = (link) => (
    <FormattedMessage
        id="publish.attention.2"
        defaultMessage="Размещая этот мод, вы соглашаетесь с {link}"
        values={{
            link: (
                <ExternalLink to={link}>
                    <FormattedMessage
                        id="create.attention.2.link"
                        defaultMessage="Правилами использования портала модов"
                    />
                </ExternalLink>
            ),
        }}
    />
)

export const SEND_TO_CHECK_BUTTON = (
    <FormattedMessage
        id="send.to.check.button"
        defaultMessage="Отправить на проверку"
    />
)

export const SAVE = (
    <FormattedMessage
        id="save"
        defaultMessage="Сохранить"
    />
)

export const SAVE_AS_DRAFT = (
    <FormattedMessage
        id="save.as.draft"
        defaultMessage="Сохранить как черновик"
    />
)

export const SAVE_CHANGES = (
    <FormattedMessage
        id="save.changes"
        defaultMessage="Сохранить изменения"
    />
)

export const EDIT_UPLOAD_INVALID_FILE_TYPE_ERROR = (
    <FormattedMessage
        id="edit.upload.invalid.file.type.error"
        defaultMessage="Вы загружаете файл неверного формата"
    />
)

export const EDIT_UPLOAD_INVALID_FILE_SIZE_ERROR = (
    <FormattedMessage
        id="edit.upload.invalid.file.size.error"
        defaultMessage="Вы загружаете слишком большой файл"
    />
)

export const EDIT_UPLOAD_INVALID_FILE_DIMENSION_ERROR = (
    <FormattedMessage
        id="edit.upload.invalid.file.dimension.error"
        defaultMessage="Загружаемое изображение должно иметь разрешение не менее 302x170"
    />
)

export const EDIT_UPLOAD_COMMON_ERROR = (
    <FormattedMessage
        id="edit.upload.common.error"
        defaultMessage="Не удалось загрузить файл. Попробуйте ещё раз."
    />
)

export const MESSAGE_IS_NOT_SENT = (
    <FormattedMessage
        id="message.is.not.sent"
        defaultMessage="Не удалось отправить сообщение"
    />
)

export const MOD_WAS_DELETED = (
    <FormattedMessage
        id="mod.was.deleted"
        defaultMessage="Этот мод был удалён с нашего сайта"
    />
)

export const ALREADY_SENT_REPORT_TITLE = (
    <FormattedMessage
        id="you.have.already.sent_report"
        defaultMessage="Вы уже отправили сообщение о проблеме"
    />
)

export const ALREADY_SENT_REPORT_DETAILS = (
    <FormattedMessage
        id="you.have.already.sent_report.details"
        defaultMessage="Вы уже отправили сообщение о проблеме с этим модом.{br}Мы обязательно проверим мод на наличие проблем.{br}Спасибо вам большое!"
        values={{
            br: <br />,
        }}
    />
)

export const ATTENTION = (
    <FormattedMessage
        id="attention"
        defaultMessage="Обратите внимание:"
    />
)

export const CLOSE = (
    <FormattedMessage
        id="close"
        defaultMessage="Закрыть"
    />
)

export const CREATE_NOTIFICATION_REQUIRED_CONTENT = (
    <FormattedMessage
        id="create.notification.required.content"
        defaultMessage="Для публикации мода, вам необходимо заполнить все поля, отмеченные {required}{br}{br}Для сохранения черновика достаточно заполнить поле «НАЗВАНИЕ МОДА»."
        values={{
            required: (<mark>*</mark>),
            br: <br />,
        }}
    />
)

export const CREATE_NOTIFICATION_ERROR_LIMIT_CONTENT = (
    <FormattedMessage
        id="create.notification.error.limit.content"
        defaultMessage="Превышен допустимый лимит количества символов при заполнении некоторых полей"
    />
)


// Please rename my id after release...
export const ALL_CAPTION = (
    <FormattedMessage
        id="categories.tag.all.caption"
        defaultMessage="Все"
    />
)

export const ALL_GAME_VERSIONS = { id: 'all', version: ALL_CAPTION }
