import { connect } from 'react-redux'

import { loadLocale } from 'actions/Locale'
import IntlWrapper from 'components/IntlWrapper/IntlWrapper'

const mapStateToProps = (state) => ({
    messages: state.locale.messages,
    language: state.currentAccount.lang,
})

const mapDispatchToProps = { loadLocale }

export default connect(mapStateToProps, mapDispatchToProps)(IntlWrapper)
