import { keys } from 'lodash'
import { connect } from 'react-redux'

import { GALLERY_ITEMS_FILTER_TYPES } from 'utils/constants'

import Categories from 'components/UI/Categories/Categories'

const mapStateToProps = (state) => {
    return {
        tags: keys(state.tags).map((tagId) => ({ id: +tagId, caption: state.tags[tagId].title })),
        checkedTags: state.gallery.filtered.filterParams.type === GALLERY_ITEMS_FILTER_TYPES.TAGS ?
            state.gallery.filtered.filterParams.argument :
            [],
    }
}

export default connect(mapStateToProps)(Categories)
