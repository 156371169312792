import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'

import {
    Divider,
    Paginator,
} from 'components'
import { formatDate } from 'utils/formatting'
import { NEWS_ITEMS_LIMIT } from 'utils/constants'
import { scrollToElement } from 'utils/domManipulation'

import styles from './NewsHeadersList.scss'

const SCROLL_DURATION = 500

export default class NewsHeadersList extends React.Component {
    static propTypes = {
        activeItemId: PropTypes.number,
        count: PropTypes.number.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            publishedAt: PropTypes.string.isRequired,
        })),
        page: PropTypes.number.isRequired,

        onChangeNewsPage: PropTypes.func.isRequired,
        onItemClick: PropTypes.func.isRequired,
    }

    componentDidUpdate() {
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }

        const activeItemRef = this[`_item-${this.props.activeItemId}`]

        if (activeItemRef) {
            const activeItemTop = activeItemRef.offsetTop + activeItemRef.offsetHeight

            if (activeItemTop > this._wrapper.scrollTop + this._wrapper.clientHeight || activeItemRef.offsetTop < this._wrapper.scrollTop) {
                scrollToElement(this._wrapper, activeItemRef, SCROLL_DURATION)
            }
        }
    }

    renderItems() {
        return this.props.items.map((item, index) => {
            const isActive = this.props.activeItemId ? this.props.activeItemId === item.id : index === 0
            const classNameItem = className(styles.item, {
                [styles.isActive]: isActive,
            })
            const onClick = isActive ? null : () => { this.props.onItemClick(item.id) }

            return (
                <div
                    ref={(c) => this[`_item-${item.id}`] = c}
                    key={item.id}
                    className={classNameItem}
                    onClick={onClick}
                >
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.date}>{formatDate(item.publishedAt, 'DD.MM.YY')}</div>
                </div>
            )
        })
    }

    renderPaginator() {
        const pages = Math.ceil(this.props.count / NEWS_ITEMS_LIMIT)

        return pages > 1 ? (
            <React.Fragment>
                <div className={styles.divider}><Divider /></div>
                <div className={styles.paginator}>
                    <Paginator
                        pages={pages}
                        activePage={this.props.page}
                        onPageChange={this.props.onChangeNewsPage}
                    />
                </div>
            </React.Fragment>
        ) : null
    }

    render() {
        if (this.props.items.length === 0) {
            return null
        }

        return (
            <div className={styles.base}>
                <div className={styles.wrapper} ref={(c) => this._wrapper = c}>
                    <div className={styles.items}>
                        {this.renderItems()}
                    </div>
                    {this.renderPaginator()}
                </div>
            </div>
        )
    }
}
