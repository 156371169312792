import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Spinner } from 'components'

import styles from './Processing.scss'

export default class Processing extends React.PureComponent {
    static propTypes = {
        isVisible: PropTypes.bool,
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isVisible]: this.props.isVisible,
        })

        return (
            <div className={classNameBase}>
                <div className={styles.spinner}>
                    <Spinner />
                </div>
            </div>
        )
    }
}
