import { reverse, first, isEmpty } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import {
    ButtonRedLarge,
    Caption,
    EditLabelError,
    FileUpload,
    Select,
    Tag,
    Textarea,
    TitleDialog,
} from 'components/index'

import apiUrls from 'apiUrls'
import { toggleList } from 'utils/filtration'
import {
    FIELD_VALIDATION_ERROR_TYPES,
    REPORT_MOD_PROBLEM_TYPES,
    REPORT_MOD_DESCRIPTION_MAX_LENGTH,
} from 'utils/constants'
import { getPrivacyPolicyUrlByRealm } from 'utils/routing'

import {
    DIALOG_REPORT_MOD_ATTENTION,
    DIALOG_REPORT_MOD_BUTTON_OK,
    DIALOG_REPORT_MOD_FILE_FORMAT,
    DIALOG_REPORT_MOD_FILE_SIZE,
    DIALOG_REPORT_MOD_PROBLEMS_CAPTION,
    DIALOG_REPORT_MOD_PROBLEMS_DESCRIPTION_CAPTION,
    DIALOG_REPORT_MOD_TITLE,
    DIALOG_REPORT_MOD_TYPE_CHEAT,
    DIALOG_REPORT_MOD_TYPE_COPYWRIGHT,
    DIALOG_REPORT_MOD_TYPE_TECHNICAL_ISSUE,
    DIALOG_REPORT_MOD_TYPE_UNACCEPTABLE_CONTENT,
    DIALOG_REPORT_MOD_VERION_CAPTION,
    DIALOG_REPORT_MOD_VERSION_VALUE,
} from './translations'


import {
    EDIT_UPLOAD_COMMON_ERROR,
    EDIT_UPLOAD_INVALID_FILE_SIZE_ERROR,
    EDIT_UPLOAD_INVALID_FILE_TYPE_ERROR,
    FIELD_MAX_LIMIT_EXCEED_ERROR,
} from 'translations'

import styles from './DialogReportMod.scss'

export default class DialogReportMod extends React.Component {
    static propTypes = {
        modId: PropTypes.number.isRequired,
        realm: PropTypes.string.isRequired,
        versions: PropTypes.arrayOf(PropTypes.shape({
            gameVersion: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            modVersion: PropTypes.string.isRequired,
        })).isRequired,

        onShowDialogError: PropTypes.func.isRequired,
        onSubmitDialog: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            descriptionErrorMessage: null,
            fileErrorType: null,
            fileErrorMessage: null,
            fileId: null,
            fileToken: null,
            isErrorProblemType: false,
            modVersionId: null,
            problemDescription: null,
            problemIds: [],
        }
    }

    componentWillMount() {
        const lastModVersionId = first(this.props.versions).id
        this.setState({
            modVersionId: lastModVersionId,
        })
    }

    handleModVersionChange = (modVersionId) => {
        this.setState({ modVersionId })
    }

    handleProblemTypeClick = (problemId) => {
        this.setState({
            problemIds: toggleList(this.state.problemIds, problemId),
            isErrorProblemType: false,
            isErrorProblemDescription: false,
        })
    }

    handleProblemDescriptionChange = (problemDescription) => {
        this.setState({
            problemDescription,
            isErrorProblemDescription: false,
            descriptionErrorMessage: null,
        })
    }

    handleFileChange = (data, fileErrorType) => {
        let fileErrorMessage = null
        if (fileErrorType) {
            fileErrorMessage = EDIT_UPLOAD_COMMON_ERROR
            if (fileErrorType === FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR) {
                fileErrorMessage = EDIT_UPLOAD_INVALID_FILE_TYPE_ERROR
            } else if (fileErrorType === FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR){
                fileErrorMessage = EDIT_UPLOAD_INVALID_FILE_SIZE_ERROR
            }
        }

        if (data === null) {
            this.setState({
                fileId: null,
                fileToken: null,
                fileErrorType,
                fileErrorMessage,
            })
        } else {
            this.setState({
                fileId: data.id,
                fileToken: data.access_token,
                fileErrorType,
                fileErrorMessage,
            })
        }
    }

    handleDialogSubmit = () => {
        let hasError = false
        if (isEmpty(this.state.problemIds)) {
            this.setState({
                isErrorProblemType: true,
            })
            hasError = true
        }

        if (!this.state.problemDescription || this.state.problemDescription.length > REPORT_MOD_DESCRIPTION_MAX_LENGTH) {
            this.setState({
                isErrorProblemDescription: true,
            })
            if (this.state.problemDescription.length > REPORT_MOD_DESCRIPTION_MAX_LENGTH) {
                this.setState({
                    descriptionErrorMessage: FIELD_MAX_LIMIT_EXCEED_ERROR(REPORT_MOD_DESCRIPTION_MAX_LENGTH),
                })
            }
            hasError = true
        }

        if (hasError) {
            return
        }

        const data = { ...this.state, modId: this.props.modId }
        this.props.onSubmitDialog(data)
    }

    renderFileErrorMessage() {
        return this.state.fileErrorMessage ? (
            <div className={styles.fileError}>
                <EditLabelError message={this.state.fileErrorMessage} />
            </div>
        ) : null
    }

    renderDescriptionErrorMessage() {
        return this.state.descriptionErrorMessage ? (
            <EditLabelError message={this.state.descriptionErrorMessage} />
        ) : null
    }

    render() {
        const itemsForSelect = reverse(this.props.versions.map((item) => ({
            caption: DIALOG_REPORT_MOD_VERSION_VALUE(item.modVersion, item.gameVersion),
            value: item.id,
        })))
        const isErrorFileFormat = this.state.fileErrorType === FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR
        const isErrorFileSize = this.state.fileErrorType === FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR

        return (
            <div className={styles.base}>
                <TitleDialog>{DIALOG_REPORT_MOD_TITLE}</TitleDialog>
                <div className={styles.form}>
                    <div className={styles.caption}>
                        <Caption>{DIALOG_REPORT_MOD_VERION_CAPTION}</Caption>
                    </div>
                    <div className={styles.select}>
                        <Select
                            items={itemsForSelect}
                            selectedValue={this.state.modVersionId}
                            onItemChange={this.handleModVersionChange}
                        />
                    </div>
                    <hr className={styles.hr} />
                    <div className={styles.caption}>
                        <Caption isRequired isError={this.state.isErrorProblemType}>
                            {DIALOG_REPORT_MOD_PROBLEMS_CAPTION}
                        </Caption>
                    </div>
                    <div className={styles.tags}>
                        <div className={styles.tag}>
                            <Tag
                                caption={DIALOG_REPORT_MOD_TYPE_TECHNICAL_ISSUE}
                                id={REPORT_MOD_PROBLEM_TYPES.TECHNICAL_ISSUE}
                                isChecked={this.state.problemIds.includes(REPORT_MOD_PROBLEM_TYPES.TECHNICAL_ISSUE)}
                                isError={this.state.isErrorProblemType}
                                type="warning"
                                onClick={this.handleProblemTypeClick}
                            />
                        </div>
                        <div className={styles.tag}>
                            <Tag
                                caption={DIALOG_REPORT_MOD_TYPE_UNACCEPTABLE_CONTENT}
                                id={REPORT_MOD_PROBLEM_TYPES.UNACCEPTABLE_CONTENT}
                                isChecked={this.state.problemIds.includes(REPORT_MOD_PROBLEM_TYPES.UNACCEPTABLE_CONTENT)}
                                isError={this.state.isErrorProblemType}
                                type="warning"
                                onClick={this.handleProblemTypeClick}
                            />
                        </div>
                        <div className={styles.tag}>
                            <Tag
                                caption={DIALOG_REPORT_MOD_TYPE_CHEAT}
                                id={REPORT_MOD_PROBLEM_TYPES.CHEAT}
                                isChecked={this.state.problemIds.includes(REPORT_MOD_PROBLEM_TYPES.CHEAT)}
                                isError={this.state.isErrorProblemType}
                                type="warning"
                                onClick={this.handleProblemTypeClick}
                            />
                        </div>
                        <div className={styles.tag}>
                            <Tag
                                caption={DIALOG_REPORT_MOD_TYPE_COPYWRIGHT}
                                id={REPORT_MOD_PROBLEM_TYPES.COPYRIGHT}
                                isChecked={this.state.problemIds.includes(REPORT_MOD_PROBLEM_TYPES.COPYRIGHT)}
                                isError={this.state.isErrorProblemType}
                                type="warning"
                                onClick={this.handleProblemTypeClick}
                            />
                        </div>
                    </div>
                    <hr className={styles.hr} />
                    <div className={styles.caption}>
                        <Caption isRequired isError={this.state.isErrorProblemDescription}>
                            {DIALOG_REPORT_MOD_PROBLEMS_DESCRIPTION_CAPTION}
                        </Caption>
                    </div>
                    <Textarea
                        isError={this.state.isErrorProblemDescription}
                        value={this.state.problemDescription}
                        onChange={this.handleProblemDescriptionChange}
                    />
                    {this.renderDescriptionErrorMessage()}
                    <hr className={styles.hr} />
                    <div className={styles.fileUpload}>
                        <FileUpload
                            url={apiUrls.reportFileUpload}
                            fieldName="source"
                            summary={(
                                <span>
                                    <span className={styles.fileUploadSummary}>
                                        {DIALOG_REPORT_MOD_FILE_FORMAT(isErrorFileFormat)}
                                    </span>
                                    <span className={styles.fileUploadSummary}>
                                        {DIALOG_REPORT_MOD_FILE_SIZE(isErrorFileSize)}
                                    </span>
                                </span>
                            )}
                            onUploadCompleted={this.handleFileChange}
                        />
                        {this.renderFileErrorMessage()}
                    </div>
                    <hr className={styles.hr} />
                    <p className={styles.attention}>
                        {DIALOG_REPORT_MOD_ATTENTION(getPrivacyPolicyUrlByRealm(this.props.realm))}
                    </p>
                    <ButtonRedLarge glyph="speakers" onClick={this.handleDialogSubmit}>
                        {DIALOG_REPORT_MOD_BUTTON_OK}
                    </ButtonRedLarge>
                </div>
            </div>
        )
    }
}
