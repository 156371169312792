import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

export const MOD_SUMMARY_VERSION = (modVersion, gameVersion, isNewVersion) => (
    <FormattedMessage
        id="mod.summary.version"
        defaultMessage="{modVersion}{NBSP}для{NBSP}{game}{NBSP}{gameVersion}"
        values={{
            NBSP: '\u00a0',
            game: (<mark>WoT</mark>),
            modVersion: (<mark>{modVersion}</mark>),
            gameVersion: isNewVersion ? (<mark>{gameVersion}</mark>) : (<i>{gameVersion}</i>),
        }}
    />

)

export const MOD_SUMMARY_AUTHOR_CAPTION = (
    <FormattedMessage
        id="mod.summary.author.caption"
        defaultMessage="Автор:"
    />
)

export const MOD_DOWNLOAD_BUTTON_CAPTION = (size) => (
    <FormattedHTMLMessage
        id="mod.download.button.caption"
        defaultMessage="Скачать мод <mark>({size})</mark>"
        values={{ size }}
    />
)

export const MOD_VIEW_LABEL = (
    <FormattedMessage
        id="mod.view.label"
        defaultMessage="Предпросмотр мода"
    />
)
