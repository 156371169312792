import { uniqueId } from 'lodash'

import settings from 'settings'

export class ScriptLoader {
    static insertScript = (src) => {
        const newScript = document.createElement('script')
        newScript.type = 'text/javascript'
        newScript.src = src
        const existingScript = document.getElementsByTagName('script')[0]
        existingScript.parentNode.insertBefore(newScript, existingScript.nextSibling)
        return newScript
    }

    constructor(window) {
        this.window = window
        this.HC = null
        this.portalLanguage = null
        this.promise = null
        this.widgetId = null
    }

    getManager(widgetId, portalLanguage) {
        if (this.promise) {
            if (this.widgetId === widgetId && this.portalLanguage === portalLanguage) {
                return this.promise
            }
            return Promise.reject('Can not load different widget ids and/or languages one one page')
        }
        const url = this.getScriptUrl(widgetId, portalLanguage)
        ScriptLoader.insertScript(url)
        this.widgetId = widgetId
        this.portalLanguage = portalLanguage
        this.promise = new Promise(this.spyOnWindow).then((HC) => {
            return new HyperCommentsManager(HC, this.widgetId)
        })
        return this.promise
    }

    spyOnWindow = (callback) => {
        Object.defineProperty(this.window, 'HC', {
            configurable: true,
            get: () => this.HC,
            set: (value) => {
                this.HC = value
                setTimeout(() => callback(value))
            },
        })
    }

    getScriptUrl(widgetId, portalLanguage) {
        return `https://w.hypercomments.com/widget/hc/${widgetId}/${portalLanguage}/widget.js`
    }
}

export class HyperCommentsManager {
    constructor(HC, widgetId) {
        this.HC = HC
        this.widgetId = widgetId
    }

    create({ el, authString, modId, lang, readonly, texts}) {
        const elId = `hypercomments-widget-${uniqueId()}`
        el.id = elId
        this.HC.widget('Stream', {
            append: `#${elId}`,
            auth: authString,
            comments_level: 1,
            css: `${settings.staticUrl}modportal_assets/vendor/hypercomments.css`,
            hc_disable: true,
            readonly,
            texts,
            widget: 'Stream',
            widget_id: this.widgetId,
            xid: this.getXid(modId, lang),
        })
        return () => {
            this.destroy(modId, lang)
        }
    }

    destroy(modId, lang) {
        this.HC.destroy(this.getXid(modId, lang))
    }

    getXid(modId, lang) {
        return `${modId}_${lang}`
    }
}

export const scriptLoader = new ScriptLoader(window)
