import { connect } from 'react-redux'

import { fetchNews, changeNewsPage } from 'actions/News'
import News from 'components/News/News'


const mapStateToProps = (state) => ({
    count: state.news.count,
    items: state.news.items,
    page: state.news.page,
    isError: state.news.isError,
})

const mapDispatchToProps = (dispatch) => ({
    fetchNews: () => {
        dispatch(fetchNews())
    },
    onChangeNewsPage: (page) => {
        Promise.resolve(
            dispatch(changeNewsPage(page))
        ).then(() => dispatch(fetchNews()))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(News)
