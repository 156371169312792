import React from 'react'
import { keys, unionBy } from 'lodash'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
    addChangelogHistoryItem,
    editChangelogHistoryItem,
    setChangelogHistoryOrdering,
} from 'actions/ChangelogHistory'
import { openDialog } from 'actions/Dialog'
import {
    changeModStatus,
    changeModVersionVisibility,
    changeModVisibility,
    fetchEditComments,
    fetchEditDetails,
    saveMod,
    setModVersionUploadFormVisibility,
} from 'actions/EditMod'

import EditMod from 'components/EditMod/EditMod'
import {
    DialogEdgeAlert,
    DialogIEAlert,
    DialogConfirmationChangelogHistoryItemRemove,
    DialogConfirmationModRemove,
    DialogConfirmationModVersionRemove,
} from 'containers'

import settings from 'settings'

const mapStateToProps = (state) => ({
    categories: keys(state.tags).map((tagId) => ({ id: +tagId, caption: state.tags[tagId].title })),
    changelogHistory: state.changelogHistory.items,
    comments: state.editMod.comments,
    data: state.editMod.data,
    gameVersions: unionBy(settings.unreleasedGameVersions, settings.gameVersions, 'id'),
    isError: state.editMod.isError,
    isErrorComments: state.editMod.isErrorComments,
    isErrorForbidden: state.editMod.isErrorForbidden,
    isErrorNotFound: state.editMod.isErrorNotFound,
    isFetched: state.editMod.isFetched,
    isFetchedComments: state.editMod.isFetchedComments,
    isFetching: state.editMod.isFetching,
    isEdgeAlertAccepted: state.persistedValues.isEdgeAlertAccepted,
    isIEAlertAccepted: state.persistedValues.isIEAlertAccepted,
    isModVersionUploadFormVisible: state.editMod.isModVersionUploadFormVisible,
    isUserBanned: state.currentAccount.isBanned,
    isUserLoggedIn: !!state.currentAccount.spaId,
    isUserStatisticsSufficient: state.currentAccount.isUserStatisticsSufficient,
    language: state.currentAccount.lang,
    realm: state.currentAccount.realm,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    onComponentWillMount: (modId) => {
        dispatch(fetchEditComments(modId))
        dispatch(fetchEditDetails(modId))
    },
    onModStatusChange: (modId, status) => {
        dispatch(changeModStatus(modId, status))
    },
    onModVersionRemove: (modVersionId) => {
        dispatch(openDialog(<DialogConfirmationModVersionRemove id={modVersionId} />))
    },
    onModVersionVisibilityChange: (modVersionId, isVisible) => {
        dispatch(changeModVersionVisibility(modVersionId, isVisible))
    },
    onModVisibilityChange: (modId, isVisible) => {
        dispatch(changeModVisibility(modId, isVisible))
    },
    onModRemove: (modId, isDraftOrRejected) => {
        dispatch(openDialog(<DialogConfirmationModRemove id={modId} isDraftOrRejected={isDraftOrRejected} />))
    },
    onSetModVersionUploadFormVisibility: (isVisible) => {
        dispatch(setModVersionUploadFormVisibility(isVisible))
    },
    onShowConfirmationHistoryDialog: () => {
        dispatch(openDialog(<DialogConfirmationChangelogHistoryItemRemove />))
    },
    onChangelogConfirmationDialogShow: (id) => {
        dispatch(openDialog(<DialogConfirmationChangelogHistoryItemRemove id={id} />))
    },
    onChangelogHistoryItemAdd: (content, version) => {
        dispatch(addChangelogHistoryItem(content, version))
    },
    onChangelogHistoryItemEdit: (id, content, version) => {
        dispatch(editChangelogHistoryItem(id, content, version))
    },
    onChangelogHistoryItemsReordered: (orderedIds) => {
        dispatch(setChangelogHistoryOrdering(orderedIds))
    },
    onFormSubmit: (modId, data) => {
        dispatch(saveMod(modId, data, ownProps.history))
    },
    onShowEdgeConfirmationDialog: () => {
        dispatch(openDialog(<DialogEdgeAlert />))
    },
    onShowIEConfirmationDialog: () => {
        dispatch(openDialog(<DialogIEAlert />))
    },
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EditMod),
)
