import React from 'react'
import { FormattedMessage } from 'react-intl'

export const EDIT_CHANGE_LOG_FORM_PREV_VERSION_LABEL = (
    <FormattedMessage
        id="edit.change.log.form.prev.version.label"
        defaultMessage="Номер предыдущей версии"
    />
)

export const EDIT_CHANGE_LOG_FORM_PREV_VERSION_DESCRIPTION_LABEL = (
    <FormattedMessage
        id="edit.change.log.form.prev.version.description.label"
        defaultMessage="Изменения предыдущей версии"
    />
)
