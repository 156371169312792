import {
    START_FETCHING,
    STOP_FETCHING,
    SET_IS_BANNED,
    SET_LANGUAGE,
    SET_RULES_ACCEPTED,
    UPDATE_MODS_COUNT,
} from 'actions/CurrentAccount'

const initialState = {
    initialized: true,
    isBanned: false,
    isFetching: false,
    isRulesAccepted: false,
    isUserStatisticsSufficient: false,
    lang: null,
    realm: null,
    spaId: null,
    spaUsername: null,
    updatedModsCount: 0,
}

export const currentAccount = (state = initialState, action) => {
    if (!state.initialized) {
        state = { ...initialState, ...state }
    }
    switch(action.type) {
        case START_FETCHING:
            return {
                ...state,
                isFetching: true,
            }
        case STOP_FETCHING:
            return {
                ...state,
                isFetching: false,
            }
        case SET_LANGUAGE:
            return {
                ...state,
                lang: action.code,
            }
        case SET_RULES_ACCEPTED:
            return {
                ...state,
                isRulesAccepted: true,
            }
        case UPDATE_MODS_COUNT:
            return {
                ...state,
                updatedModsCount: action.count,
            }
        case SET_IS_BANNED:
            return {
                ...state,
                isBanned: true,
            }
        default:
            return state
    }
}
