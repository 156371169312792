import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { SEARCH_RESULT_FILTER_TYPES } from 'utils/constants'

import Nav from 'components/UI/Nav/Nav'

const mapStateToProps = (state) => ({
    searchTerm: state.searchResult.filtered.filterParams.type === SEARCH_RESULT_FILTER_TYPES.TITLE ?
        state.searchResult.filtered.filterParams.argument :
        '',
    updatedModsCount: state.currentAccount.updatedModsCount,
})

export default withRouter(connect(mapStateToProps, null)(Nav))
