import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import { currentAccount } from 'reducers/CurrentAccount'
import { dialog } from 'reducers/Dialog'
import { subscriptions } from 'reducers/Subscriptions'
import { locale } from 'reducers/Locale'
import { persistedValues } from 'reducers/PersistedValues'
import { processing } from 'reducers/Processing'
import { dialogError } from 'reducers/DialogError'

import { contentPage } from 'reducers/ContentPage'
import { changelogHistory } from 'reducers/ChangelogHistory'
import { editMod } from 'reducers/EditMod'
import { details } from 'reducers/Details'
import { gallery } from 'reducers/Gallery'
import { modderSection } from 'reducers/ModderSection'
import { news } from 'reducers/News'
import { searchResult } from 'reducers/SearchResult'

export const hypercomments = (state = {}) => state
export const footerLinks = (state = {}) => state
export const tags = (state = {}) => state

export default combineReducers({
    currentAccount,
    dialog,
    dialogError,
    subscriptions,
    locale,
    persistedValues,
    processing,

    contentPage,
    changelogHistory,
    details,
    editMod,
    gallery,
    modderSection,
    news,
    searchResult,

    footerLinks,
    hypercomments,
    tags,

    routing: routerReducer,
})
