import React from 'react'
import { FormattedMessage } from 'react-intl'

export const ERROR_404_TITLE = (
    <FormattedMessage
        id="error.404.title"
        defaultMessage="Страница не найдена"
    />
)

export const ERROR_404_CONTENT = (
    <FormattedMessage
        id="error.content"
        defaultMessage="Что-то пошло не так. Такой страницы нет."
    />
)
