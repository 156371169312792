import React from 'react'
import { FormattedMessage } from 'react-intl'

export const EDIT_AUTHOR_NAME_LABEL = (
    <FormattedMessage
        id="edit.author.name.label"
        defaultMessage="Автор"
    />
)

export const EDIT_AUTHOR_NAME_TEXT = (
    <FormattedMessage
        id="edit.author.name.text"
        defaultMessage="Указывается, если автор не вы, а иное лицо, предоставившее вам права на добавление мода"
    />
)
