import { getGalleryItemsCountPerRow } from 'utils/helpers'

export const BREAKPOINT = 1366

export const GALLERY_ITEMS_TYPES = {
    RECOMMENDED: 'recommended',
    UPDATED: 'updated',
    NEW: 'new',
}

export const GALLERY_ITEMS_FILTER_TYPES = {
    TAGS: 'tags',
}

export const GALLERY_ITEMS_COUNT_PER_ROW = getGalleryItemsCountPerRow()

export const GALLERY_ITEMS_PER_PAGE = {
    [GALLERY_ITEMS_TYPES.RECOMMENDED]: 3 * GALLERY_ITEMS_COUNT_PER_ROW,
    [GALLERY_ITEMS_TYPES.UPDATED]: GALLERY_ITEMS_COUNT_PER_ROW,
    [GALLERY_ITEMS_TYPES.NEW]: GALLERY_ITEMS_COUNT_PER_ROW,
}

export const GALLERY_ITEMS_FILTERED_PER_PAGE = 3 * GALLERY_ITEMS_COUNT_PER_ROW

export const GALLERY_ITEMS_FILTERED_ORDERING = {
    RATING: '-rating',
    CHANGED_AT: '-changed_at',
}

export const GALLERY_FILTER_LANGUAGES = {
    ALL: 'all',
    EN: 'en',
    RU: 'ru',
}

export const SEARCH_RESULT_FILTER_TYPES = {
    OWNER: 'owner',
    TITLE: 'title',
}

export const SEARCH_RESULT_LANGUAGES = {
    ALL: 'all',
    EN: 'en',
    RU: 'ru',
}

export const SEARCH_RESULT_ITEMS_PER_PAGE = 3 * GALLERY_ITEMS_COUNT_PER_ROW

export const SEARCH_RESULT_ITEMS_ORDERING = {
    RATING: '-rating',
    CHANGED_AT: '-changed_at',
}

export const REPORT_MOD_PROBLEM_TYPES = {
    TECHNICAL_ISSUE: 0,
    UNACCEPTABLE_CONTENT: 1,
    CHEAT: 2,
    COPYRIGHT: 3,
}

export const REPORT_MOD_DESCRIPTION_MAX_LENGTH = 4000

export const MOD_STATE = {
    DEFAULT: -1,
    DRAFT: 0,
    IN_REVIEW: 1,
    REJECTED: 2,
    PUBLISHED: 3,
    PUBLISHED_UPDATE_IN_REVIEW: 4,
    PUBLISHED_UPDATE_REJECTED: 5,
    HIDDEN: 6,
}

export const MOD_VERSION_STATUS = {
    PENDING_REVIEW: 0,
    PUBLISHED: 1,
    REJECTED: 2,
}

export const MOD_STATE_MAPPING = {
    [MOD_STATE.DRAFT]: 'draft',
    [MOD_STATE.IN_REVIEW]: 'review',
    [MOD_STATE.REJECTED]: 'rejected',
    [MOD_STATE.PUBLISHED]: 'published',
    [MOD_STATE.PUBLISHED_UPDATE_IN_REVIEW]: 'published_update_in_review',
    [MOD_STATE.PUBLISHED_UPDATE_REJECTED]: 'published_update_rejected',
    [MOD_STATE.HIDDEN]: 'hidden',
}

export const MOD_VERSION_STATUS_MAPPING = {
    [MOD_VERSION_STATUS.PENDING_REVIEW]: 'review',
    [MOD_VERSION_STATUS.PUBLISHED]: 'published',
    [MOD_VERSION_STATUS.REJECTED]: 'rejected',
}

export const MOD_STATUS_TYPES = {
    DRAFT: 0,
    PENDING_REVIEW: 1,
    PUBLISHED: 2,
    REJECTED: 3,
}

export const MOD_VERSION_COMMENT_TYPES = {
    APPROVED: 'approved',
    REJECTED: 'rejected',
}

export const MAX_SEARCH_LENGTH = 255
export const MAX_AUTHOR_LENGTH = 255
export const MAX_CHANGELOG_LENGTH = 1000
export const MAX_COMMENT_LENGTH = 1000
export const MAX_COMMENTS_LENGTH = 1000
export const MAX_DESCRIPTION_LENGTH = 3000
export const MAX_INSTALLATION_GUIDE_LENGTH = 1000
export const MAX_MOD_VERSION_LENGTH = 15
export const MAX_TITLE_LENGTH = 255

export const MIN_DESCRIPTION_LENGTH = 1
export const MIN_INSTALLATION_GUIDE_LENGTH = 1
export const MIN_MOD_VERSION_LENGTH = 1
export const MIN_TITLE_LENGTH = 3

export const FIELD_VALIDATION_ERROR_TYPES = {
    COMMON_ERROR: 'common_error',
    EMPTY_VALUES: 'empty_values',
    FILE_SIZE_ERROR: 'file_size_error',
    FILE_TYPE_ERROR: 'file_type_error',
    MAX_LIMIT: 'max_limit',
    MIN_LIMIT: 'min_limit',
    NO_FILE_SELECTED: 'no_file_selected',
    NOT_SUPPORTED_IMAGE_DIMENSIONS: 'not_supported_image_dimensions',
}

export const NEWS_ITEMS_LIMIT = 10

export const CHANGELOG_FIRST_PAGE_MAX_ITEMS = 5
export const COMMENTS_FIRST_PAGE_MAX_ITEMS = 5

export const SCROLL_TO_COMPONENTS_PARAMETERS = { align: 'top', duration: 500, offset: -100 }
export const HTML_MEDIA_ITEM_LENGHT = 30
