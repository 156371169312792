import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { chunk, isEmpty } from 'lodash'

import {
    COMMENTS_FIRST_PAGE_MAX_ITEMS,
    FIELD_VALIDATION_ERROR_TYPES,
    MAX_COMMENTS_LENGTH,
} from 'utils/constants'

import EditCommentsItem from './components/EditCommentsItem/EditCommentsItem'
import {
    ButtonGrayOutlineSmall,
    EditLabelError,
    Input,
    Paginator,
    TitleMinor,
} from 'components'

import {
    FIELD_MAX_LIMIT_EXCEED_ERROR,
    LOAD_MORE,
} from 'translations'

import {
    EDIT_COMMENTS_TITLE,
    EDIT_MOD_INPUT_PLACEHOLDER,
} from './translations'

import styles from './EditComments.scss'

export default class EditComment extends React.Component {
    static propTypes = {
        comments: PropTypes.arrayOf(PropTypes.shape({
            authorName: PropTypes.string,
            content: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            gameVersion: PropTypes.string.isRequired,
            modVersion: PropTypes.string,
            modVersionId: PropTypes.number,
            status: PropTypes.string,
        })),
        modId: PropTypes.number.isRequired,
        isErrorComments: PropTypes.bool.isRequired,
        isFetchedComments: PropTypes.bool.isRequired,
        isDisabled: PropTypes.bool,

        onCommentAdd: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            activeChunk: 0,
            chunkedItems: [],
            isExpanded: false,
            newCommentContent: null,
            errorType: null,
        }
    }

    componentWillMount() {
        this.splitCommentsIntoChunks(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.splitCommentsIntoChunks(nextProps)
    }

    splitCommentsIntoChunks(props) {
        if (isEmpty(props.comments)) {
            return
        }

        const chunkedItems = chunk(props.comments, COMMENTS_FIRST_PAGE_MAX_ITEMS)
        this.setState({ chunkedItems })
    }

    handleNewCommentChange = (newCommentContent) => {
        let errorType = null

        if (newCommentContent.length > MAX_COMMENTS_LENGTH) {
            errorType = FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT
        }

        this.setState({
            errorType,
            newCommentContent,
        })
    }

    handleNewCommentSubmit = (newCommentContent) => {
        if (!this.state.errorType) {
            this.props.onCommentAdd(this.props.modId, newCommentContent)
            this.setState({
                activeChunk: 0,
                newCommentContent: null,
            })
        }
    }

    handleLoadMoreClick = () => {
        this.setState({
            isExpanded: true,
        })
    }

    handlePageChange = (activePage) => {
        this.setState({
            activeChunk: activePage - 1,
        })
    }

    renderError() {
        const errorToMessagesMapping = {
            [FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT]: FIELD_MAX_LIMIT_EXCEED_ERROR(MAX_COMMENTS_LENGTH),
        }

        return this.state.errorType ? (
            <EditLabelError message={errorToMessagesMapping[this.state.errorType]} />
        ) : null
    }

    renderItems() {
        let chunk = this.state.chunkedItems[this.state.activeChunk]

        if (!chunk) {
            return null
        }

        if (this.state.isExpanded) {
            const nextChunk = this.state.chunkedItems[this.state.activeChunk + 1]
            if (nextChunk) {
                chunk = [...chunk, ...nextChunk]
            }
        }

        const items = chunk.map((item, index) => (
            <EditCommentsItem
                key={`edit-comments-item-${index}`}
                authorName={item.authorName}
                content={item.content}
                createdAt={item.createdAt}
                gameVersion={item.gameVersion}
                modVersion={item.modVersion}
                status={item.status}
            />
        ))

        return (
            <div className={styles.comments}>
                {items}
            </div>
        )
    }

    renderLoadMoreButton() {
        const canRender = !this.state.isExpanded &&
            this.props.comments.length > COMMENTS_FIRST_PAGE_MAX_ITEMS &&
            this.props.comments.length <= 2 * COMMENTS_FIRST_PAGE_MAX_ITEMS

        return canRender ? (
            <div className={styles.button}>
                <ButtonGrayOutlineSmall onClick={this.handleLoadMoreClick}>
                    {LOAD_MORE}
                </ButtonGrayOutlineSmall>
            </div>
        ) : null
    }

    renderPaginator() {
        return this.props.comments.length > 2 * COMMENTS_FIRST_PAGE_MAX_ITEMS ? (
            <div className={styles.paginator}>
                <div className={styles.paginatorInner}>
                    <Paginator
                        activePage={this.state.activeChunk + 1}
                        pages={this.state.chunkedItems.length}
                        onPageChange={this.handlePageChange}
                    />
                </div>
            </div>
        ) : null
    }

    render() {
        if (this.props.isErrorComments || !this.props.isFetchedComments) {
            return null
        }

        const classNameBase = classNames(styles.base, {
            [styles.isDisabled]: this.props.isDisabled,
        })

        const count = this.props.comments.length <= 99 ? this.props.comments.length : '99+'

        return (
            <div className={classNameBase}>
                <div className={styles.title}>
                    <TitleMinor>{EDIT_COMMENTS_TITLE(count)}</TitleMinor>
                </div>
                <Input
                    placeholder={EDIT_MOD_INPUT_PLACEHOLDER}
                    value={this.state.newCommentContent}
                    isError={this.state.errorType}
                    onChange={this.handleNewCommentChange}
                    onSubmit={this.handleNewCommentSubmit}
                />
                {this.renderError()}
                {this.renderItems()}
                {this.renderLoadMoreButton()}
                {this.renderPaginator()}
            </div>
        )
    }
}
