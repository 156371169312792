import { connect } from 'react-redux'
import { union } from 'lodash'

import {
    changeSearchResultItemsOrdering,
    fetchItems,
    incrementSearchingOffset,
    resetSearchResultItems,
} from 'actions/SearchResult'

import {
    incrementDownloadCount,
    processDownloadMod,
} from 'actions/Details'

import SearchResult from 'components/SearchResult/SearchResult'
import settings from 'settings'

import { ALL_GAME_VERSIONS } from 'translations'

const mapStateToProps = (state) => ({
    gameVersions: union(settings.gameVersions, [ALL_GAME_VERSIONS]),
    filtered: state.searchResult.filtered,
    isModDownloadAccepted: state.persistedValues.isModDownloadAccepted,
    overallFilters: state.gallery.overallFilters,
})

const mapDispatchToProps = (dispatch) => ({
    changeItemsOrdering: (filterParams) => {
        dispatch(changeSearchResultItemsOrdering(filterParams.ordering))
        dispatch(fetchItems(filterParams))
    },
    fetchItems: (filterParams) => {
        dispatch(resetSearchResultItems())
        dispatch(fetchItems(filterParams))
    },
    loadMoreItems: (filterParams) => {
        dispatch(incrementSearchingOffset())
        dispatch(fetchItems(filterParams))
    },
    onDownloadClick: (isModDownloadAccepted, isModDownloaded, modId, title, versions) => {
        if (isModDownloaded) {
            dispatch(incrementDownloadCount(modId))
        } else {
            dispatch(processDownloadMod(isModDownloadAccepted, modId, title, versions))
        }
    },
    resetComponent: () => {
        dispatch(resetSearchResultItems())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult)
