import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import classNames from 'classnames'

import { ButtonYellowLarge } from 'components'

import { pushHistoryWithLanding } from 'utils/routing'

import {
    ERROR_EMPTY_SUBSCRIPTIONS_MESSAGE,
    ERROR_EMPTY_SUBSCRIPTIONS_BUTTON,
} from './translations'

import styles from './ErrorEmptySubscriptions.scss'

export default class ErrorEmptySubscriptions extends React.PureComponent {
    static propTypes = {
        history: ReactRouterPropTypes.history.isRequired,
        lang: PropTypes.string.isRequired,
    }

    handleClick = () => {
        pushHistoryWithLanding(this.props.history)
    }

    render() {
        const classNameBase = classNames(styles.base, styles[`base__${this.props.lang}`])

        return (
            <div className={classNameBase}>
                <div>
                    <div className={styles.message}>{ERROR_EMPTY_SUBSCRIPTIONS_MESSAGE}</div>
                    <div className={styles.icon} />
                    <div>
                        <ButtonYellowLarge onClick={this.handleClick}>{ERROR_EMPTY_SUBSCRIPTIONS_BUTTON}</ButtonYellowLarge>
                    </div>
                </div>
            </div>
        )
    }
}
