import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Content.scss'

export default class Content extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        isEditor: PropTypes.bool,
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isEditor]: this.props.isEditor,
        })

        return (
            <div className={classNameBase}>{this.props.children}</div>
        )
    }
}
