import React from 'react'
import PropTypes from 'prop-types'

import { FIELD_VALIDATION_ERROR_TYPES } from 'utils/constants'
import { toggleList } from 'utils/filtration'

import {
    Caption,
    EditLabelText,
    Tag,
} from 'components'

import {
    EDIT_CATEGORIES_LABEL,
    EDIT_CATEGORIES_LABEL_TEXT,
} from './translations'

import styles from './EditCategories.scss'

const MAX_ITEMS_TO_CHECK = 3

export default class EditCategories extends React.PureComponent {
    static propTypes = {
        categories: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            caption: PropTypes.string.isRequired,
        })).isRequired,
        checkedItems: PropTypes.arrayOf(PropTypes.number),
        externalError: PropTypes.oneOf([
            FIELD_VALIDATION_ERROR_TYPES.EMPTY_VALUES,
        ]),

        onValueChange: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            checkedItems: [],
            isCheckedAll: false,
        }
    }

    componentWillMount() {
        if (this.props.checkedItems) {
            this.setState({
                checkedItems: this.props.checkedItems,
                isCheckedAll: this.props.checkedItems.length >= MAX_ITEMS_TO_CHECK,
            })
        }
    }

    handleItemClick = (itemId) => {
        const checkedItems = toggleList(this.state.checkedItems, itemId)
        const errorType = checkedItems.length === 0 ? FIELD_VALIDATION_ERROR_TYPES.EMPTY_VALUES : null
        this.setState({
            checkedItems,
            isCheckedAll: checkedItems.length >= MAX_ITEMS_TO_CHECK,
        })
        this.props.onValueChange(checkedItems, errorType)
    }

    renderItems() {
        return this.props.categories.map((item, index) => {
            const isDisabled = this.state.isCheckedAll && !this.state.checkedItems.includes(item.id)
            return (
                <div className={styles.tag} key={`create-tag-${item.id}-${index}`}>
                    <Tag
                        id={item.id}
                        isChecked={this.state.checkedItems.includes(item.id)}
                        isDisabled={isDisabled}
                        isError={!!this.props.externalError}
                        caption={item.caption}
                        onClick={this.handleItemClick}
                    />
                </div>
            )
        })
    }

    render() {
        return (
            <div className={styles.base}>
                <div className={styles.label}>
                    <Caption isLarge isRequired isError={!!this.props.externalError}>
                        {EDIT_CATEGORIES_LABEL}
                    </Caption>
                    <EditLabelText message={EDIT_CATEGORIES_LABEL_TEXT} />
                </div>
                <div className={styles.field}>
                    <div className={styles.tags}>
                        {this.renderItems()}
                    </div>
                </div>
            </div>
        )
    }
}
