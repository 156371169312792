import * as currentAccountActions from 'actions/CurrentAccount'

const initialState = {
    isModDownloadAccepted: false,
    overallLanguageFilter: null,
    isIEAlertAccepted: false,
    isEdgeAlertAccepted: false,
}

export const persistedValues = (state = initialState, action) => {
    if (!state.initialized) {
        state = { ...initialState, ...state }
    }
    switch(action.type) {
        case currentAccountActions.SET_MOD_DOWNLOAD_ACCEPTED:
            return {
                ...state,
                isModDownloadAccepted: true,
            }
        case currentAccountActions.SET_OVERALL_LANGUAGE_FILTER:
            return {
                ...state,
                overallLanguageFilter: action.payload.language,
            }
        case currentAccountActions.SET_IE_ALERT_ACCEPTED:
            return {
                ...state,
                isIEAlertAccepted: true,
            }
        case currentAccountActions.SET_EDGE_ALERT_ACCEPTED:
            return {
                ...state,
                isEdgeAlertAccepted: true,
            }
        default:
            return state
    }
}
