import React from 'react'
import PropTypes from 'prop-types'

import {
    FIELD_VALIDATION_ERROR_TYPES,
    MAX_AUTHOR_LENGTH,
} from 'utils/constants'

import {
    Caption,
    EditLabelError,
    EditLabelText,
    Input,
} from 'components'

import {
    FIELD_MAX_LIMIT_EXCEED_ERROR,
} from 'translations'

import {
    EDIT_AUTHOR_NAME_LABEL,
    EDIT_AUTHOR_NAME_TEXT,
} from './translations'

import styles from './EditAuthorName.scss'

export default class EditAuthorName extends React.PureComponent {
    static propTypes = {
        externalValue: PropTypes.string,
        externalError: PropTypes.oneOf([
            FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
        ]),

        onValueChange: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            errorType: null,
            value: '',
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.externalValue && nextProps.externalValue !== this.state.value) {
            this.setState({
                value: nextProps.externalValue,
            })
        }
    }

    handleValueChange = (value) => {
        let errorType = null

        if (value.length > MAX_AUTHOR_LENGTH) {
            errorType = FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT
        }

        this.setState({ errorType, value })
        this.props.onValueChange(value, errorType)
    }

    renderError() {
        const errorToMessagesMapping = {
            [FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT]: FIELD_MAX_LIMIT_EXCEED_ERROR(MAX_AUTHOR_LENGTH),
        }
        return this.state.errorType ? (
            <EditLabelError message={errorToMessagesMapping[this.state.errorType]} />
        ) : null
    }

    render() {
        return (
            <div className={styles.base}>
                <div className={styles.label}>
                    <Caption isLarge isError={!!this.props.externalError}>
                        {EDIT_AUTHOR_NAME_LABEL}
                    </Caption>
                    <EditLabelText message={EDIT_AUTHOR_NAME_TEXT} />
                    {this.renderError()}
                </div>
                <div className={styles.field}>
                    <Input
                        value={this.state.value}
                        isError={!!this.props.externalError}
                        onChange={this.handleValueChange}
                    />
                </div>
            </div>
        )
    }
}
