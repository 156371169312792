import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Scrollbars } from 'react-custom-scrollbars'

import styles from './ScrollArea.scss'

export default class ScrollArea extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        contentHeightMax: PropTypes.number.isRequired,
        isDropdown: PropTypes.bool,
    }

    renderTrack = () => {
        const scrollTrack = classNames(styles.scrollTrack, {
            [styles.isDropdown]: this.props.isDropdown,
        })

        return (
            <div className={scrollTrack} />
        )
    }

    renderThumb({ ...props }) {
        return (
            <div
                className={styles.scrollThumb}
                {...props}
            />
        )
    }

    render() {
        return (
            <Scrollbars
                hideTracksWhenNotNeeded
                autoHeight
                autoHeightMax={this.props.contentHeightMax}
                renderTrackVertical={this.renderTrack}
                renderThumbVertical={this.renderThumb}
            >
                {this.props.children}
            </Scrollbars>
        )
    }
}
