import { connect } from 'react-redux'

import {
    fetchContentPage,
} from 'actions/ContentPage'

import ContentPage from 'components/ContentPage/ContentPage'

const mapStateToProps = (state) => ({
    data: state.contentPage.data,
    isError: state.contentPage.isError,
    isErrorNotFound: state.contentPage.isErrorNotFound,
    isFetching: state.contentPage.isFetching,
    isFetched: state.contentPage.isFetched,
})

const mapDispatchToProps = (dispatch) => ({
    fetchContentPage: (slug) => {
        dispatch(fetchContentPage(slug))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(ContentPage)
