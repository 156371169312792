import React from 'react'
import { FormattedMessage } from 'react-intl'

export const DIALOG_CONFIRMATION_HISTORY_TITLE = (
    <FormattedMessage
        id="dialog.confirmation.history.title"
        defaultMessage="Вы действительно хотите удалить историю изменений?"
    />
)

export const DIALOG_CONFIRMATION_HISTORY_CONTENT = (
    <FormattedMessage
        id="dialog.confirmation.history.content"
        defaultMessage="Обратите внимание, данное действие необратимо и восстановить историю вы не сможете."
    />
)

export const DIALOG_CONFIRMATION_HISTORY_OK_BUTTON = (
    <FormattedMessage
        id="dialog.confirmation.history.ok.button"
        defaultMessage="Удалить"
    />
)

export const DIALOG_CONFIRMATION_HISTORY_CANCEL_BUTTON = (
    <FormattedMessage
        id="dialog.confirmation.history.cancel.button"
        defaultMessage="Отмена"
    />
)
