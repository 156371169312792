import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

export const MOD_DETAILS_DOWNLOAD_BUTTON_CAPTION = (size) => (
    <FormattedHTMLMessage
        id="mod.details.download.button.caption"
        defaultMessage="Скачать мод <mark>({size})</mark>"
        values={{ size }}
    />
)

export const MOD_DETAILS_DOWNLOADS = (downloads) => (
    <FormattedHTMLMessage
        id="mod.details.downloads"
        defaultMessage="Скачали: <mark>{downloads}</mark>"
        values={{ downloads }}
    />
)

export const MOD_DETAILS_MOD_VERSION = (modVersion) => (
    <FormattedHTMLMessage
        id="mod.details.mod.version"
        defaultMessage="Версия мода: <mark>{modVersion}</mark>"
        values={{ modVersion }}
    />
)

export const MOD_DETAILS_GAME_VERSION = (gameVersion, isNewVersion) => (
    <FormattedMessage
        id="mod.details.game.version"
        defaultMessage="Версия игры: {gameVersion}"
        values={{
            gameVersion: isNewVersion ? (<mark>{gameVersion}</mark>) : (<i>{gameVersion}</i>),
        }}
    />
)

export const MOD_DETAILS_GAME_CREATED_AT = (createdAt) => (
    <FormattedHTMLMessage
        id="mod.details.game.created.at"
        defaultMessage="Добавлен: <mark>{createdAt}</mark>"
        values={{ createdAt }}
    />
)

export const MOD_DETAILS_GAME_UPDATED_AT = (updatedAt) => (
    <FormattedHTMLMessage
        id="mod.details.game.updated.at"
        defaultMessage="Обновлён: <mark>{updatedAt}</mark>"
        values={{ updatedAt }}
    />
)

export const MOD_DETAILS_STATUS = (
    <FormattedMessage
        id="mod.details.status"
        defaultMessage="Проверен"
    />
)
