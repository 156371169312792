import React from 'react'
import { FormattedMessage } from 'react-intl'

export const EDIT_CHANGELOG_CURRENT_LABEL = (
    <FormattedMessage
        id="edit.changelog.current.label"
        defaultMessage="Изменения текущей версии"
    />
)

export const EDIT_CHANGELOG_LABEL = (
    <FormattedMessage
        id="edit.changelog.label"
        defaultMessage="Изменения версии"
    />
)
