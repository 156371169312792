import React from 'react'
import PropTypes from 'prop-types'

import CommonMenuAPI from 'utils/commonMenu'

export default class CommonMenu extends React.Component {
    static propTypes = {
        spaId: PropTypes.number,
        spaUsername: PropTypes.string,
        isFetching: PropTypes.bool.isRequired,
        onLoginClick: PropTypes.func.isRequired,
    }

    componentDidMount() {
        this.cm = new CommonMenuAPI({
            spaId: this.props.spaId,
            spaUsername: this.props.spaUsername,
            onLogin: this.onLoginClick,
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.isFetching !== prevProps.isFetching) {
            this.cm.setFetching(this.props.isFetching)
        }
    }

    onLoginClick = () => {
        this.props.onLoginClick()
    }

    render() {
        return null
    }
}
