import React from 'react'
import { FormattedMessage } from 'react-intl'

export const EDIT_CATEGORIES_LABEL = (
    <FormattedMessage
        id="edit.categories.label"
        defaultMessage="Выберите категории"
    />
)

export const EDIT_CATEGORIES_LABEL_TEXT = (
    <FormattedMessage
        id="edit.categories.label.text"
        defaultMessage="Не более трёх"
    />
)
