import React from 'react'
import { FormattedMessage } from 'react-intl'

export const EDIT_TO_MODERATOR_LABEL = (
    <FormattedMessage
        id="edit.to.moderator.label"
        defaultMessage="Информация для модератора"
    />
)

export const EDIT_TO_MODERATOR_LABEL_TEXT = (
    <FormattedMessage
        id="edit.to.moderator.label.text"
        defaultMessage="Не более 1 000 символов"
    />
)
