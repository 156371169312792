import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { removeMod } from 'actions/EditMod'
import { closeDialog } from 'actions/Dialog'

import DialogConfirmationModRemove
    from 'components/Dialogs/DialogConfirmationModRemove/DialogConfirmationModRemove'

const mapStateToProps = (state, ownProps) => ({
    id: ownProps.id,
    isDraftOrRejected: ownProps.isDraftOrRejected,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    onCloseDialog: () => {
        dispatch(closeDialog())
    },
    onSubmitDialog: (modVersionId) => {
        dispatch(removeMod(modVersionId, ownProps.history))
        dispatch(closeDialog())
    },
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DialogConfirmationModRemove)
)