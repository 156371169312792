import { concat, get } from 'lodash'

import {
    GALLERY_ITEMS_FILTERED_PER_PAGE,
    GALLERY_ITEMS_PER_PAGE,
    GALLERY_ITEMS_TYPES,
    GALLERY_ITEMS_FILTERED_ORDERING,
} from 'utils/constants'
import * as actions from 'actions/Gallery'
import settings from 'settings'

const getInitialState = () => ({
    [GALLERY_ITEMS_TYPES.RECOMMENDED]: {
        count: 0,
        isError: false,
        isFetchedAll: false,
        isFetching: false,
        items: [],
        offset: 0,
    },
    [GALLERY_ITEMS_TYPES.NEW]: {
        count: 0,
        isError: false,
        isFetchedAll: false,
        isFetching: false,
        items: [],
        offset: 0,
    },
    [GALLERY_ITEMS_TYPES.UPDATED]: {
        count: 0,
        isError: false,
        isFetchedAll: false,
        isFetching: false,
        items: [],
        offset: 0,
    },
    filtered: {
        count: 0,
        filterParams: {
            type: null,
            argument: null,
            ordering: GALLERY_ITEMS_FILTERED_ORDERING.RATING,
        },
        isError: false,
        isFetchedAll: false,
        isFetching: false,
        items: [],
        offset: 0,
    },
    overallFilters: {
        language: window.__PRELOADED_STATE__.currentAccount.lang,
        gameVersion: settings.gameVersions[0].id,
    },
})

/* eslint-disable complexity */
export const gallery = (state = getInitialState(), action) => {
    const itemsType = get(action, 'payload.itemsType')
    switch (action.type) {
        case actions.START_FETCHING_GALLERY_ITEMS:
            return {
                ...state,
                [itemsType]: {
                    ...state[itemsType],
                    isError: false,
                    isFetching: true,
                },
            }
        case actions.STOP_FETCHING_GALLERY_ITEMS:
            return {
                ...state,
                [itemsType]: {
                    ...state[itemsType],
                    isFetching: false,
                },
            }
        case actions.SET_ERROR_FETCHING_GALLERY_ITEMS:
            return {
                ...state,
                [itemsType]: {
                    ...state[itemsType],
                    isError: true,
                },
            }
        case actions.INCREMENT_FETCHING_OFFSET:
            return {
                ...state,
                [itemsType]: {
                    ...state[itemsType],
                    offset: state[itemsType].isFetchedAll ?
                        state[itemsType].offset :
                        state[itemsType].offset + GALLERY_ITEMS_PER_PAGE[itemsType],
                },
            }
        case actions.RESET_ITEMS:
            return {
                ...state,
                [GALLERY_ITEMS_TYPES.RECOMMENDED]: getInitialState()[GALLERY_ITEMS_TYPES.RECOMMENDED],
                [GALLERY_ITEMS_TYPES.NEW]: getInitialState()[GALLERY_ITEMS_TYPES.NEW],
                [GALLERY_ITEMS_TYPES.UPDATED]: getInitialState()[GALLERY_ITEMS_TYPES.UPDATED],
            }
        case actions.RESET_FILTER:
            return {
                ...state,
                filtered: getInitialState().filtered,
            }
        case actions.UPDATE_GALLERY_ITEMS:
            return {
                ...state,
                [itemsType]: {
                    ...state[itemsType],
                    isError: false,
                    items: concat(state[itemsType].items, action.payload.items),
                    isFetchedAll: (
                        state[itemsType].offset + GALLERY_ITEMS_PER_PAGE[itemsType] >= action.payload.count
                    ),
                    count: action.payload.count,
                },
            }
        default:
            return filterGalleryItems(state, action)
    }
}

const filterGalleryItems = (state = getInitialState(), action = {}) => {
    switch (action.type) {
        case actions.START_FILTERING_GALLERY_ITEMS:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    isError: false,
                    isFetching: true,
                },
            }
        case actions.STOP_FILTERING_GALLERY_ITEMS:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    isFetching: false,
                },
            }
        case actions.SET_ERROR_FILTERING_GALLERY_ITEMS:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    isError: true,
                },
            }
        case actions.INCREMENT_FILTERING_OFFSET:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    offset: state.filtered.isFetchedAll ?
                        state.filtered.offset :
                        state.filtered.offset + GALLERY_ITEMS_FILTERED_PER_PAGE,
                },
            }
        case actions.CHANGE_GALLERY_FILTERED_ITEMS_ORDERING:
            return {
                ...state,
                filtered: {
                    count: 0,
                    filterParams: {
                        ...state.filtered.filterParams,
                        ordering: action.payload.ordering,
                    },
                    isError: false,
                    isFetchedAll: false,
                    isFetching: false,
                    items: [],
                    offset: 0,
                },
            }
        case actions.UPDATE_GALLERY_FILTERED_ITEMS:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    filterParams: {
                        ...state.filtered.filterParams,
                        ...action.payload.filterParams,
                    },
                    isError: false,
                    items: state.filtered.offset ?
                        concat(state.filtered.items, action.payload.items) :
                        action.payload.items,
                    isFetchedAll: (
                        state.filtered.offset + GALLERY_ITEMS_FILTERED_PER_PAGE >= action.payload.count
                    ),
                    count: action.payload.count,
                },
            }
        default:
            return overallFilters(state, action)
    }
}

const overallFilters = (state = getInitialState(), action = {}) => {
    switch (action.type) {
        case actions.SET_OVERALL_LANGUAGE_FILTER:
            return {
                ...state,
                overallFilters: {
                    ...state.overallFilters,
                    language: action.payload.language,
                },
            }
        case actions.SET_OVERALL_GAME_VERSION_FILTER:
            return {
                ...state,
                overallFilters: {
                    ...state.overallFilters,
                    gameVersion: action.payload.gameVersionId,
                },
            }
        case actions.CLEAR_ALREADY_RECEIVED_ITEMS:
            return {
                ...state,
                [GALLERY_ITEMS_TYPES.RECOMMENDED]: getInitialState()[GALLERY_ITEMS_TYPES.RECOMMENDED],
                [GALLERY_ITEMS_TYPES.NEW]: getInitialState()[GALLERY_ITEMS_TYPES.NEW],
                [GALLERY_ITEMS_TYPES.UPDATED]: getInitialState()[GALLERY_ITEMS_TYPES.UPDATED],
                filtered: {
                    ...state.filtered,
                    count: 0,
                    isError: false,
                    isFetchedAll: false,
                    isFetching: false,
                    items: [],
                    offset: 0,
                },
            }
        default:
            return state
    }
}
