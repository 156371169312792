import React from 'react'
import { FormattedMessage } from 'react-intl'

export const FILE_UPLOAD_TITLE = (
    <FormattedMessage
        id="file.upload.title"
        defaultMessage="Загрузить файл"
    />
)

export const FILE_CANCEL_TITLE = (
    <FormattedMessage
        id="file.cancel.title"
        defaultMessage="Отменить"
    />
)

export const FILE_UPDATE_TITLE = (
    <FormattedMessage
        id="file.update.title"
        defaultMessage="Заменить файл"
    />
)

export const FILE_REMOVE_TITLE = (
    <FormattedMessage
        id="file.remove.title"
        defaultMessage="Удалить файл"
    />
)

export const FILE_UPDATE_FILE_NAME = (fileName, size) => (
    <FormattedMessage
        id="file.update.file.name"
        defaultMessage="Загружен файл: {fileName} {size}"
        values={{
            fileName: (<mark>{fileName}</mark>),
            size: size,
        }}
    />
)

export const FILE_UPLOAD_PROGRESS = (value) => (
    <FormattedMessage
        id="file.upload.progress"
        defaultMessage="Загрузка: {value}"
        values={{
            value: (<mark>{value}%</mark>),
        }}
    />
)
