import React from 'react'
import PropTypes from 'prop-types'

import styles from './TitleMinor.scss'

export default class TitleMinor extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
    }

    render() {
        return (
            <h2 className={styles.base}>{this.props.children}</h2>
        )
    }
}
