import React from 'react'
import { FormattedMessage } from 'react-intl'

export const ACTION_SUBSCRIBE = (
    <FormattedMessage
        id="action.subscribe"
        defaultMessage="Подписаться на обновления"
    />
)

export const ACTION_SUBSCRIBE_ACTIVE = (
    <FormattedMessage
        id="action.subscribe.active"
        defaultMessage="Вы подписаны на обновления"
    />
)
