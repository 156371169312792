import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './TableSorter.scss'

export default class TableSorter extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
        isActive: PropTypes.bool,
        isSortable: PropTypes.bool,
        isAsc: PropTypes.bool,
        onClick: PropTypes.func,
    }

    handleClick = () => {
        this.props.onClick(this.props.name)
    }

    render() {
        const type = this.props.isAsc ? 'asc' : 'desc'

        const classNameBase = classNames(styles.base, {
            [styles[`base__${type}`]]: this.props.isSortable,
            [styles.isSortable]: this.props.isSortable,
            [styles.isActive]: this.props.isActive,
        })

        return (
            <span
                className={classNameBase}
                onClick={this.props.isSortable ? this.handleClick : null}
            >
                {this.props.children}
            </span>
        )
    }
}
