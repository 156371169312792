import React from 'react'
import PropTypes from 'prop-types'

import {
    ButtonYellowLarge,
    Dialog,
    TitleDialog,
} from 'components'

import {
    DIALOG_ERROR_TITLE,
    DIALOG_ERROR_CONTENT,
    DIALOG_ERROR_CLOSE_BUTTON,
} from './translations'

import styles from './DialogError.scss'

export default class DialogError extends React.PureComponent {
    static propTypes = {
        content: PropTypes.node,
        isOpened: PropTypes.bool.isRequired,
        title: PropTypes.node,
        onCloseDialog: PropTypes.func.isRequired,
    }

    renderContent() {
        const title = this.props.title || DIALOG_ERROR_TITLE
        const content = this.props.content || DIALOG_ERROR_CONTENT
        return (
            <div className={styles.base}>
                <TitleDialog>{title}</TitleDialog>
                <div className={styles.content}>
                    <p>{content}</p>
                </div>
                <ButtonYellowLarge onClick={this.props.onCloseDialog}>
                    {DIALOG_ERROR_CLOSE_BUTTON}
                </ButtonYellowLarge>
            </div>
        )
    }

    render () {
        return (
            <Dialog
                content={this.renderContent()}
                isOpened={this.props.isOpened}
                onCloseDialog={this.props.onCloseDialog}
            />
        )
    }
}
