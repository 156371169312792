import React from 'react'
import { FormattedMessage } from 'react-intl'

export const EDIT_CHANGE_LOG_ADD_HISTORY_LABEL = (
    <FormattedMessage
        id="edit.change.log.add.history.label"
        defaultMessage="История изменений предыдущих версий"
    />
)

export const EDIT_CHANGE_LOG_ADD_HISTORY_BUTTON = (
    <FormattedMessage
        id="edit.change.log.add.history.button"
        defaultMessage="Добавить историю изменений"
    />
)

export const EDIT_CHANGE_LOG_ADD_PREV_VERSION_LABEL = (
    <FormattedMessage
        id="edit.change.log.add.prev.version.label"
        defaultMessage="Номер предыдущей версии"
    />
)

export const EDIT_CHANGE_LOG_ADD_PREV_VERSION_DESCRIPTION_LABEL = (
    <FormattedMessage
        id="edit.change.log.add.prev.version.description.label"
        defaultMessage="Изменения предыдущей версии"
    />
)
