import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { closeDialog } from 'actions/Dialog'

import Dialog from 'components/Dialogs/Dialog/Dialog'

const mapStateToProps = (state) => ({
    content: state.dialog.content,
    isNavigatingToLandingOnClose: state.dialog.isNavigatingToLandingOnClose,
    isOpened: state.dialog.isOpened,
})

const mapDispatchToProps = {
    onCloseDialog: closeDialog,
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Dialog),
)
