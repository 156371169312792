import React from 'react'
import { Route, Switch } from 'react-router-dom'

import {
    CommonMenu,
    ContentPage,
    CreateMod,
    Details,
    Dialog,
    DialogError,
    Landing,
    ModderSection,
    Nav,
    News,
    Processing,
    SearchResult,
    Subscriptions,
    EditMod,
} from 'containers'

import {
    Error404,
    Footer,
} from 'components'

import { urls } from 'utils/routing'
import settings from 'settings'

import styles from './MainWrapper.scss'

export default class MainWrapper extends React.Component {
    renderCommonMenu() {
        return settings.isCommonMenuEnabled ? (
            <CommonMenu />
        ) : null
    }

    render() {
        return (
            <div className={styles.base}>
                {this.renderCommonMenu()}
                <div className={styles.nav}>
                    <Nav />
                </div>
                <div className={styles.body}>
                    <Switch>
                        <Route exact path={urls.landing} component={Landing} />
                        <Route path={urls.contentPage} component={ContentPage} />
                        <Route exact path={'/:modId(\\d+)/(details)?'} component={Details} />
                        <Route path={urls.news} component={News} />
                        <Route path={urls.search} component={SearchResult} />
                        <Route path={urls.subscriptions} component={Subscriptions} />
                        <Route path={urls.edit} component={EditMod} />

                        <Route path={urls.create} component={CreateMod} />
                        <Route path={urls.modderSection} component={ModderSection} />

                        <Route component={Error404} />
                    </Switch>
                </div>
                <div className={styles.footer}>
                    <Footer />
                </div>
                <Dialog />
                <DialogError />
                <Processing />
            </div>
        )
    }
}
