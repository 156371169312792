import React from 'react'
import PropTypes from 'prop-types'

import {
    ButtonYellowLarge,
    TitleDialog,
} from 'components'

import { DIALOG_IE_ALERT_CONTENT } from './translations'

import {
    ATTENTION,
    CLOSE,
} from 'translations'

import styles from './DialogIEAlert.scss'

export default class DialogIEAlert extends React.PureComponent {
    static propTypes = {
        onCloseDialog: PropTypes.func.isRequired,
    }

    render() {
        return (
            <div className={styles.base}>
                <TitleDialog>{ATTENTION}</TitleDialog>
                <div className={styles.content}>
                    <p>{DIALOG_IE_ALERT_CONTENT}</p>
                </div>
                <div>
                    <div className={styles.button}>
                        <ButtonYellowLarge onClick={this.props.onCloseDialog}>
                            {CLOSE}
                        </ButtonYellowLarge>
                    </div>
                </div>
            </div>
        )
    }
}
