import { get } from 'lodash'

import * as actions from 'actions/ContentPage'

const getInitialState = () => ({
    data: {},
    isError: false,
    isErrorNotFound: false,
    isFetched: false,
    isFetching: false,
    slug: null,
})

/* eslint-disable complexity */
export const contentPage = (state = getInitialState(), action) => {
    switch (action.type) {
        case actions.START_FETCHING_CONTENT_PAGE:
            return {
                ...state,
                isError: false,
                isErrorNotFound: false,
                isFetching: true,
                isFetched: false,
            }
        case actions.STOP_FETCHING_CONTENT_PAGE:
            return {
                ...state,
                isFetching: false,
                isFetched: true,
            }
        case actions.SET_ERROR_FETCHING_CONTENT_PAGE:
            return {
                ...state,
                isError: true,
                isErrorNotFound: get(action.payload, 'errorData.status') === 404,
            }

        case actions.UPDATE_CONTENT_PAGE:
            return {
                ...state,
                data: action.payload.data,
                slug: action.payload.slug,
                isError: false,
                isErrorNotFound: false,
            }
        default:
            return state
    }
}
