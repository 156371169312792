import { get } from 'lodash'

import * as actions from 'actions/Subscriptions'

const initialState = {
    initialized: true,
    isError: false,
    isFetching: false,
    items: [],
    count: 0,
}

export const subscriptions = (state = initialState, action) => {
    const payload = get(action, 'payload')
    switch (action.type) {
        case actions.START_FETCHING_SUBSCRIPTIONS:
            return {
                ...state,
                isError: false,
                isFetching: true,
            }
        case actions.SET_ERROR_FETCHING_SUBSCRIPTIONS:
            return {
                ...state,
                isFetching: false,
                isError: get(action.payload, 'errorData.status') !== 403,
            }
        case actions.STOP_FETCHING_SUBSCRIPTIONS:
            return {
                ...state,
                isFetching: false,
            }
        case actions.UPDATE_ITEMS_SUBSCRIPTIONS:
            return {
                ...state,
                items: payload.items,
            }
        default:
            return state
    }
}
