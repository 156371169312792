import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

import { Information } from 'components'

import { pushHistoryWithLanding } from 'utils/routing'

import {
    ERROR_BLOCKED_CONTENT,
} from './translations'

import { GO_TO_LANDING } from 'translations'

import styles from './ErrorBlocked.scss'

export default class ErrorBlocked extends React.PureComponent {
    static propTypes = {
        history: ReactRouterPropTypes.history.isRequired,
    }

    handleClick = () => {
        pushHistoryWithLanding(this.props.history)
    }

    render() {
        return (
            <div className={styles.base}>
                <div>
                    <div className={styles.icon} />
                    <Information
                        message={ERROR_BLOCKED_CONTENT}
                        buttonCaption={GO_TO_LANDING}
                        onButtonClick={this.handleClick}
                    />
                </div>
            </div>
        )
    }
}
