import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

export const DIALOG_INFORMATION_TITLE = (
    <FormattedMessage
        id="dialog.information.title"
        defaultMessage="Важная информация!"
    />
)

export const DIALOG_INFORMATION_CONTENT = (
    <FormattedHTMLMessage
        id="dialog.information.content"
        defaultMessage="Пожалуйста, обратите внимание: <mark>моды создаются игроками World of Tanks, а не компанией Wargaming</mark>. Все представленные на портале моды проходят предварительные базовые проверки, однако <mark>мы не несём ответственности за их работоспособность, качество и безопасность</mark>."
    />
)

export const DIALOG_INFORMATION_WELCOME = (
    <FormattedMessage
        id="dialog.information.welcome"
        defaultMessage="Спасибо за понимание и приятной игры!"
    />
)

export const DIALOG_INFORMATION_OK_BUTTON = (
    <FormattedMessage
        id="dialog.information.ok.button"
        defaultMessage="Понятно"
    />
)
