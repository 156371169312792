import { connect } from 'react-redux'

import { changeLanguage } from 'actions/CurrentAccount'

import settings from 'settings'

import LanguageSelect from 'components/UI/LanguageSelect/LanguageSelect'

const mapStateToProps = (state) => ({
    languages: settings.portalLanguages,
    selected: state.currentAccount.lang,
})

const mapDispatchToProps = {
    onChange: changeLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelect)
