import React from 'react'
import { FormattedMessage } from 'react-intl'

import { FileUploadCaption } from 'components'

export const EDIT_COVER_UPLOAD_LABEL = (
    <FormattedMessage
        id="edit.cover.upload.label"
        defaultMessage="Загрузка обложки"
    />
)

export const EDIT_COVER_UPLOAD_ALERT = (
    <FormattedMessage
        id="edit.cover.upload.alert"
        defaultMessage="Разрешение обложки в списке модов{NBSP}— 302{NBSP}x{NBSP}170{NBSP}px. Картинка была автоматически спозиционирована и обрезана."
        values={{ NBSP: '\u00a0' }}
    />
)

export const EDIT_COVER_UPLOAD_FILE_FORMAT = (isError) => (
    <FormattedMessage
        id="edit.cover.upload.file.format"
        defaultMessage="Формат файла: {ext0} или {ext1}"
        values={{
            ext0: (<FileUploadCaption isError={isError} caption="PNG" />),
            ext1: (<FileUploadCaption isError={isError} caption="JPG" />),
        }}
    />
)

export const EDIT_COVER_UPLOAD_FILE_DIMENSIONS = (isError) => (
    <FormattedMessage
        id="edit.cover.upload.file.dimensions"
        defaultMessage="Разрешение: {dimensions}"
        values={{
            dimensions: (
                <FileUploadCaption
                    isError={isError}
                    caption={(
                        <FormattedMessage
                            id="edit.cover.upload.file.dimensions.caption"
                            defaultMessage="Не менее 302 x 170"
                        />
                    )}
                />
            ),
        }}
    />
)

export const EDIT_COVER_UPLOAD_FILE_SIZE = (isError) => (
    <FormattedMessage
        id="edit.cover.upload.file.size"
        defaultMessage="Размер: {size}"
        values={{
            size: (
                <FileUploadCaption
                    isError={isError}
                    caption={(
                        <FormattedMessage
                            id="edit.cover.upload.file.size.caption"
                            defaultMessage="Не более 4MB"
                        />
                    )}
                />
            ),
        }}
    />
)
