import React from 'react'
import { batchActions } from 'redux-batched-actions'
import { get } from 'lodash'

import { fetchWithSpinner, fetchWrapper as fetch } from 'utils/fetch'
import { processModDetailsData } from 'utils/responseProcessing'
import urls from 'apiUrls'

import { openDialog } from 'actions/Dialog'
import { openDialogError } from 'actions/DialogError'
import {
    DialogDownload,
    DialogInformation,
    DialogReportModComplete,
} from 'containers'
import {
    MESSAGE_IS_NOT_SENT,
    MOD_WAS_DELETED,
    ALREADY_SENT_REPORT_TITLE,
    ALREADY_SENT_REPORT_DETAILS,
} from 'translations'

export const showReportDialogSuccess = () => {
    return (dispatch) => {
        dispatch(openDialog(<DialogReportModComplete />))
    }
}

export const showDialogDownload = (modId, title, versions) => {
    return (dispatch) => {
        dispatch(openDialog(<DialogDownload modId={modId} title={title} versions={versions} />))
    }
}

export const showDialogInformation = (modId, title, versions) => {
    return (dispatch) => {
        dispatch(openDialog(<DialogInformation modId={modId} title={title} versions={versions} />))
    }
}

export const processDownloadMod = (isModDownloadAccepted, modId, title, versions) => {
    return (dispatch) => {
        if (isModDownloadAccepted) {
            dispatch(showDialogDownload(modId, title, versions))
        } else {
            dispatch(showDialogInformation(modId, title, versions))
        }
    }
}

export const SET_ERROR_FETCHING_DETAILS = 'SET_ERROR_FETCHING_DETAILS'
export const SET_REPORT_COMPLETED = 'SET_REPORT_COMPLETED'
export const START_FETCHING_DETAILS = 'START_FETCHING_DETAILS'
export const STOP_FETCHING_DETAILS = 'STOP_FETCHING_DETAILS'
export const UPDATE_DETAILS = 'UPDATE_DETAILS'
export const UPDATE_VOTING = 'UPDATE_VOTING'

export const startFetchingDetails = () => ({
    type: START_FETCHING_DETAILS,
})

export const stopFetchingDetails = () => ({
    type: STOP_FETCHING_DETAILS,
})

export const updateDetails = (rawResponseData, state) => ({
    type: UPDATE_DETAILS,
    payload: {
        data: processModDetailsData(rawResponseData, state),
        id: rawResponseData.id,
    },
})

export const updateVoting = (rawResponseData) => ({
    type: UPDATE_VOTING,
    payload: {
        value: rawResponseData.value,
    },
})

export const setReportCompleted = () => ({
    type: SET_REPORT_COMPLETED,
})

export const setErrorFetchingDetails = (errorData) => ({
    type: SET_ERROR_FETCHING_DETAILS,
    payload: {
        errorData,
    },
})

export const fetchDetails = (modId) => {
    return (dispatch, getState) => {
        const state = getState()
        const url = `${urls.mods}${modId}/`

        dispatch(startFetchingDetails())

        const fetchPromise = fetch(url).promise
            .then((rawResponseData) => {
                dispatch(batchActions([
                    updateDetails(rawResponseData, state),
                    stopFetchingDetails(),
                ], UPDATE_DETAILS))
            }, (errorData) => {
                dispatch(batchActions([
                    setErrorFetchingDetails(errorData),
                    stopFetchingDetails(),
                ], SET_ERROR_FETCHING_DETAILS))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const voteForMod = (modId, value) => {
    return (dispatch) => {
        const body = {
            mod: modId,
            value: value,
        }

        const fetchPromise = fetch(urls.votes, { method: 'POST', body }).promise
            .then((rawResponseData) => {
                dispatch(updateVoting(rawResponseData))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const reportMod = ({ modId, modVersionId, problemIds, problemDescription, fileId, fileToken }) => {
    return (dispatch, getState) => {
        const state = getState()

        let body = {
            description: problemDescription,
            lang: state.currentAccount.lang,
            mod: modId,
            mod_version: modVersionId,
            problem_types: problemIds,
        }
        if (fileId && fileToken) {
            body = {
                ...body,
                temporary_file: fileId,
                temporary_file_access_token: fileToken,
            }
        }

        const fetchPromise = fetch(urls.reports, { method: 'POST', body }).promise
            .then(() => {
                dispatch(setReportCompleted())
                dispatch(showReportDialogSuccess())
            }, (errorData) => {
                let error = get(errorData, 'response.body.mod[0]')
                if (!error) {
                    error = get(errorData, 'response.body.mod_version[0]')
                }
                if (['mod_version_is_not_active', 'mod_is_not_active'].includes(error)) {
                    dispatch(openDialogError(MOD_WAS_DELETED, MESSAGE_IS_NOT_SENT))
                } else if (error === 'active_report_already_exists_for_this_user') {
                    dispatch(openDialogError(ALREADY_SENT_REPORT_DETAILS, ALREADY_SENT_REPORT_TITLE))
                } else {
                    dispatch(openDialogError())
                }
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const incrementDownloadCount = (modId) => {
    return () => {
        const url = urls.downloadCounter.replace(/:(modId)/g, modId)
        return fetch(url).promise
    }
}
