import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import apiUrls from 'apiUrls'
import { FIELD_VALIDATION_ERROR_TYPES } from 'utils/constants'

import {
    Caption,
    EditLabelError,
    FileUpload,
} from 'components'

import {
    EDIT_UPLOAD_MOD_COMMON_ERROR,
    EDIT_UPLOAD_MOD_FILE_FORMAT,
    EDIT_UPLOAD_MOD_FILE_SIZE,
    EDIT_UPLOAD_MOD_INVALID_FILE_SIZE_ERROR,
    EDIT_UPLOAD_MOD_INVALID_FILE_TYPE_ERROR,
    EDIT_UPLOAD_MOD_LABEL,
    EDIT_UPLOAD_MOD_NO_FILE_SELECTED,
} from './translations'

import styles from './EditUploadMod.scss'

export default class EditUploadMod extends React.PureComponent {
    static propTypes = {
        externalError: PropTypes.oneOf([
            FIELD_VALIDATION_ERROR_TYPES.COMMON_ERROR,
            FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR,
            FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR,
            FIELD_VALIDATION_ERROR_TYPES.NO_FILE_SELECTED,
        ]),
        externalFileName: PropTypes.string,
        externalFileSize: PropTypes.number,
        isSecondary: PropTypes.bool,

        onUploadCompleted: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            errorType: null,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.externalError) {
            this.setState({
                errorType: nextProps.externalError,
            })
        }
    }

    handleUploadCompleted = (data, errorType) => {
        this.setState({
            errorType: errorType || null,
        })
        this.props.onUploadCompleted(get(data, '[0]', null), errorType)
    }

    renderErrorLabel() {
        const errorToMessagesMapping = {
            [FIELD_VALIDATION_ERROR_TYPES.COMMON_ERROR]: EDIT_UPLOAD_MOD_COMMON_ERROR,
            [FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR]: EDIT_UPLOAD_MOD_INVALID_FILE_SIZE_ERROR,
            [FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR]: EDIT_UPLOAD_MOD_INVALID_FILE_TYPE_ERROR,
            [FIELD_VALIDATION_ERROR_TYPES.NO_FILE_SELECTED]: EDIT_UPLOAD_MOD_NO_FILE_SELECTED,
        }
        return this.state.errorType ? (
            <EditLabelError message={errorToMessagesMapping[this.state.errorType]} />
        ) : null
    }

    render() {
        const isErrorFileFormat = this.state.errorType === FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR
        const isErrorFileSize = this.state.errorType === FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR

        return (
            <div className={styles.base}>
                <div className={styles.label}>
                    <Caption
                        isLarge={!this.props.isSecondary}
                        isRequired
                        isError={!!this.props.externalError}
                    >
                        {EDIT_UPLOAD_MOD_LABEL}
                    </Caption>
                    {this.renderErrorLabel()}
                </div>
                <div className={styles.field}>
                    <FileUpload
                        externalFileName={this.props.externalFileName}
                        externalFileSize={this.props.externalFileSize}
                        fieldName="source"
                        isError={!!this.props.externalError}
                        summary={(
                            <span>
                                <span className={styles.fileUploadSummary}>
                                    {EDIT_UPLOAD_MOD_FILE_FORMAT(isErrorFileFormat)}
                                </span>
                                <span className={styles.fileUploadSummary}>
                                    {EDIT_UPLOAD_MOD_FILE_SIZE(isErrorFileSize)}
                                </span>
                            </span>
                        )}
                        url={apiUrls.modFileUpload}
                        onUploadCompleted={this.handleUploadCompleted}
                    />
                </div>
            </div>
        )
    }
}
