import React from 'react'
import PropTypes from 'prop-types'
import { NavLink, Route } from 'react-router-dom'

import { Search } from 'components'

import { urls, pushHistoryWithTitle } from 'utils/routing'

import {
    NAV_LINK_MAIN,
    NAV_LINK_NEWS,
    NAV_LINK_SUBSCRIPTIONS,
    NAV_LINK_MODDER_SECTION,
} from './translations'

import styles from './Nav.scss'

export default class Nav extends React.PureComponent {
    static propTypes = {
        searchTerm: PropTypes.string.isRequired,
        updatedModsCount: PropTypes.number,
    }

    renderUpdatedCount() {
        if (!this.props.updatedModsCount) {
            return null
        }

        const countLabel = this.props.updatedModsCount <= 99 ? this.props.updatedModsCount : '99+'

        return (
            <span className={styles.counter}>{countLabel}</span>
        )
    }

    render() {
        return (
            <div className={styles.base}>
                <nav className={styles.inner}>
                    <a href="/" className={styles.logo} />

                    <NavLink exact to={urls.landing} className={styles.link} activeClassName={styles.isActive}>
                        {NAV_LINK_MAIN}
                    </NavLink>

                    <NavLink exact to={urls.news} className={styles.link} activeClassName={styles.isActive}>
                        {NAV_LINK_NEWS}
                    </NavLink>

                    <NavLink exact to={urls.subscriptions} className={styles.link} activeClassName={styles.isActive}>
                        {NAV_LINK_SUBSCRIPTIONS}
                        {this.renderUpdatedCount()}
                    </NavLink>

                    <NavLink exact to={urls.modderSection} className={styles.link} activeClassName={styles.isActive}>
                        {NAV_LINK_MODDER_SECTION}
                    </NavLink>

                    <Route render={({ history }) => (
                        <div className={styles.search}>
                            <Search
                                value={this.props.searchTerm}
                                onSearchContolClick={(value) => {
                                    value && pushHistoryWithTitle(history, value)
                                }}
                            />
                        </div>
                    )} />
                </nav>
            </div>
        )
    }
}
