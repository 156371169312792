// Cloned and modified from https://github.com/odynvolk/map-keys-deep-lodash

import { isObject, mapKeys as mapKeysLodash } from 'lodash'

const mapKeysArray = (array, callback) => array.map(mapKeysDeep(callback))

const mapKeysDeep = (callbak) => (value) => {
    if (!value) return value
    if (Array.isArray(value)) return mapKeysArray(value, callbak)
    return isObject(value) ? mapKeys(value, callbak) : value
}

const mapKeys = (object, callback) => {
    if (Array.isArray(object)) return mapKeysArray(object, callback)

    object = mapKeysLodash(object, callback)

    const result = {}

    mapKeysLodash(object, (value, key) => {
        result[key] = isObject(value) ? mapKeys(value, callback) : value
    })

    return result
}

export default mapKeys
