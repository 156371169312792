import React from 'react'
import PropTypes from 'prop-types'
import { formatDate } from 'utils/formatting'
import { scrollToElement } from 'utils/domManipulation'

import styles from './NewsContentsList.scss'

const SCROLL_OFFSET = 161
const SCROLL_DURATION = 500

export default class NewsContentsList extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            content: PropTypes.node.isRequired,
            title: PropTypes.string.isRequired,
            publishedAt: PropTypes.string.isRequired,

        })),
        activeItemId: PropTypes.number,
        scrollToActiveItem: PropTypes.bool,

        onActiveItemChanged: PropTypes.func.isRequired,
    }

    componentDidMount() {
        this.initItemsViewportMap()
    }

    componentDidUpdate() {
        this.initItemsViewportMap()
        
        const activeItemRef = this[`_item-${this.props.activeItemId}`]
        
        if (activeItemRef && this.props.scrollToActiveItem) {
            scrollToElement(this._wrapper, activeItemRef, SCROLL_DURATION, () => {
                this.props.onActiveItemChanged(this.props.activeItemId)
            })
        }
    }

    handleActiveItemChanged = (itemId) => {
        if (itemId !== this.props.activeItemId) {
            this.props.onActiveItemChanged(itemId)
        }
    }

    initItemsViewportMap() {
        this._itemsViewportMap = {}

        this.props.items.forEach((item) => {
            const itemElement = this[`_item-${item.id}`]

            this._itemsViewportMap[item.id] = {
                top: itemElement.offsetTop,
                height: itemElement.offsetHeight,
            }
        })
    }

    renderItems() {
        return this.props.items.map((item) => {
            const html = { __html: item.content }
            return (
                <div className={styles.item} ref={(c) => {this[`_item-${item.id}`] = c}} key={item.id}>
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.date}>{formatDate(item.publishedAt, 'DD.MM.YY')}</div>
                    <div className={styles.content} dangerouslySetInnerHTML={html} />
                </div>
            )
        })
    }

    handleScroll = () => {
        if (this.props.scrollToActiveItem) {
            return
        }

        const scrollTop = this._wrapper.scrollTop

        for (let i = 0; i < this.props.items.length; i++) {
            const id = this.props.items[i].id
            const itemParams = this._itemsViewportMap[id]

            if (itemParams.height + itemParams.top > scrollTop + SCROLL_OFFSET) {
                this.handleActiveItemChanged(id)
                break
            }
        }

    }

    render() {
        return (
            <div className={styles.base}>
                <div
                    ref={(c) => this._wrapper = c}
                    className={styles.wrapper}
                    onScroll={this.handleScroll}
                >
                    <div className={styles.items}>
                        {this.renderItems()}
                    </div>
                </div>
            </div>
        )
    }
}
