import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

import settings from 'settings'

import {
    ButtonYellowSmall,
    Notification,
} from 'components'

import {
    NOTIFICATION_BLOCKED_USER_CONTENT,
    NOTIFICATION_BLOCKED_USER_CONTENT_REQUIREMENT_BATTLES_COUNT,
    NOTIFICATION_BLOCKED_USER_CONTENT_REQUIREMENT_DAYS,
} from './translations'

import { GO_TO_LANDING } from 'translations'

import { pushHistoryWithLanding } from 'utils/routing'

import styles from './NotificationBlockedUser.scss'

export default class NotificationBlockedUser extends React.PureComponent {
    static propTypes = {
        history: ReactRouterPropTypes.history.isRequired,
    }

    handleClick = () => {
        pushHistoryWithLanding(this.props.history)
    }

    render() {
        const days = settings.uploadRestrictions.days
        const battlesCount = settings.uploadRestrictions.battlesCount

        return (
            <div className={styles.base}>
                <Notification type="warning">
                    <div className={styles.inner}>
                        <div className={styles.icon} />
                        <div className={styles.content}>
                            <p>{NOTIFICATION_BLOCKED_USER_CONTENT}</p>
                            <ol>
                                <li>{NOTIFICATION_BLOCKED_USER_CONTENT_REQUIREMENT_DAYS(days)}</li>
                                <li>{NOTIFICATION_BLOCKED_USER_CONTENT_REQUIREMENT_BATTLES_COUNT(battlesCount)}</li>
                            </ol>
                        </div>
                        <div className={styles.button}>
                            <ButtonYellowSmall onClick={this.handleClick}>
                                {GO_TO_LANDING}
                            </ButtonYellowSmall>
                        </div>
                    </div>
                </Notification>
            </div>
        )
    }
}
