import { each } from 'lodash'

import settings from 'settings'
import urls from 'apiUrls'
import { fetchWithSpinner, fetchWrapper as fetch } from 'utils/fetch'
import { pushHistoryWithModderSection } from 'utils/routing'

import { openDialogError } from 'actions/DialogError'
import { setIsBanned } from 'actions/CurrentAccount'

export const showDialogError = () => {
    return (dispatch) => {
        dispatch(openDialogError())
    }
}

const prepareChangelogData = (data, state) => {
    let result = []

    if (data.modVersion && data.changelog) {
        result = [
            {
                body: data.changelog,
                date: '',
                version: data.modVersion,
            },
        ]
    }

    each(state.changelogHistory.items, (historyItem) => {
        result.push({
            body: historyItem.content,
            date: '',
            version: historyItem.version,
        })
    })

    return result
}

const prepareScreenshotsData = (data) => {
    const result = []

    each(data.screenshots, (item, index) => {
        result.push({
            access_token: item.accessToken,
            position: index,
            temporary_file: item.fileId,
        })
    })

    return result
}

const prepareLocalizationsData = (data) => {
    const result = []

    each(data.byLanguages, (item, language) => {
        if (!item.title) {
            return
        }

        result.push({
            lang: settings.portalLanguages.find((item) => item.code === language).id,
            title: item.title,
            description: item.description,
            installation_guide: item.installationGuide,
        })
    })

    return result
}

const prepareVersionsData = (data) => {
    const result = {
        game_version: data.gameVersionId,
        comment: data.comment || '',
    }
    if (data.modVersion) {
        result.version = data.modVersion
    }
    if (data.modFileId && data.modFileToken) {
        result.temporary_version_file = data.modFileId
        result.temporary_version_file_access_token = data.modFileToken
    }
    return [result]
}

export const prepareBody = (data, state) => {
    let body = {
        status: data.status,
        change_log: [],
        localizations: [],
        screenshots: [],
        tags: data.categories,
    }

    if (data.coverFileId && data.coverFileToken) {
        body.temporary_cover_file = data.coverFileId
        body.temporary_cover_file_access_token = data.coverFileToken
    }

    if (data.authorName) {
        body.author_name = data.authorName
    }

    body.change_log = prepareChangelogData(data, state)
    body.screenshots = prepareScreenshotsData(data)
    body.localizations = prepareLocalizationsData(data)
    body.versions = prepareVersionsData(data)

    return body
}

export const saveMod = (data, history) => {
    return (dispatch, getState) => {
        const state = getState()
        const body = prepareBody(data, state)
        const headers = {
            'X-API-Version': '2',
        }

        const fetchPromise = fetch(urls.mods, { method: 'POST', body, headers }).promise
            .then(() => {
                pushHistoryWithModderSection(history)
            }, (error) => {
                if (error.status === 403) {
                    Promise.resolve(
                        dispatch(setIsBanned())
                    ).then(() => {
                        pushHistoryWithModderSection(history)
                    })
                } else {
                    dispatch(showDialogError())
                }
                // eslint-disable-next-line no-console
                console.log('something went wrong')
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}
