import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

import { Header, Main } from 'components'
import { Categories, Gallery, FilterLanguage } from 'containers'

import styles from './Landing.scss'

export default class Landing extends React.Component {
    static propTypes = {
        history: ReactRouterPropTypes.history.isRequired,
        location: ReactRouterPropTypes.location.isRequired,
    }

    render() {
        return (
            <div className={styles.base}>
                <div className={styles.head}>
                    <Header>
                        <div className={styles.selectPanel}>
                            <FilterLanguage />
                        </div>
                        <div className={styles.categories}>
                            <Categories history={this.props.history} />
                        </div>
                    </Header>
                </div>
                <div className={styles.body}>
                    <Main>
                        <Gallery location={this.props.location} history={this.props.history} />
                    </Main>
                </div>
            </div>
        )
    }
}
