import React from 'react'
import { FormattedMessage } from 'react-intl'

export const DIALOG_REPORT_MOD_COMPLETE_TITLE = (
    <FormattedMessage
        id="dialog.report.mod.complete.title"
        defaultMessage="Спасибо! Сообщение о проблеме отправлено"
    />
)

export const DIALOG_REPORT_MOD_COMPLETE_CONTENT = (
    <FormattedMessage
        id="dialog.report.mod.complete.content"
        defaultMessage="Мы обязательно проверим этот мод на наличие проблем. Благодарим вас за бдительность."
    />
)

export const DIALOG_REPORT_MOD_COMPLETE_OK_BUTTON = (
    <FormattedMessage
        id="dialog.report.mod.complete.ok.button"
        defaultMessage="Закрыть"
    />
)
