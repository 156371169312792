import React from 'react'
import PropTypes from 'prop-types'

import styles from './ActionMuteSubscribe.scss'

export default class ActionMuteSubscribe extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
    }

    render() {
        return (
            <div className={styles.base} onClick={this.props.onClick} />
        )
    }
}
