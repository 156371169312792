import React from 'react'
import { FormattedMessage } from 'react-intl'

export const FILTER_GAME_VERSION_CAPTION = (
    <FormattedMessage
        id="filter.game.version.caption"
        defaultMessage="Версия игры:"
    />
)

export const FILTER_GAME_VERSION_CURRENT_VERSION = (version) => (
    <FormattedMessage
        id="filter.game.version.current.version"
        defaultMessage="Текущая ({version})"
        values={{ version }}
    />
)
