import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import classNames from 'classnames'
import { each, get, sumBy } from 'lodash'
import FileSaver from 'file-saver'

import { browserName } from 'utils/helpers'
import { formatFileSize } from 'utils/formatting'
import { urls } from 'utils/routing'

import {
    Back,
    ButtonYellowOutlineLarge,
    Divider,
    Error,
    ErrorEmptySubscriptions,
    Information,
    Main,
    TitleMajor,
    TitleMinor,
} from 'components'

import SubscriptionsItem from './components/SubscriptionsItem/SubscriptionsItem'

import {
    COMMON_ERROR,
    TO_LANDING,
} from 'translations'

import {
    messages,
    SUBSCRIPTIONS_PAGE_TITLE,
    SUBSCRIPTIONS_PAGE_TITLE_WITHOUT_COUNTER,
    SUBSCRIPTIONS_PAGE_MOD_DOWNLOAD_ALL_LBL,
    SUBSCRIPTIONS_PAGE_MOD_DOWNLOAD_FAVORITES,
    SUBSCRIPTIONS_PAGE_LAST_UPDATED_TITLE,
    SUBSCRIPTIONS_PAGE_FAVORITES_TITLE,
    SUBSCRIPTIONS_PAGE_LOGIN,
    SUBSCRIPTIONS_PAGE_UNAUTHORIZED_MESSAGE,
} from './translations'

import styles from './Subscriptions.scss'

class Subscriptions extends React.Component {
    static propTypes = {
        isError: PropTypes.bool,
        isFetching: PropTypes.bool.isRequired,
        isLoggedIn: PropTypes.bool,
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            isUpdated: PropTypes.bool.isRequired,
            mod: PropTypes.shape({
                cover: PropTypes.string,
                id: PropTypes.number.isRequired,
                mark: PropTypes.number.isRequired,
                modVersion: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                versions: PropTypes.arrayOf(PropTypes.shape({
                    createdAt: PropTypes.string.isRequired,
                    downloadUrl: PropTypes.string.isRequired,
                    id: PropTypes.number.isRequired,
                    modVersion: PropTypes.string.isRequired,
                    versionFileOriginalName: PropTypes.string.isRequired,
                    versionFileSize: PropTypes.number,
                })).isRequired,
            }),
        })),

        history: ReactRouterPropTypes.history.isRequired,

        intl: intlShape,

        lang: PropTypes.string.isRequired,

        onIncrementDownloadCount: PropTypes.func.isRequired,
        onIgnoreUpdate: PropTypes.func.isRequired,
        onShowLoginDialog: PropTypes.func.isRequired,
        fetchItems: PropTypes.func.isRequired,
        onUnsubscribe: PropTypes.func.isRequired,
    }

    componentWillMount() {
        this.props.fetchItems()

        document.title = this.props.intl.formatMessage(messages.subscriptionsTitle)
    }

    handlerDownloadFiles(isUpdated) {
        const actions = []
        each(this.props.items, (item) => {
            if (item.isUpdated === isUpdated) {
                const version = item.mod.versions[0]
                FileSaver.saveAs(version.downloadUrl, version.versionFileOriginalName)
                actions.push(this.props.onIncrementDownloadCount(item.mod.id))
            }
        })
        return Promise.all(actions)
    }

    handlerDownloadUpdatedFiles = (e) => {
        e.stopPropagation()
        this.handlerDownloadFiles(true).then(() => {
            this.props.fetchItems()
        })
    }

    handlerDownloadSubscriptionFiles = (e) => {
        e.stopPropagation()
        this.handlerDownloadFiles(false)
    }

    renderError() {
        return this.props.isError ? (
            <Error>{COMMON_ERROR}</Error>
        ) : null
    }

    renderEmptyList() {
        return this.props.isLoggedIn && !this.props.isError && !this.props.isFetching && (this.props.items.length === 0) ? (
            <ErrorEmptySubscriptions history={this.props.history} lang={this.props.lang} />
        ) : null
    }

    renderDownloadAllButton(items, isUpdated) {
        if (browserName.safari) {
            return null
        }
        const size = sumBy(items, (item) => {
            return item.isUpdated === isUpdated ? get(item, 'mod.versions[0].versionFileSize', 0) : 0
        })
        const label = isUpdated ?
            SUBSCRIPTIONS_PAGE_MOD_DOWNLOAD_ALL_LBL(formatFileSize(size)) :
            SUBSCRIPTIONS_PAGE_MOD_DOWNLOAD_FAVORITES(formatFileSize(size))
        const action = isUpdated ? this.handlerDownloadUpdatedFiles : this.handlerDownloadSubscriptionFiles
        return (
            <div className={styles.ItemsButton}>
                <ButtonYellowOutlineLarge isWide onClick={action}>
                    {label}
                </ButtonYellowOutlineLarge>
            </div>
        )
    }

    renderItems(items, isUpdated) {
        const title = isUpdated ?
            SUBSCRIPTIONS_PAGE_LAST_UPDATED_TITLE(items.length) :
            SUBSCRIPTIONS_PAGE_FAVORITES_TITLE(items.length)

        return items.length ? (
            <React.Fragment>
                <div className={styles.itemsHeader}>
                    <TitleMinor>{title}</TitleMinor>
                    {this.renderDownloadAllButton(items, isUpdated)}
                </div>
                {items.map((item) => {
                    return (
                        <SubscriptionsItem
                            isNew={isUpdated}
                            isUpdated={isUpdated}
                            mod={item.mod}
                            key={item.id}
                            id={item.id}
                            fetchItems={this.props.fetchItems}
                            onIncrementDownloadCount={this.props.onIncrementDownloadCount}
                            onIgnoreUpdate={this.props.onIgnoreUpdate}
                            onUnsubscribe={this.props.onUnsubscribe}
                        />
                    )
                })}
            </React.Fragment>
        ) : null
    }

    renderBody() {
        if (this.props.isError || this.props.items.length === 0 || !this.props.isLoggedIn) {
            return
        }
        let updated = []
        let other = []
        each(this.props.items, (item) => {
            if (item.isUpdated) {
                updated.push(item)
            } else {
                other.push(item)
            }
        })
        return (
            <div>
                {this.renderItems(updated, true)}
                {other.length && updated.length ? (
                    <div className={styles.divider}><Divider /></div>
                ) : null}
                {this.renderItems(other, false)}
            </div>
        )
    }

    renderTitle() {
        const countLabel = this.props.isError ? '-' : this.props.items.length
        return this.props.isLoggedIn ? SUBSCRIPTIONS_PAGE_TITLE(countLabel) : SUBSCRIPTIONS_PAGE_TITLE_WITHOUT_COUNTER()
    }

    renderInformation() {
        return !this.props.isLoggedIn && !this.props.isError ? (
            <Information
                message={SUBSCRIPTIONS_PAGE_UNAUTHORIZED_MESSAGE}
                buttonCaption={SUBSCRIPTIONS_PAGE_LOGIN}
                onButtonClick={this.props.onShowLoginDialog}
            />
        ) : null
    }

    render() {
        const classNameTitle = classNames(styles.title, {
            [styles.isMuted]: this.props.isError || this.props.items.length === 0,
        })
        return (
            <Main>
                <a
                    download
                    className={styles.hidden}
                />
                <div className={styles.base}>
                    <div className={styles.head}>
                        <Back caption={TO_LANDING} to={urls.landing} />
                        <TitleMajor>
                            <span className={classNameTitle}>{this.renderTitle()}</span>
                        </TitleMajor>
                    </div>

                    <div className={styles.body}>
                        {this.renderInformation()}
                        {this.renderEmptyList()}
                        {this.renderError()}
                        {this.renderBody()}
                    </div>
                </div>
            </Main>
        )
    }
}

export default injectIntl(Subscriptions)
