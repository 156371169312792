import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import styles from './Back.scss'

export default class Back extends React.PureComponent {
    static propTypes = {
        caption: PropTypes.node.isRequired,
        to: PropTypes.string.isRequired,
    }

    render() {
        return (
            <Link to={this.props.to} className={styles.base}>{this.props.caption}</Link>
        )
    }
}
