import React from 'react'
import { FormattedMessage } from 'react-intl'

export const DIALOG_CONFIRMATION_MOD_TITLE = (
    <FormattedMessage
        id="dialog.confirmation.mod.title"
        defaultMessage="Вы действительно хотите удалить мод?"
    />
)

export const DIALOG_CONFIRMATION_DRAFT_TITLE = (
    <FormattedMessage
        id="dialog.confirmation.draft.title"
        defaultMessage="Вы действительно хотите удалить черновик?"
    />
)

export const DIALOG_CONFIRMATION_MOD_CONTENT = (
    <FormattedMessage
        id="dialog.confirmation.mod.content"
        defaultMessage="Обратите внимание, данное действие необратимо и восстановить мод вы не сможете."
    />
)

export const DIALOG_CONFIRMATION_DRAFT_CONTENT = (
    <FormattedMessage
        id="dialog.confirmation.draft.content"
        defaultMessage="Обратите внимание, данное действие необратимо и восстановить черновик вы не сможете."
    />
)

export const DIALOG_CONFIRMATION_MOD_OK_BUTTON = (
    <FormattedMessage
        id="dialog.confirmation.mod.ok.button"
        defaultMessage="Удалить"
    />
)

export const DIALOG_CONFIRMATION_MOD_CANCEL_BUTTON = (
    <FormattedMessage
        id="dialog.confirmation.mod.cancel.button"
        defaultMessage="Отмена"
    />
)
