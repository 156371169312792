import React from 'react'
import { FormattedMessage } from 'react-intl'

import { FileUploadCaption } from 'components'

export const EDIT_SCREENSHOTS_LABEL = (
    <FormattedMessage
        id="edit.screenshots.label"
        defaultMessage="Скриншоты"
    />
)

export const EDIT_SCREENSHOTS_LABEL_TEXT = (
    <FormattedMessage
        id="form.screenshots.label.text"
        defaultMessage="Не более пяти"
    />
)

export const EDIT_SCREENSHOT_FILE_FORMAT = (isError) => (
    <FormattedMessage
        id="edit.screenshot.file.format"
        defaultMessage="Формат файла: {ext0} или {ext1}"
        values={{
            ext0: (<FileUploadCaption isError={isError} caption="PNG" />),
            ext1: (<FileUploadCaption isError={isError} caption="JPG" />),
        }}
    />
)

export const EDIT_SCREENSHOT_FILE_SIZE = (isError) => (
    <FormattedMessage
        id="edit.screenshot.upload.file.size"
        defaultMessage="Размер: {size}"
        values={{
            size: (
                <FileUploadCaption
                    isError={isError}
                    caption={(
                        <FormattedMessage
                            id="edit.cover.upload.file.size.caption"
                            defaultMessage="Не более 4MB для одного файла"
                        />
                    )}
                />
            ),
        }}
    />
)
