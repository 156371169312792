import React from 'react'
import { connect } from 'react-redux'

import { openDialog } from 'actions/Dialog'
import { incrementDownloadCount } from 'actions/Details'
import { fetchSubscriptions, ignoreModUpdate, unsubscribeMod } from 'actions/Subscriptions'
import { DialogLogin } from 'containers'
import Subscriptions from 'components/Subscriptions/Subscriptions'


const mapStateToProps = (state) => ({
    lang: state.currentAccount.lang,
    isLoggedIn: !!state.currentAccount.spaId,
    isFetching: state.subscriptions.isFetching,
    items: state.subscriptions.items,
    isError: state.subscriptions.isError,
})

const mapDispatchToProps = (dispatch) => ({
    fetchItems: () => {
        dispatch(fetchSubscriptions())
    },
    onShowLoginDialog: () => {
        dispatch(openDialog(<DialogLogin />))
    },
    onIncrementDownloadCount: (modId) => {
        return dispatch(incrementDownloadCount(modId))
    },
    onIgnoreUpdate: (id, modId) => {
        Promise.resolve(
            dispatch(ignoreModUpdate(id, modId))
        ).then(() => dispatch(fetchSubscriptions()))
    },
    onUnsubscribe: (modId) => {
        Promise.resolve(
            dispatch(unsubscribeMod(modId))
        ).then(() => dispatch(fetchSubscriptions()))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions)
