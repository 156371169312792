import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { startsWithBaseUrl, stripBaseUrl } from 'utils/routing'

import styles from './FooterLinks.scss'
import { FOOTER_LINKS_COOKIES_SETTINGS } from './translations'

class FooterLinks extends React.PureComponent {
    static propTypes = {
        links: PropTypes.arrayOf(PropTypes.shape({
            href: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            isExternal: PropTypes.bool,
        })).isRequired,
    }

    renderExternalIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" className={styles.externalLinkIcon}>
                <path d="M8 4V1.75L3.762 5.98l-.746-.74L7.254 1H5V0h4v4H8ZM1 8h5V6h1v3H0V2h3v1H1v5Z" />
            </svg>
        )
    }

    renderLinks() {
        return this.props.links.map((link) => {
            const target = link.isExternal ? '_blank' : null

            return (
                startsWithBaseUrl(link.href) ? (
                    <Link
                        key={link.href}
                        to={stripBaseUrl(link.href)}
                        className={styles.link}
                        target={target}
                    >
                        {link.title}
                        {link.isExternal && this.renderExternalIcon()}
                    </Link>
                ) : (
                    <a
                        key={link.href}
                        href={link.href}
                        className={styles.link}
                        target={target}
                    >
                        {link.title}
                        {link.isExternal && this.renderExternalIcon()}
                    </a>
                )
            )
        })
    }

    handleShowCookiesSettings = (e) => {
        e.preventDefault()

        if (!window.OneTrust) {
            // eslint-disable-next-line no-console
            console.error('FooterLinks.js: OneTrust is not defined')
            return
        }

        window.OneTrust.ToggleInfoDisplay()
    }

    render() {
        return (
            <nav className={styles.base}>
                {this.renderLinks()}
                <a
                    href="#"
                    className={classNames('ot-sdk-show-settings', styles.link)}
                    onClick={this.handleShowCookiesSettings}
                >
                    {FOOTER_LINKS_COOKIES_SETTINGS}
                </a>
            </nav>
        )
    }
}

const FooterLinksDefaultExport = injectIntl(FooterLinks)
export default FooterLinksDefaultExport
