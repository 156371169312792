import {
    OPEN_DIALOG_ERROR,
    CLOSE_DIALOG_ERROR,
} from 'actions/DialogError'

const initialState = {
    content: null,
    isOpened: false,
    title: null,
}

export const dialogError = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_DIALOG_ERROR: {
            return {
                ...state,
                content: action.content,
                title: action.title,
                isOpened: true,
            }
        }
        case CLOSE_DIALOG_ERROR: {
            return {
                ...state,
                content: null,
                isOpened: false,
                title: null,
            }
        }
        default:
            return state
    }
}
