import * as commonMenu from 'utils/commonMenu'
import { savePreferredLanguage } from 'utils/i18n'
import urls from 'apiUrls'
import { fetchWithSpinner, fetchWrapper as fetch } from 'utils/fetch'

export const START_FETCHING = 'CURRENT_ACCOUNT_START_FETCHING'
export const STOP_FETCHING = 'CURRENT_ACCOUNT_STOP_FETCHING'
export const SET_LANGUAGE = 'CURRENT_ACCOUNT_SET_LANGUAGE'
export const SET_IS_BANNED = 'SET_IS_BANNED'
export const SET_MOD_DOWNLOAD_ACCEPTED = 'CURRENT_ACCOUNT_SET_MOD_DOWNLOAD_ACCEPTED'
export const SET_OVERALL_LANGUAGE_FILTER = 'CURRENT_ACCOUNT_SET_OVERALL_LANGUAGE_FILTER'
export const SET_RULES_ACCEPTED = 'CURRENT_ACCOUNT_SET_RULES_ACCEPTED'
export const UPDATE_MODS_COUNT = 'UPDATE_MODS_COUNT'
export const SET_IE_ALERT_ACCEPTED = 'SET_IE_ALERT_ACCEPTED'
export const SET_EDGE_ALERT_ACCEPTED = 'SET_EDGE_ALERT_ACCEPTED'

export const startFetching = () => ({
    type: START_FETCHING,
})

export const stopFetching = () => ({
    type: STOP_FETCHING,
})

export const setLanguage = (code) => ({
    type: SET_LANGUAGE,
    code,
})

export const setRulesAccepted = () => ({
    type: SET_RULES_ACCEPTED,
})

export const setModDownloadAccepted = () => ({
    type: SET_MOD_DOWNLOAD_ACCEPTED,
})

export const setOverallLanguageFilter = (language) => ({
    type: SET_OVERALL_LANGUAGE_FILTER,
    payload: {
        language,
    },
})

export const setIsBanned = () => ({
    type: SET_IS_BANNED,
})

export const updateModsCount = (count) => ({
    type: UPDATE_MODS_COUNT,
    count,
})

export const login = (realm) => {
    return (dispatch) => {
        dispatch(startFetching())
        commonMenu.login(realm)
    }
}

export const changeLanguage = (code) => {
    return (dispatch) => {
        dispatch(setLanguage(code))
        savePreferredLanguage(code).then(() => {
            window.location.reload()
        }, () => {
            window.location.reload()
        })
    }
}

export const changeRulesAccepted = () => {
    return (dispatch, getState) => {
        const body = {
            is_rules_accepted: true,
        }
        const state = getState()
        const spaId = state.currentAccount.spaId
        const url = urls.userDetails.replace(/:(spaId)/g, spaId)

        const fetchPromise = fetch(url, { method: 'PUT', body }).promise.then(() => {
            dispatch(setRulesAccepted())
        })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const setIEAlertAccepted = () => ({
    type: SET_IE_ALERT_ACCEPTED,
})

export const setEdgeAlertAccepted = () => ({
    type: SET_EDGE_ALERT_ACCEPTED,
})
