import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

export const SEARCH_RESULT_PAGE_TITLE_BY_TITLE = (searchTerm, count) => (
    <FormattedMessage
        id="search.result.page.title.by.title"
        defaultMessage="По запросу {searchTerm} найдено: {count}"
        values={{
            searchTerm,
            count,
        }}
    />
)

export const SEARCH_RESULT_PAGE_TITLE_BY_OWNER = (ownerName, count) => (
    <FormattedMessage
        id="search.result.page.title.by.owner"
        defaultMessage="Моды от {ownerName}: {count}"
        values={{
            ownerName,
            count,
        }}
    />
)

export const SEARCH_RESULT_EMPTY = (
    <FormattedMessage
        id="search.result.empty"
        defaultMessage="По вашему запросу ничего не найдено. Попробуйте изменить запрос."
    />
)

export const SEARCH_RESULT_EMPTY_BY_OWNER = (
    <FormattedMessage
        id="search.result.empty.by.owner"
        defaultMessage="Моды этого пользователя не найдены."
    />
)

export const messages = defineMessages({
    filterByTitle: {
        id: 'search.result.by.title.title',
        defaultMessage: 'Результаты поиска по {title} — Моды для World of Tanks',
    },
    filterByOwner: {
        id: 'search.result.by.owner.title',
        defaultMessage: 'Скачать моды от {ownerName} для World of Tanks — Моды для World of Tanks',
    },
})
