import { connect } from 'react-redux'

import { login } from 'actions/CurrentAccount'
import { closeDialog } from 'actions/Dialog'

import DialogLogin from 'components/Dialogs/DialogLogin/DialogLogin'

const mapDispatchToProps = {
    onLoginClick: login,
    onCloseDialog: closeDialog,
}

export default connect(null, mapDispatchToProps)(DialogLogin)
