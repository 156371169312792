import { LOCALE_LOADED_OK } from 'actions/Locale'

const initialState = {
    messages: {},
    language: '',
}

export const locale = (state = initialState, action = {}) => {
    switch (action.type) {
        case LOCALE_LOADED_OK:
            return {
                ...state,
                messages: action.messages,
                language: action.language,
            }
        default:
            return state
    }
}
