import React from 'react'
import { FormattedMessage } from 'react-intl'

import { formatWithSeparatedThousands } from 'utils/formatting'

export const EDIT_LANGUAGE_FORM_LABEL = (lang) => (
    <FormattedMessage
        id="edit.language.form.label"
        defaultMessage="Язык описания: {lang}"
        values={{ lang }}
    />
)

export const EDIT_LANGUAGE_FORM_RU = (
    <FormattedMessage
        id="edit.language.form.ru"
        defaultMessage="Русский"
    />
)

export const EDIT_LANGUAGE_FORM_EN = (
    <FormattedMessage
        id="edit.language.form.en"
        defaultMessage="Английский"
    />
)

export const EDIT_LANGUAGE_FORM_CANCEL_CAPTION = (
    <FormattedMessage
        id="edit.language.form.cancel.caption"
        defaultMessage="Удалить описание"
    />
)

export const EDIT_LANGUAGE_FORM_NAME_LABEL = (
    <FormattedMessage
        id="edit.language.form.name.label"
        defaultMessage="Введите название мода"
    />
)

export const EDIT_LANGUAGE_FORM_DESCRIPTION_LABEL = (
    <FormattedMessage
        id="edit.language.form.description.label"
        defaultMessage="Введите описание"
    />
)

export const EDIT_LANGUAGE_FORM_DESCRIPTION_TEXT = (maxChars) => (
    <FormattedMessage
        id="edit.language.form.description.text"
        defaultMessage="Не более {maxChars} символов"
        values={{
            maxChars: formatWithSeparatedThousands(maxChars),
        }}
    />
)

export const EDIT_LANGUAGE_FORM_INSTALLATION_GUIDE_LABEL = (
    <FormattedMessage
        id="edit.language.form.installation.guide.label"
        defaultMessage="Инструкция  по установке"
    />
)

export const EDIT_LANGUAGE_FORM_INSTALLATION_GUIDE_TEXT = (maxChars) => (
    <FormattedMessage
        id="edit.language.form.installation.guide.text"
        defaultMessage="Не более {maxChars} символов"
        values={{
            maxChars: formatWithSeparatedThousands(maxChars),
        }}
    />
)
