import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'

import { AVAILABLE_LANGUAGES } from 'utils/i18n'
import { messages } from 'components/HyperCommentsWidget/translations'

class HyperCommentsWidget extends React.Component {
    static propTypes = {
        authString: PropTypes.string,
        lang: PropTypes.oneOf(AVAILABLE_LANGUAGES).isRequired,
        modId: PropTypes.number.isRequired,
        readonly: PropTypes.bool.isRequired,
        intl: intlShape,
    }

    componentDidMount() {
        this.destroy = HyperCommentsWidgetDefaultExport.manager.create({
            authString: this.props.authString,
            el: this.el,
            lang: this.props.lang,
            modId: this.props.modId,
            readonly: this.props.readonly,
            texts: this.getTexts(),
        })
    }

    componentWillReceiveProps(nextProps) {
        this.destroy = HyperCommentsWidgetDefaultExport.manager.create({
            authString: this.props.authString,
            el: this.el,
            lang: nextProps.lang,
            modId: this.props.modId,
            readonly: this.props.readonly,
            texts: this.getTexts(),
        })
    }

    shouldComponentUpdate() {
        return false
    }

    componentWillUnmount() {
        this.destroy()
    }

    refCallback = (el) => {
        this.el = el
    }

    getTexts = () => ({
        'Close discussion': this.props.intl.formatMessage(messages.close_discussion),
    })

    render() {
        return (
            <div
                ref={this.refCallback}
            />
        )
    }
}

const HyperCommentsWidgetDefaultExport = injectIntl(HyperCommentsWidget)
export default HyperCommentsWidgetDefaultExport
