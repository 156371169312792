import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { injectIntl, intlShape } from 'react-intl'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { concat, first, values } from 'lodash'

import { GALLERY_ITEMS_FILTER_TYPES, GALLERY_ITEMS_FILTERED_ORDERING, GALLERY_ITEMS_TYPES } from 'utils/constants'
import { pushHistoryWithLanding } from 'utils/routing'

import { FilterGameVersion } from 'containers'

import {
    ButtonGrayOutlineLarge,
    Cancel,
    Error,
    Mod,
    Notification,
    TitleMinor,
    Toggle,
} from 'components'

import {
    GALLERY_FILTERED_BY_TAGS_CAPTION,
    GALLERY_FILTERED_EMPTY,
    GALLERY_FILTERED_RECOMMENDED_EMPTY,
    GALLERY_LOAD_FILTERED_MODS,
    GALLERY_LOAD_NEW_MODS,
    GALLERY_LOAD_POPULAR_MODS,
    GALLERY_LOAD_UPDATED_MODS,
    GALLERY_NEW_MODS,
    GALLERY_POPULAR_MODS,
    GALLERY_UPDATED_MODS,
    messages,
} from './translations'

import {
    COMMON_ERROR,
    ORDERING_BY_CHANGED_AT_FILTERED_MODS,
    ORDERING_BY_RATING_FILTERED_MODS,
    RESET_CAPTION,
} from 'translations'

import styles from './Gallery.scss'

const GalleryItemPropTypes = PropTypes.shape({
    authorName: PropTypes.string,
    cover: PropTypes.string,
    gameVersion: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    mark: PropTypes.number.isRequired,
    modVersion: PropTypes.string.isRequired,
    ownerId: PropTypes.number.isRequired,
    ownerName: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        caption: PropTypes.string.isRequired,
    })).isRequired,
    title: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(PropTypes.shape({
        downloadUrl: PropTypes.string.isRequired,
        gameVersion: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        modVersion: PropTypes.string.isRequired,
        versionFileOriginalName: PropTypes.string.isRequired,
        versionFileSize: PropTypes.number,
    })).isRequired,
})

const GallerySectionPropTypes = PropTypes.shape({
    isError: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isFetchedAll: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(GalleryItemPropTypes).isRequired,
})

const GalleryFilteredSectionPropTypes = PropTypes.shape({
    filterParams: PropTypes.shape({
        type: PropTypes.oneOf(values(GALLERY_ITEMS_FILTER_TYPES)),
        argument: PropTypes.oneOfType([
            PropTypes.shape({
                ownerId: PropTypes.number.isRequired,
                ownerName: PropTypes.string.isRequired,
            }),
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.number),
        ]),
        ordering: PropTypes.oneOf([
            GALLERY_ITEMS_FILTERED_ORDERING.CHANGED_AT,
            GALLERY_ITEMS_FILTERED_ORDERING.RATING,
        ]),
    }).isRequired,
    isError: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isFetchedAll: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(GalleryItemPropTypes).isRequired,
})

class Gallery extends React.Component {
    static propTypes = {
        [GALLERY_ITEMS_TYPES.RECOMMENDED]: GallerySectionPropTypes.isRequired,
        [GALLERY_ITEMS_TYPES.NEW]: GallerySectionPropTypes.isRequired,
        [GALLERY_ITEMS_TYPES.UPDATED]: GallerySectionPropTypes.isRequired,
        filtered: GalleryFilteredSectionPropTypes.isRequired,
        isModDownloadAccepted: PropTypes.bool,
        tags: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            caption: PropTypes.string.isRequired,
        }).isRequired).isRequired,

        location: ReactRouterPropTypes.location.isRequired,
        history: ReactRouterPropTypes.history.isRequired,

        intl: intlShape,

        changeGalleryFilteredItemsOrdering: PropTypes.func.isRequired,
        fetchAllItems: PropTypes.func.isRequired,
        fetchGalleryItemsMore: PropTypes.func.isRequired,
        filterGalleryMore: PropTypes.func.isRequired,
        onDownloadClick: PropTypes.func.isRequired,
    }

    componentWillMount() {
        this.props.fetchAllItems(this.props.location)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            this.props.fetchAllItems(nextProps.location)
        }

        this.setDocumentTitle(nextProps)
    }

    setDocumentTitle(props) {
        let formattedMessage

        if (props.filtered.filterParams.type === GALLERY_ITEMS_FILTER_TYPES.TAGS) {
            const tagId = props.filtered.filterParams.argument[0]
            const tagItem = props.tags.find(item => item.id === tagId)
            const tagTitle = tagItem ? tagItem.caption : ''
            formattedMessage = this.props.intl.formatMessage(messages.filterByTagTitle, { tagTitle })
        } else {
            formattedMessage = this.props.intl.formatMessage(messages.landingTitle)
        }

        document.title = formattedMessage
    }

    isErrorState = () => {
        return this.props[GALLERY_ITEMS_TYPES.RECOMMENDED].isError ||
            this.props[GALLERY_ITEMS_TYPES.NEW].isError ||
            this.props[GALLERY_ITEMS_TYPES.UPDATED].isError
    }

    handleLoadMoreRecommendedClick = () => {
        this.props.fetchGalleryItemsMore(GALLERY_ITEMS_TYPES.RECOMMENDED)
    }

    handleLoadMoreNewClick = () => {
        this.props.fetchGalleryItemsMore(GALLERY_ITEMS_TYPES.NEW)
    }

    handleLoadMoreUpdatedClick = () => {
        this.props.fetchGalleryItemsMore(GALLERY_ITEMS_TYPES.UPDATED)
    }

    handleLoadMoreFilteredClick = () => {
        this.props.filterGalleryMore(this.props.filtered.filterParams)
    }

    handleFilteredOrderingChanged = (ordering) => {
        const filterParams = {
            ...this.props.filtered.filterParams,
            ordering,
        }
        this.props.changeGalleryFilteredItemsOrdering(filterParams)
    }

    handleFilterResetClick = () => {
        pushHistoryWithLanding(this.props.history)
    }

    handleDownloadClick = (modId, isModDownloaded) => {
        const { title, versions } = concat(
            this.props[GALLERY_ITEMS_TYPES.RECOMMENDED].items,
            this.props[GALLERY_ITEMS_TYPES.NEW].items,
            this.props[GALLERY_ITEMS_TYPES.UPDATED].items,
            this.props.filtered.items,
        ).find((item) => (item.id === modId))
        this.props.onDownloadClick(this.props.isModDownloadAccepted, isModDownloaded, modId, title, versions)
    }

    renderItem(item) {
        const lastVersion = first(item.versions)
        return (
            <div className={styles.mod} key={`gallery-item-id-${item.id}`}>
                <Mod
                    authorName={item.authorName}
                    cover={item.cover}
                    gameVersion={item.gameVersion}
                    id={item.id}
                    mark={item.mark}
                    modSize={lastVersion.versionFileSize}
                    modVersion={item.modVersion}
                    modVersionFile={lastVersion.downloadUrl}
                    modVersionFileOriginalName={lastVersion.versionFileOriginalName}
                    ownerId={item.ownerId}
                    ownerName={item.ownerName}
                    tags={item.tags}
                    title={item.title}

                    isModDownloadAccepted={this.props.isModDownloadAccepted}
                    isSingleVersionAvailable={item.versions.length === 1}

                    onDownloadClick={this.handleDownloadClick}
                />
            </div>
        )
    }

    renderError() {
        return this.isErrorState() ? (
            <Error>{COMMON_ERROR}</Error>
        ) : null
    }

    renderFilteredLoadMoreButton() {
        if (this.props.filtered.isFetchedAll || this.props.filtered.isFetching) {
            return null
        }

        return (
            <div className={styles.button}>
                <ButtonGrayOutlineLarge onClick={this.handleLoadMoreFilteredClick}>
                    {GALLERY_LOAD_FILTERED_MODS}
                </ButtonGrayOutlineLarge>
            </div>
        )
    }

    renderFilteredResults() {
        if (this.props.filtered.items.length === 0 && !this.props.filtered.isFetching) {
            return (
                <Error>{GALLERY_FILTERED_EMPTY}</Error>
            )
        }

        if (this.props.filtered.isError) {
            return (
                <Error>{COMMON_ERROR}</Error>
            )
        }

        return (
            <div className={styles.mods}>
                {this.props.filtered.items.map((item) => this.renderItem(item))}
            </div>
        )
    }

    renderFilteredSection() {
        if (!this.props.filtered.filterParams.type) {
            return null
        }

        return (
            <React.Fragment>
                <section className={classNames(styles.section, styles['section__filtered'])}>
                    <div className={styles.filteredHead}>
                        <div className={styles.title}>
                            <TitleMinor>
                                {GALLERY_FILTERED_BY_TAGS_CAPTION(this.props.filtered.filterParams.argument.length)}
                                <div className={styles.reset}>
                                    <Cancel
                                        caption={RESET_CAPTION}
                                        align="right"
                                        onClick={this.handleFilterResetClick}
                                    />
                                </div>
                            </TitleMinor>
                        </div>
                        <div className={styles.filter}>
                            <div className={styles.toggle}>
                                <Toggle
                                    items={[
                                        {
                                            value: GALLERY_ITEMS_FILTERED_ORDERING.RATING,
                                            caption: ORDERING_BY_RATING_FILTERED_MODS,
                                        },
                                        {
                                            value: GALLERY_ITEMS_FILTERED_ORDERING.CHANGED_AT,
                                            caption: ORDERING_BY_CHANGED_AT_FILTERED_MODS,
                                        },
                                    ]}
                                    isDisabled={this.props.filtered.items.length === 0 || this.props.filtered.isError}
                                    checkedItem={this.props.filtered.filterParams.ordering}
                                    onItemChange={this.handleFilteredOrderingChanged}
                                />
                            </div>
                            <FilterGameVersion />
                        </div>
                    </div>
                    <div className={styles.filteredBody}>
                        <div className={styles.inner}>
                            {this.renderFilteredResults()}
                            {this.renderFilteredLoadMoreButton()}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }

    renderLangingLoadMoreButton(itemsType) {
        if (this.props[itemsType].isFetchedAll) {
            return null
        }

        switch (itemsType) {
            case GALLERY_ITEMS_TYPES.RECOMMENDED: {
                return (
                    <div className={styles.button}>
                        <ButtonGrayOutlineLarge
                            onClick={this.handleLoadMoreRecommendedClick}
                            gtmTag={'ev_click-load-more'}
                        >
                            {GALLERY_LOAD_POPULAR_MODS}
                        </ButtonGrayOutlineLarge>
                    </div>
                )
            }
            case GALLERY_ITEMS_TYPES.NEW: {
                return (
                    <div className={styles.button}>
                        <ButtonGrayOutlineLarge
                            onClick={this.handleLoadMoreNewClick}
                            gtmTag={'ev_click-load-more'}
                        >
                            {GALLERY_LOAD_NEW_MODS}
                        </ButtonGrayOutlineLarge>
                    </div>
                )
            }
            case GALLERY_ITEMS_TYPES.UPDATED: {
                return (
                    <div className={styles.button}>
                        <ButtonGrayOutlineLarge
                            onClick={this.handleLoadMoreUpdatedClick}
                            gtmTag={'ev_click-load-more'}
                        >
                            {GALLERY_LOAD_UPDATED_MODS}
                        </ButtonGrayOutlineLarge>
                    </div>
                )
            }
        }
    }

    // eslint-disable-next-line complexity
    renderLandingSections() {
        if (this.props.filtered.filterParams.type || this.isErrorState()) {
            return null
        }
        const isSomethingFetching = this.props[GALLERY_ITEMS_TYPES.RECOMMENDED].isFetching ||
            this.props[GALLERY_ITEMS_TYPES.NEW].isFetching || this.props[GALLERY_ITEMS_TYPES.UPDATED].isFetching ||
            this.props.filtered.isFetching

        const isAllEmpty = !this.props[GALLERY_ITEMS_TYPES.RECOMMENDED].items.length &&
            !this.props[GALLERY_ITEMS_TYPES.NEW].items.length &&
            !this.props[GALLERY_ITEMS_TYPES.UPDATED].items.length

        if (isSomethingFetching && isAllEmpty) {
            return null
        }

        if (!isSomethingFetching && isAllEmpty) {
            return (
                <Error>{GALLERY_FILTERED_EMPTY}</Error>
            )
        }

        return (
            <React.Fragment>
                <section key={`section-${GALLERY_ITEMS_TYPES.RECOMMENDED}`} className={styles.section}>
                    <div className={styles.filteredHead}>
                        <div className={styles.title}>
                            <TitleMinor>{GALLERY_POPULAR_MODS}</TitleMinor>
                        </div>
                        <div className={styles.filter}>
                            <FilterGameVersion isDisabled={this.props.filtered.isError} />
                        </div>
                    </div>
                    {this.props[GALLERY_ITEMS_TYPES.RECOMMENDED].items.length ? (
                        <div className={styles.filteredBody}>
                            <div className={styles.inner}>
                                <div className={styles.mods}>
                                    {this.props[GALLERY_ITEMS_TYPES.RECOMMENDED].items.map((item) => this.renderItem(item))}
                                </div>
                                {this.renderLangingLoadMoreButton(GALLERY_ITEMS_TYPES.RECOMMENDED)}
                            </div>
                        </div>
                    ) : (
                        <Notification type="info">
                            <p>{GALLERY_FILTERED_RECOMMENDED_EMPTY}</p>
                        </Notification>
                    )}
                </section>
                <section key={`section-${GALLERY_ITEMS_TYPES.NEW}`} className={styles.section}>
                    <div className={styles.title}>
                        <TitleMinor>{GALLERY_NEW_MODS}</TitleMinor>
                    </div>
                    <div className={styles.inner}>
                        <div className={styles.mods}>
                            {this.props[GALLERY_ITEMS_TYPES.NEW].items.map((item) => this.renderItem(item))}
                        </div>
                        {this.renderLangingLoadMoreButton(GALLERY_ITEMS_TYPES.NEW)}
                    </div>
                </section>
                <section key={`section-${GALLERY_ITEMS_TYPES.UPDATED}`} className={styles.section}>
                    <div className={styles.title}>
                        <TitleMinor>{GALLERY_UPDATED_MODS}</TitleMinor>
                    </div>
                    <div className={styles.inner}>
                        <div className={styles.mods}>
                            {this.props[GALLERY_ITEMS_TYPES.UPDATED].items.map((item) => this.renderItem(item))}
                        </div>
                        {this.renderLangingLoadMoreButton(GALLERY_ITEMS_TYPES.UPDATED)}
                    </div>
                </section>
            </React.Fragment>
        )
    }

    render() {
        return (
            <div className={styles.base}>
                {this.renderError()}
                {this.renderLandingSections()}
                {this.renderFilteredSection()}
            </div>
        )
    }
}

export default injectIntl(Gallery)
