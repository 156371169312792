import React from 'react'
import { FormattedMessage } from 'react-intl'

export const ACTION_REMOVE_MOD = (
    <FormattedMessage
        id="action.remove.mod"
        defaultMessage="Удалить мод"
    />
)

export const ACTION_REMOVE_DRAFT = (
    <FormattedMessage
        id="action.remove.draft"
        defaultMessage="Удалить"
    />
)
