import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { Tag } from 'components'

import { toggleList } from 'utils/filtration'
import { pushHistoryWithLanding, pushHistoryWithTags } from 'utils/routing'

import {
    CATEGORIES_CAPTION,
} from './translations'

import {
    ALL_CAPTION,
} from 'translations'

import styles from './Categories.scss'

export default class Categories extends React.PureComponent {
    static propTypes = {
        tags: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            caption: PropTypes.string.isRequired,
        }).isRequired).isRequired,
        history: ReactRouterPropTypes.history.isRequired,
        checkedTags: PropTypes.array,
    }

    handleTagChange = (checkedTag) => {
        const checkedTags = toggleList(this.props.checkedTags, checkedTag)

        if (checkedTags.length === 0 || checkedTags.length === this.props.tags.length) {
            pushHistoryWithLanding(this.props.history)
        } else {
            pushHistoryWithTags(this.props.history, checkedTags)
        }
    }

    handleResetClick = () => {
        pushHistoryWithLanding(this.props.history)
    }

    renderTags() {
        return this.props.tags.map((tag, index) => {
            const isChecked = this.props.checkedTags.includes(tag.id)

            return (
                <div className={styles.tag} key={`mod-tag-${tag.id}-${index}`}>
                    <Tag
                        id={tag.id}
                        caption={tag.caption}
                        isChecked={isChecked}
                        onClick={this.handleTagChange}
                    />
                </div>
            )
        })
    }

    render() {
        return (
            <div className={styles.base}>
                <span className={styles.caption}>{CATEGORIES_CAPTION}</span>
                <div className={styles.tag}>
                    <Tag
                        id="all"
                        caption={ALL_CAPTION}
                        isChecked={this.props.checkedTags.length === 0}
                        onClick={this.handleResetClick}
                    />
                </div>
                {this.renderTags()}
            </div>
        )
    }
}
