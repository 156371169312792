import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Image from 'react-image-resizer'
import Lightbox from 'react-image-lightbox'

import styles from './Screenshots.scss'

export default class Screenshots extends React.PureComponent {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({
            height: PropTypes.number,
            id: PropTypes.number.isRequired,
            position: PropTypes.number.isRequired,
            source: PropTypes.string.isRequired,
            sourceFullSize: PropTypes.string.isRequired,
            sourceViewPage: PropTypes.string.isRequired,
            width: PropTypes.number,
        })),
    }

    constructor(props) {
        super(props)

        this.state = {
            photoIndex: 0,
            isVisible: false,
        }
    }

    handleScreenshotClick = (photoIndex) => {
        photoIndex !== this.state.photoIndex && this.setState({ photoIndex })
    }

    handleFullScreenshotClick = () => {
        this.setState({ isVisible: true })
    }

    handleCloseRequest = () => {
        this.setState({ isVisible: false })
    }

    handleMovePrevRequest = () => {
        const images = this.props.items
        const photoIndex = this.state.photoIndex

        this.setState({
            photoIndex: (photoIndex + images.length - 1) % images.length,
        })
    }

    handleMoveNextRequest = () => {
        const images = this.props.items
        const photoIndex = this.state.photoIndex

        this.setState({
            photoIndex: (photoIndex + 1) % images.length,
        })
    }

    renderPreviews() {
        const itemsLength = this.props.items.length

        return itemsLength > 1 && (
            <div className={styles.previews}>
                <div className={styles.counter}>
                    <span>{this.state.photoIndex + 1}</span> / {itemsLength}
                </div>
                {this.renderPreview()}
            </div>
        )
    }

    renderPreview() {
        return (
            this.props.items.map((item, idx) => {
                const classNamePreview = classNames(styles.preview, {
                    [styles.isActive]: idx === this.state.photoIndex,
                    'ev_click-screenshot': true,
                })

                return (
                    <div
                        key={`items-${idx}`}
                        className={classNamePreview}
                        onClick={() => this.handleScreenshotClick(idx)}
                    >
                        <Image
                            src={this.props.items[idx].sourceFullSize}
                            width={160}
                            height={85}
                        />
                    </div>
                )
            })
        )
    }

    renderView() {
        if (this.props.items.length === 1) {
            return (
                <img
                    className={styles.image}
                    src={this.props.items[this.state.photoIndex].sourceFullSize}
                />
            )
        }

        return (
            <div className={styles.view} onClick={this.handleFullScreenshotClick}>
                <Image
                    src={this.props.items[this.state.photoIndex].sourceFullSize}
                    width={930}
                    height={525}
                />
            </div>
        )
    }

    render() {
        const images = this.props.items
        const photoIndex = this.state.photoIndex

        return (
            <div className={styles.base}>
                {this.renderView()}
                {this.renderPreviews()}
                {this.state.isVisible && (
                    <React.Fragment>
                        <Lightbox
                            enableZoom={false}
                            imageTitle={(
                                <div className={styles.lightboxCounter}>
                                    <span>{this.state.photoIndex + 1}</span> / {images.length}
                                </div>
                            )}
                            imagePadding={170}

                            mainSrc={images[photoIndex].sourceFullSize}
                            nextSrc={images[(photoIndex + 1) % images.length].sourceFullSize}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length].sourceFullSize}
                            onCloseRequest={this.handleCloseRequest}
                            onMovePrevRequest={this.handleMovePrevRequest}
                            onMoveNextRequest={this.handleMoveNextRequest}
                        />
                    </React.Fragment>
                )}
            </div>
        )
    }
}
