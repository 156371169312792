import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { TitleDialog } from 'components'

import {
    DIALOG_LOGIN_TITLE,
    DIALOG_LOGIN_CAPTION,
} from './translations'

import styles from './DialogLogin.scss'

export default class DialogLogin extends React.PureComponent {
    static propTypes = {
        onLoginClick: PropTypes.func.isRequired,
        onCloseDialog: PropTypes.func.isRequired,
    }

    realms = [
        'eu',
        'us',
        'sg',
    ]

    handleLinkClick(realm) {
        this.props.onCloseDialog()
        this.props.onLoginClick(realm)
    }

    renderRealm() {
        return this.realms.map((realm) => (
            <div
                key={realm}
                className={classNames(styles.realm, styles[`realm__${realm}`])}
                onClick={() => this.handleLinkClick(realm)}
            >
                {DIALOG_LOGIN_CAPTION[realm]}
            </div>
        ))
    }

    render() {
        return (
            <div className={styles.base}>
                <TitleDialog>{DIALOG_LOGIN_TITLE}</TitleDialog>
                <div className={styles.realms}>
                    {this.renderRealm()}
                </div>
            </div>
        )
    }
}
