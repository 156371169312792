import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Tooltip.scss'

const OFFSET = 15
const TOOLTIP_SHOW_DELAY = 10

class TooltipManager {
    constructor() {
        this.tooltips = new Map()
        this.activeTooltip = null
    }

    addTooltip(id, tooltip) {
        if (this.tooltips.has(id))
            throw `duplicate tooltip id - ${id}`
        if (this.tooltips.size === 0)
            document.addEventListener('mouseover', this.handleMouseOver)
        this.tooltips.set(id, tooltip)
    }

    removeTooltip(id) {
        const tooltip = this.tooltips.get(id)
        if (!tooltip)
            throw `try delete tooltip id "${id}" that already deleted`
        if (tooltip === this.activeTooltip)
            this.destroyActiveTooltip()
        this.tooltips.delete(id)
        if (this.tooltips.size === 0)
            document.removeEventListener('mouseover', this.handleMouseOver)
    }

    handleMouseOver = (e) => {
        let target = e.target
        while (!target.dataset.for) {
            target = target.parentNode
            if (target === e.currentTarget)
                return
        }
        if (target.dataset.for) {
            const id = target.dataset.for
            const tooltip = this.tooltips.get(id)
            if (tooltip && this.activeTooltip !== tooltip) {
                this.activateTooltip(tooltip, target)
            }
        }
    }

    activateTooltip(tooltip, target) {
        if (this.activeTooltip)
            this.destroyActiveTooltip()
        this.activeTooltip = tooltip
        this.activeTooltip.target = target
        this.activeTooltip.activate()
        target.addEventListener('mousemove', this.handleMouseMove, true)
        target.addEventListener('mouseleave', this.handleMouseLeave)
        target.addEventListener('mousedown', this.handleMouseDown)
    }

    handleMouseMove = (e) => {
        this.activeTooltip.move(e)
    }

    handleMouseLeave = () => {
        this.destroyActiveTooltip()
    }

    handleMouseDown = () => {
        this.destroyActiveTooltip()
    }

    destroyActiveTooltip() {
        this.activeTooltip.hide()
        this.activeTooltip.target.removeEventListener('mousemove', this.handleMouseMove, true)
        this.activeTooltip.target.removeEventListener('mouseleave', this.handleMouseLeave)
        this.activeTooltip.target.removeEventListener('mousedown', this.handleMouseDown)
        this.activeTooltip.target = null
        this.activeTooltip = null
    }
}

export default class Tooltip extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        id: PropTypes.string.isRequired,
        bounds: PropTypes.shape({
            top: PropTypes.number,
            right: PropTypes.number,
            bottom: PropTypes.number,
            left: PropTypes.number,
        }),
        isWide: PropTypes.bool,
        getContent: PropTypes.func,
    }

    static defaultProps = {
        getContent: () => {},
    }

    static manager = new TooltipManager()

    constructor() {
        super()

        this.state = {
            initialized: false,
            visible: false,
            top: 0,
            left: 0,
        }
        this.target = null
    }

    componentDidMount() {
        Tooltip.manager.addTooltip(this.props.id, this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id) {
            Tooltip.manager.addTooltip(nextProps.id, this)
            Tooltip.manager.removeTooltip(this.props.id)
        }
    }

    componentWillUnmount() {
        Tooltip.manager.removeTooltip(this.props.id)
    }

    activate() {
        if (!this.state.initialized) {
            this.setState({ initialized: true }, () => {
                this.timeoutId = setTimeout(this.show, TOOLTIP_SHOW_DELAY)
            })
        } else {
            this.timeoutId = setTimeout(this.show, TOOLTIP_SHOW_DELAY)
        }
    }

    move({ clientX, clientY }) {
        this.setState({ clientX, clientY })
    }

    show = () => {
        const position = this.getPosition()
        this.setState({
            ...position,
            visible: true,
        })
    }

    hide() {
        this.clearTimeouts()
        this.setState({ visible: false })
    }

    clearTimeouts() {
        this.timeoutId && clearTimeout(this.timeoutId)
    }

    getPosition() {
        const { clientX, clientY } = this.state

        const tooltipHeight = this._tooltip.offsetHeight
        const tooltipWidth = this._tooltip.offsetWidth

        const { clientHeight, clientWidth } = document.documentElement

        const bounds = {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            ...this.props.bounds,
        }

        const topLimit = bounds.top
        const rightLimit = clientWidth - bounds.right
        const bottomLimit = clientHeight - bounds.bottom
        const leftLimit = bounds.left

        let tooltipTop
        let tooltipLeft

        const fitsTop = (tooltipTop) => tooltipTop >= topLimit
        const fitsRight = (tooltipLeft) => tooltipLeft + tooltipWidth <= rightLimit
        const fitsBottom = (tooltipTop) => tooltipTop + tooltipHeight <= bottomLimit
        const fitsLeft = (tooltipLeft) => tooltipLeft >= leftLimit

        tooltipTop = clientY + OFFSET
        tooltipLeft = clientX + OFFSET

        if (!fitsRight(tooltipLeft)) {
            tooltipLeft = clientX - tooltipWidth - OFFSET
        }

        if (!fitsLeft(tooltipLeft)) {
            tooltipLeft = leftLimit
        }

        if (!fitsTop(tooltipTop)) {
            tooltipTop = clientY + OFFSET

            if (!fitsRight(tooltipLeft)) {
                tooltipTop = clientY - OFFSET
            }
        }

        if (!fitsBottom(tooltipTop)) {
            tooltipTop = bottomLimit - tooltipHeight - OFFSET
        }

        return { top: tooltipTop, left: tooltipLeft }
    }

    render() {
        if (!this.state.initialized) return null

        const classNameBase = classNames(styles.base, {
            [styles.isVisible]: this.state.visible,
            [styles.isWide]: this.props.isWide,
        })

        const inlineStyles = {
            top: this.state.visible ? this.state.top : 0,
            left: this.state.visible ? this.state.left : 0,
        }

        const content = this.props.children ? this.props.children : (
            this.target && this.props.getContent(this.target.dataset.tip)
        )

        return (
            <div
                className={classNameBase}
                style={inlineStyles}
                ref={(c) => this._tooltip = c}
            >
                {content}
            </div>
        )
    }
}
