import { connect } from 'react-redux'

import * as currentAccountActions from 'actions/CurrentAccount'
import * as galleryActions from 'actions/Gallery'
import * as searchResultActions from 'actions/SearchResult'

import FilterLanguage from 'components/UI/FilterLanguage/FilterLanguage'

const mapStateToProps = (state, ownProps) => ({
    isUsedForSearch: !!ownProps.isUsedForSearch,
    selectedLanguage: ownProps.isUsedForSearch ?
        state.searchResult.overallFilters.language :
        state.persistedValues.overallLanguageFilter || state.gallery.overallFilters.language,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    onChange: (language) => {
        dispatch(currentAccountActions.setOverallLanguageFilter(language))

        const action = ownProps.isUsedForSearch ?
            searchResultActions.changeLanguageFilter :
            galleryActions.changeOverallLanguageFilter
        dispatch(action(language))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterLanguage)
