import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

import { ButtonYellowLarge } from 'components'

import { pushHistoryWithLanding } from 'utils/routing'

import {
    ERROR_404_TITLE,
    ERROR_404_CONTENT,
} from './translations'

import { GO_TO_LANDING } from 'translations'

import styles from './Error404.scss'

export default class Error404 extends React.PureComponent {
    static propTypes = {
        history: ReactRouterPropTypes.history.isRequired,
    }

    handleClick = () => {
        pushHistoryWithLanding(this.props.history)
    }

    render() {
        return (
            <div className={styles.base}>
                <div className={styles.inner}>
                    <div className={styles.error} />
                    <div className={styles.title}>{ERROR_404_TITLE}</div>
                    <div className={styles.content}>{ERROR_404_CONTENT}</div>
                    <ButtonYellowLarge onClick={this.handleClick}>{GO_TO_LANDING}</ButtonYellowLarge>
                </div>
            </div>
        )
    }
}
