import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { closeDialog } from 'actions/Dialog'
import { changeRulesAccepted } from 'actions/CurrentAccount'

import { pushHistoryWithLanding } from 'utils/routing'

import DialogConfirmation from 'components/Dialogs/DialogConfirmation/DialogConfirmation'

const mapStateToProps = (state) => ({
    language: state.currentAccount.lang,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    onCloseDialog: () => {
        pushHistoryWithLanding(ownProps.history)
        dispatch(closeDialog())
    },
    onSubmitDialog: () => {
        dispatch(changeRulesAccepted())
        dispatch(closeDialog())
    },
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DialogConfirmation)
)
