import { connect } from 'react-redux'

import { closeDialogError } from 'actions/DialogError'

import DialogError from 'components/Dialogs/DialogError/DialogError'

const mapStateToProps = (state) => ({
    content: state.dialogError.content,
    isOpened: state.dialogError.isOpened,
    title: state.dialogError.title,
})


const mapDispatchToProps = (dispatch) => ({
    onCloseDialog: () => {
        dispatch(closeDialogError())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(DialogError)
