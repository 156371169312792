import React from 'react'
import PropTypes from 'prop-types'

import { getTermsOfUse } from 'utils/routing'

import {
    ButtonYellowLarge,
    ButtonYellowOutlineLarge,
    Checkbox,
    TitleDialog,
} from 'components'

import {
    DIALOG_CONFIRMATION_TITLE,
    DIALOG_CONFIRMATION_CONTENT,
    DIALOG_CONFIRMATION_CHECKBOX_LABEL,
    DIALOG_CONFIRMATION_OK_BUTTON,
    DIALOG_CONFIRMATION_CANCEL_BUTTON,
} from './translations'

import styles from './DialogConfirmation.scss'

export default class DialogConfirmation extends React.PureComponent {
    static propTypes = {
        language: PropTypes.string.isRequired,
        onCloseDialog: PropTypes.func.isRequired,
        onSubmitDialog: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            isChecked: false,
        }
    }

    handleCheckboxChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        })
    }

    render() {
        return (
            <div className={styles.base}>
                <TitleDialog>{DIALOG_CONFIRMATION_TITLE}</TitleDialog>
                <div className={styles.content}>
                    <p>{DIALOG_CONFIRMATION_CONTENT(getTermsOfUse(this.props.language))}</p>
                </div>
                <div className={styles.checkbox}>
                    <Checkbox
                        caption={DIALOG_CONFIRMATION_CHECKBOX_LABEL}
                        isChecked={this.state.isChecked}
                        onChange={this.handleCheckboxChange}
                    />
                </div>
                <div>
                    <div className={styles.button}>
                        <ButtonYellowLarge isDisabled={!this.state.isChecked} onClick={this.props.onSubmitDialog}>
                            {DIALOG_CONFIRMATION_OK_BUTTON}
                        </ButtonYellowLarge>
                    </div>
                    <div className={styles.button}>
                        <ButtonYellowOutlineLarge onClick={this.props.onCloseDialog}>
                            {DIALOG_CONFIRMATION_CANCEL_BUTTON}
                        </ButtonYellowOutlineLarge>
                    </div>
                </div>
            </div>
        )
    }
}
