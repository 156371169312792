import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './ButtonYellowLarge.scss'
import { isUndefined } from 'lodash'

export default class ButtonYellowLarge extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        gtmTag: PropTypes.string,
        isDisabled: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    }

    handleClick = () => {
        if (this.gtmTagRef) {
            this.gtmTagRef.click()
        }
        this.props.onClick()
    }

    renderGtmTag() {
        return !isUndefined(this.props.gtmTag) ? (
            <div className={classNames(this.props.gtmTag)} ref={c => (this.gtmTagRef = c)} />
        ) : null
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isDisabled]: this.props.isDisabled,
        })
        return (
            <React.Fragment>
                {this.renderGtmTag()}
                <button className={classNameBase} onClick={this.props.isDisabled ? null : this.handleClick}>
                    {this.props.children}
                </button>
            </React.Fragment>
        )
    }
}
