import React from 'react'
import { FormattedMessage } from 'react-intl'

import { ExternalLink, FileUploadCaption } from 'components'

export const DIALOG_REPORT_MOD_TITLE = (
    <FormattedMessage
        id="dialog.report.mod.title"
        defaultMessage="Сообщить о проблеме с модом"
    />
)

export const DIALOG_REPORT_MOD_VERION_CAPTION = (
    <FormattedMessage
        id="dialog.report.mod.version"
        defaultMessage="Выберите версию мода:"
    />
)

export const DIALOG_REPORT_MOD_VERSION_VALUE = (modVersion, gameVersion) => (
    <FormattedMessage
        id="dialog.report.mod.version.value"
        defaultMessage="{modVersion} для WoT {gameVersion}"
        values={{ modVersion, gameVersion }}
    />
)

export const DIALOG_REPORT_MOD_PROBLEMS_CAPTION = (
    <FormattedMessage
        id="dialog.report.mod.problems.caption"
        defaultMessage="Выберите типы проблем:"
    />
)

export const DIALOG_REPORT_MOD_TYPE_TECHNICAL_ISSUE = (
    <FormattedMessage
        id="dialog.report.mod.type.technical.issue"
        defaultMessage="Технические проблемы"
    />
)

export const DIALOG_REPORT_MOD_TYPE_UNACCEPTABLE_CONTENT = (
    <FormattedMessage
        id="dialog.report.mod.type.unacceptable.content"
        defaultMessage="Неприемлемый контент"
    />
)

export const DIALOG_REPORT_MOD_TYPE_CHEAT = (
    <FormattedMessage
        id="dialog.report.mod.type.cheat"
        defaultMessage="Запрещенный мод"
    />
)

export const DIALOG_REPORT_MOD_TYPE_COPYWRIGHT = (
    <FormattedMessage
        id="dialog.report.mod.type.copywright"
        defaultMessage="Нарушение авторских прав"
    />
)

export const DIALOG_REPORT_MOD_PROBLEMS_DESCRIPTION_CAPTION = (
    <FormattedMessage
        id="dialog.report.mod.problems.description.caption"
        defaultMessage="Подробно опишите проблему:"
    />
)

export const DIALOG_REPORT_MOD_FILE_FORMAT = (isError) => (
    <FormattedMessage
        id="dialog.report.mod.file.format"
        defaultMessage="Формат файла: {ext0}, {ext1}, {ext2}, {ext3} или {ext4}"
        values={{
            ext0: (<FileUploadCaption isError={isError} caption="zip" />),
            ext1: (<FileUploadCaption isError={isError} caption="rar" />),
            ext2: (<FileUploadCaption isError={isError} caption="7z" />),
            ext3: (<FileUploadCaption isError={isError} caption="png" />),
            ext4: (<FileUploadCaption isError={isError} caption="jpg" />),
        }}
    />
)

export const DIALOG_REPORT_MOD_FILE_SIZE_NO_MORE_THAN = (
    <FormattedMessage
        id="dialog.report.mod.file.size.no.more.than"
        defaultMessage="не более 10MB"
    />
)

export const DIALOG_REPORT_MOD_FILE_SIZE = (isError) => (
    <FormattedMessage
        id="dialog.report.mod.file.size"
        defaultMessage="Размер: {size}"
        values={{
            size: (<FileUploadCaption isError={isError} caption={DIALOG_REPORT_MOD_FILE_SIZE_NO_MORE_THAN} />),
        }}
    />
)

export const DIALOG_REPORT_MOD_ATTENTION = (link) => (
    <FormattedMessage
        id="dialog.report.mod.attention"
        defaultMessage="В целях безопасности не указывайте здесь личную информацию о себе или о ком-либо другом, так как мы не сможем защитить её в соответствии с {link}"
        values={{
            link: (
                <ExternalLink to={link}>
                    <FormattedMessage
                        id="dialog.report.mod.attention.link"
                        defaultMessage="Политикой конфиденциальности Wargaming"
                    />
                </ExternalLink>
            ),
        }}
    />
)

export const DIALOG_REPORT_MOD_BUTTON_OK = (
    <FormattedMessage
        id="dialog.report.mod.button.ok"
        defaultMessage="Сообщить о проблеме"
    />
)
