import React from 'react'
import { FormattedMessage } from 'react-intl'

export const EDIT_COMMENTS_TITLE = (count) => (
    <FormattedMessage
        id="edit.comments.title"
        defaultMessage="Комментарии {count}"
        values={{
            count: (<sup>{count}</sup>),
        }}
    />
)

export const EDIT_MOD_INPUT_PLACEHOLDER = (
    <FormattedMessage
        id="edit.mod.input.placeholder"
        defaultMessage="Введите текст сообщения..."
    />
)
