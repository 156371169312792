import React from 'react'

import { FooterLinks, LanguageSelect } from 'containers'

import { FOOTER_LANGUAGE_SELECT_CAPTION } from './translations'

import styles from './Footer.scss'

export default class Footer extends React.PureComponent {
    render() {
        return (
            <footer className={styles.base}>
                <div className={styles.inner}>
                    <a href="/" className={styles.logo} />
                    <div className={styles.links}>
                        <FooterLinks />
                    </div>
                    <div className={styles.languageSelect}>
                        <span className={styles.languageSelectCaption}>
                            {FOOTER_LANGUAGE_SELECT_CAPTION}
                        </span>
                        <LanguageSelect />
                    </div>
                </div>
            </footer>
        )
    }
}
