import React from 'react'
import { FormattedMessage } from 'react-intl'

export const FILTER_LANGUAGE_CAPTION = (
    <FormattedMessage
        id="filter.language.mod.descriptons"
        defaultMessage="Описание модов:"
    />
)

export const FILTER_LANGUAGE_ALL = (
    <FormattedMessage
        id="filter.language.all"
        defaultMessage="Все языки"
    />
)

export const FILTER_LANGUAGE_EN = (
    <FormattedMessage
        id="filter.language.en"
        defaultMessage="На английском"
    />
)

export const FILTER_LANGUAGE_RU = (
    <FormattedMessage
        id="filter.language.ru"
        defaultMessage="На русском"
    />
)
