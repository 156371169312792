import React from 'react'
import { FormattedMessage } from 'react-intl'

export const EDIT_UPLOAD_UPDATE_LIST_ALL_UPDATE_LABEL = (
    <FormattedMessage
        id="edit.upload.update.list.all.update.label"
        defaultMessage="Все обновления:"
    />
)

export const EDIT_UPLOAD_UPDATE_LIST_ALL_UPDATE_TEXT = (
    <FormattedMessage
        id="edit.upload.update.list.all.update.text"
        defaultMessage="Отметьте файлы для скачивания"
    />
)

export const EDIT_UPLOAD_UPDATE_LIST_STATE_CAPTION = (state) => (
    <FormattedMessage
        id="edit.upload.update.list.state.caption"
        defaultMessage="Статус обновления:{NBSP}{NBSP}{state}"
        values={{
            state,
            NBSP: '\u00a0',
        }}
    />
)

export const EDIT_UPLOAD_UPDATE_LIST_GAME_VERSION_VALUE = (gameVersion) => (
    <FormattedMessage
        id="edit.upload.update.list.game.version.value"
        defaultMessage="WoT {gameVersion}"
        values={{ gameVersion }}
    />
)

