import { get } from 'lodash'

import * as actions from 'actions/Details'

const getInitialState = () => ({
    id: null,
    isError: false,
    isErrorNotFound: false,
    isFetched: false,
    isFetching: false,
    data: {},
})

/* eslint-disable complexity */
export const details = (state = getInitialState(), action) => {
    switch (action.type) {
        case actions.START_FETCHING_DETAILS:
            return {
                ...state,
                isError: false,
                isErrorNotFound: false,
                isFetching: true,
                isFetched: false,
            }
        case actions.STOP_FETCHING_DETAILS:
            return {
                ...state,
                isFetching: false,
                isFetched: true,
            }
        case actions.SET_ERROR_FETCHING_DETAILS:
            return {
                ...state,
                isError: true,
                isErrorNotFound: get(action.payload, 'errorData.status') === 404,
            }

        case actions.UPDATE_DETAILS:
            return {
                ...state,
                data: action.payload.data,
                id: action.payload.id,
                isError: false,
                isErrorNotFound: false,
            }
        case actions.UPDATE_VOTING:
            return {
                ...state,
                data: {
                    ...state.data,
                    userVote: action.payload.value,
                },
            }
        case actions.SET_REPORT_COMPLETED:
            return {
                ...state,
                data: {
                    ...state.data,
                    isReported: true,
                },
            }
        default:
            return state
    }
}
