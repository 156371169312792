import React from 'react'
import { FormattedMessage } from 'react-intl'

export const ACTION_REPORT = (
    <FormattedMessage
        id="action.report"
        defaultMessage="Сообщить о проблеме"
    />
)

export const ACTION_REPORT_COMPLETED = (
    <FormattedMessage
        id="action.report.completed"
        defaultMessage="Вы сообщили о проблеме"
    />
)
