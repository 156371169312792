import React from 'react'
import PropTypes from 'prop-types'
import Loadable from 'react-loadable'

import { Textarea } from 'components'

class Placeholder extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string.isRequired,
        isError: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    }

    render() {
        return (
            <Textarea {...this.props} />
        )
    }
}

export default class HtmlEditor extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        isError: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    }

    componentWillMount() {
        this.loadableComponent = Loadable({
            loader: () => import('./CKEditorWrapper'),
            loading: () => {
                return (<Placeholder {...this.props} />)
            },
        })
    }

    render() {
        const LoadableComponent = this.loadableComponent

        return <LoadableComponent {...this.props} />
    }
}