import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Checkbox.scss'

export default class Checkbox extends React.PureComponent {
    static propTypes = {
        caption: PropTypes.node,
        isChecked: PropTypes.bool.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isChecked]: this.props.isChecked,
            [styles.isDisabled]: this.props.isDisabled,
            [styles.hasCaption]: this.props.caption,
        })

        const caption = this.props.caption ? (
            <span className={styles.caption}>{this.props.caption}</span>
        ) : null

        return (
            <label className={classNameBase}>
                <input
                    type="checkbox"
                    className={styles.input}
                    checked={this.props.isChecked}
                    onChange={this.props.onChange}
                />
                <span className={styles.check} />
                {caption}
            </label>
        )
    }
}
