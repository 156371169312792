import React from 'react'
import PropTypes from 'prop-types'

import {
    Caption,
    FilterSelect,
} from 'components'

import {
    GALLERY_FILTER_LANGUAGES,
} from 'utils/constants'

import {
    FILTER_LANGUAGE_CAPTION,
    FILTER_LANGUAGE_ALL,
    FILTER_LANGUAGE_RU,
    FILTER_LANGUAGE_EN,
} from './translations'

import styles from './FilterLanguage.scss'

export default class FilterLanguage extends React.PureComponent {
    static propTypes = {
        selectedLanguage: PropTypes.string,
        isUsedForSearch: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    }

    render() {
        const languageItems = [
            { value: GALLERY_FILTER_LANGUAGES.ALL, caption: FILTER_LANGUAGE_ALL },
            { value: GALLERY_FILTER_LANGUAGES.EN, caption: FILTER_LANGUAGE_EN },
            { value: GALLERY_FILTER_LANGUAGES.RU, caption: FILTER_LANGUAGE_RU },
        ]

        return (
            <div className={styles.base}>
                <Caption>{FILTER_LANGUAGE_CAPTION}</Caption>
                <div className={styles.select}>
                    <FilterSelect
                        items={languageItems}
                        selectedValue={this.props.selectedLanguage}
                        onItemChange={this.props.onChange}
                        isLanguage
                    />
                </div>
            </div>
        )
    }
}
