import { batchActions } from 'redux-batched-actions'
import { filter } from 'lodash'

import { updateModsCount } from 'actions/CurrentAccount'
import { fetchWithSpinner, fetchWrapper as fetch } from 'utils/fetch'
import { processSubscriptions } from 'utils/responseProcessing'
import urls from 'apiUrls'

export const SET_ERROR_FETCHING_SUBSCRIPTIONS = 'SET_ERROR_FETCHING_SUBSCRIPTIONS'
export const START_FETCHING_SUBSCRIPTIONS = 'START_FETCHING_SUBSCRIPTIONS'
export const STOP_FETCHING_SUBSCRIPTIONS = 'STOP_FETCHING_SUBSCRIPTIONS'
export const UPDATE_ITEMS_SUBSCRIPTIONS = 'UPDATE_ITEMS_SUBSCRIPTIONS'

const startFetchingSubscriptions = () => ({
    type: START_FETCHING_SUBSCRIPTIONS,
})

const stopFetchingSubscriptions = () => ({
    type: STOP_FETCHING_SUBSCRIPTIONS,
})

const updateItemsSubscriptions = (items) => ({
    type: UPDATE_ITEMS_SUBSCRIPTIONS,
    payload: {
        items,
    },
})

const setErrorFetchingSubscriptions = (errorData) => ({
    type: SET_ERROR_FETCHING_SUBSCRIPTIONS,
    payload: {
        errorData,
    },
})

export const fetchSubscriptions = () => {
    return (dispatch) => {
        const url = urls.subscription
        dispatch(startFetchingSubscriptions())

        const fetchPromise = fetch(url).promise
            .then((rawResponseData) => {
                dispatch(batchActions([
                    updateItemsSubscriptions(processSubscriptions(rawResponseData)),
                    stopFetchingSubscriptions(),
                ], UPDATE_ITEMS_SUBSCRIPTIONS))
                dispatch(updateModsCount(filter(rawResponseData, (item) => item.is_updated).length))
            }, (errorData) => {
                dispatch(batchActions([
                    setErrorFetchingSubscriptions(errorData),
                    stopFetchingSubscriptions(),
                ], SET_ERROR_FETCHING_SUBSCRIPTIONS))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

const following = (modId, method, id) => {
    return (dispatch) => {
        let body, url
        if (method === 'POST' || method === 'DELETE') {
            url = `${urls.subscriptionAction}`
            body = {
                is_updated: false,
                mod: modId,
            }
        } else {
            url = `${urls.subscriptionAction}${id}/`
            body = {
                id: id,
                is_updated: false,
                mod: modId,
            }
        }
        const fetchPromise = fetch(url, { method, body }).promise
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const unsubscribeMod = (modId) => {
    return following(modId, 'DELETE')
}

export const ignoreModUpdate = (id, modId) => {
    return following(modId, 'PUT', id)
}

export const followMod = (modId) => {
    return following(modId, 'POST')
}
