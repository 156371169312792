import React from 'react'
import PropTypes from 'prop-types'

import {
    ButtonYellowLarge,
    ButtonYellowOutlineLarge,
    TitleDialog,
} from 'components'

import {
    DIALOG_CONFIRMATION_DRAFT_CONTENT,
    DIALOG_CONFIRMATION_DRAFT_TITLE,
    DIALOG_CONFIRMATION_MOD_CANCEL_BUTTON,
    DIALOG_CONFIRMATION_MOD_CONTENT,
    DIALOG_CONFIRMATION_MOD_OK_BUTTON,
    DIALOG_CONFIRMATION_MOD_TITLE,
} from './translations'

import styles from './DialogConfirmationModRemove.scss'

export default class DialogConfirmationModRemove extends React.PureComponent {
    static propTypes = {
        id: PropTypes.number.isRequired,
        isDraftOrRejected: PropTypes.bool,

        onCloseDialog: PropTypes.func.isRequired,
        onSubmitDialog: PropTypes.func.isRequired,
    }

    handleSubmitDialog = () => {
        this.props.onSubmitDialog(this.props.id)
    }

    render() {
        return (
            <div className={styles.base}>
                <TitleDialog>
                    {this.props.isDraftOrRejected ? DIALOG_CONFIRMATION_DRAFT_TITLE : DIALOG_CONFIRMATION_MOD_TITLE}
                </TitleDialog>
                <div className={styles.content}>
                    <p>{this.props.isDraftOrRejected ? DIALOG_CONFIRMATION_DRAFT_CONTENT : DIALOG_CONFIRMATION_MOD_CONTENT}</p>
                </div>
                <div className={styles.button}>
                    <ButtonYellowLarge onClick={this.props.onCloseDialog}>
                        {DIALOG_CONFIRMATION_MOD_CANCEL_BUTTON}
                    </ButtonYellowLarge>
                </div>
                <div className={styles.button}>
                    <ButtonYellowOutlineLarge onClick={this.handleSubmitDialog}>
                        {DIALOG_CONFIRMATION_MOD_OK_BUTTON}
                    </ButtonYellowOutlineLarge>
                </div>
            </div>
        )
    }
}
