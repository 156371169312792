import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { orderBy } from 'lodash'

import { MOD_VERSION_STATUS, MOD_VERSION_COMMENT_TYPES } from 'utils/constants'

import {
    Cancel,
    Caption,
    Checkbox,
    EditLabelText,
    ModState,
    Notification,
} from 'components'

import {
    EDIT_UPLOAD_UPDATE_LIST_ALL_UPDATE_LABEL,
    EDIT_UPLOAD_UPDATE_LIST_ALL_UPDATE_TEXT,
    EDIT_UPLOAD_UPDATE_LIST_GAME_VERSION_VALUE,
    EDIT_UPLOAD_UPDATE_LIST_STATE_CAPTION,
} from './translations'

import styles from './EditUploadUpdateList.scss'

export default class EditUploadUpdateList extends React.Component {
    static propTypes = {
        comments: PropTypes.arrayOf(PropTypes.shape({
            authorName: PropTypes.string,
            content: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            gameVersion: PropTypes.string,
            modVersion: PropTypes.string,
            modVersionId: PropTypes.number,
            status: PropTypes.string,
        })).isRequired,
        versions: PropTypes.arrayOf(PropTypes.shape({
            createdAt: PropTypes.string.isRequired,
            gameVersion: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            isVisible: PropTypes.bool.isRequired,
            modVersion: PropTypes.string.isRequired,
            status: PropTypes.number.isRequired,
        })).isRequired,

        onModVersionRemove: PropTypes.func.isRequired,
        onModVersionVisibilityChange: PropTypes.func.isRequired,
    }

    handleVersionVisibilityChange = (modVersionId) => {
        this.props.onModVersionVisibilityChange(modVersionId)
    }

    renderItems() {
        const numberOfPublishedVersions = this.props.versions.filter(
            (item) => item.status === MOD_VERSION_STATUS.PUBLISHED,
        ).length

        const numberOfPublishedAndVisibleVersions = this.props.versions.filter(
            (item) => item.status === MOD_VERSION_STATUS.PUBLISHED && item.isVisible,
        ).length

        const isLastPublishedVersion = (item) =>
            this.props.versions.length === 1 ||
            (item.status === MOD_VERSION_STATUS.PUBLISHED && numberOfPublishedVersions === 1) ||
            (item.isVisible && item.status === MOD_VERSION_STATUS.PUBLISHED && numberOfPublishedAndVisibleVersions === 1)

        return orderBy(this.props.versions, 'id', 'desc').map((item, index) => {
            const commentWithRejection = this.props.comments.find(
                (comment) => comment.modVersionId === item.id && comment.status === MOD_VERSION_COMMENT_TYPES.REJECTED,
            )

            const isDisabled = item.status === MOD_VERSION_STATUS.REJECTED || isLastPublishedVersion(item)

            return (
                <div className={styles.tr} key={`mod-update-item-${index}`}>
                    <div className={styles.inner}>
                        <div className={classNames(styles.td, styles['td__mod-version'])}>
                            <Checkbox
                                caption={item.modVersion}
                                isChecked={item.isVisible}
                                isDisabled={isDisabled}
                                onChange={() => {this.props.onModVersionVisibilityChange(item.id, !item.isVisible)}}
                            />
                        </div>
                        <div className={classNames(styles.td, styles['td__game-version'])}>
                            {EDIT_UPLOAD_UPDATE_LIST_GAME_VERSION_VALUE(item.gameVersion)}
                        </div>
                        <div className={classNames(styles.td, styles['td__state'])}>
                            {EDIT_UPLOAD_UPDATE_LIST_STATE_CAPTION(
                                <ModState state={item.status} target="modVersion" />,
                            )}
                        </div>
                        {!isLastPublishedVersion(item) && (
                            <div className={classNames(styles.td, styles['td__remove'])}>
                                <Cancel onClick={() => {this.props.onModVersionRemove(item.id)}} />
                            </div>
                        )}
                    </div>

                    {index === 0 && commentWithRejection && (
                        <div className={styles.notification}>
                            <Notification type="warning">
                                <p>{commentWithRejection.content}</p>
                            </Notification>
                        </div>
                    )}
                </div>
            )
        })
    }

    render() {
        return (
            <div className={styles.base}>
                <div className={styles.row}>
                    <div className={styles.label}>
                        <Caption isLarge>
                            {EDIT_UPLOAD_UPDATE_LIST_ALL_UPDATE_LABEL}
                        </Caption>
                        <EditLabelText message={EDIT_UPLOAD_UPDATE_LIST_ALL_UPDATE_TEXT} />
                    </div>
                    <div className={styles.field}>
                        <div className={styles.table}>
                            {this.renderItems()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
