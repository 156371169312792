import { connect } from 'react-redux'

import { removeModVersion } from 'actions/EditMod'
import { closeDialog } from 'actions/Dialog'

import DialogConfirmationModVersionRemove
    from 'components/Dialogs/DialogConfirmationModVersionRemove/DialogConfirmationModVersionRemove'

const mapStateToProps = (state, ownProps) => ({
    id: ownProps.id,
})

const mapDispatchToProps = (dispatch) => ({
    onCloseDialog: () => {
        dispatch(closeDialog())
    },
    onSubmitDialog: (modVersionId) => {
        dispatch(removeModVersion(modVersionId))
        dispatch(closeDialog())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(DialogConfirmationModVersionRemove)