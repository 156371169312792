import React from 'react'
import { FormattedMessage } from 'react-intl'

import { ExternalLink } from 'components'

export const DIALOG_CONFIRMATION_TITLE = (
    <FormattedMessage
        id="dialog.confirmation.title"
        defaultMessage="Правила использования портала MOD HUB."
    />
)

export const DIALOG_CONFIRMATION_CONTENT = (link) => (
    <FormattedMessage
        id="dialog.confirmation.content"
        defaultMessage="Чтобы войти в раздел мододела, пожалуйста, прочитайте и примите{br}{link}"
        values={{
            link: (
                <ExternalLink to={link}>
                    <FormattedMessage
                        id="dialog.confirmation.content.link"
                        defaultMessage="Правила использования портала модов"
                    />
                </ExternalLink>
            ),
            br: <br />,
        }}
    />
)

export const DIALOG_CONFIRMATION_CHECKBOX_LABEL = (
    <FormattedMessage
        id="dialog.confirmation.checkbox.label"
        defaultMessage="Я согласен с правилами"
    />
)

export const DIALOG_CONFIRMATION_OK_BUTTON = (
    <FormattedMessage
        id="dialog.confirmation.ok.button"
        defaultMessage="Принимаю"
    />
)

export const DIALOG_CONFIRMATION_CANCEL_BUTTON = (
    <FormattedMessage
        id="dialog.confirmation.cancel.button"
        defaultMessage="Не принимаю"
    />
)
