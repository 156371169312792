import React from 'react'
import PropTypes from 'prop-types'

import {
    Caption,
    FilterSelect,
} from 'components'

import {
    FILTER_GAME_VERSION_CAPTION,
    FILTER_GAME_VERSION_CURRENT_VERSION,
} from './translations'

import styles from './FilterGameVersion.scss'

export default class FilterGameVersion extends React.PureComponent {
    static propTypes = {
        gameVersions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
                version: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
            }).isRequired,
        ).isRequired,
        selectedGameVersion: PropTypes.number,
        isUsedForSearch: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    }

    render() {
        const gameVersion = this.props.gameVersions.map((item, index) => ({
            value: item.id,
            caption: index === 0 ? FILTER_GAME_VERSION_CURRENT_VERSION(item.version) : item.version,
        }))

        return (
            <div className={styles.base}>
                <Caption>{FILTER_GAME_VERSION_CAPTION}</Caption>
                <div className={styles.select}>
                    <FilterSelect
                        items={gameVersion}
                        selectedValue={this.props.selectedGameVersion}
                        onItemChange={this.props.onChange}
                    />
                </div>
            </div>
        )
    }
}
