import { concat } from 'lodash'

import {
    SEARCH_RESULT_ITEMS_ORDERING,
    SEARCH_RESULT_ITEMS_PER_PAGE,
    SEARCH_RESULT_LANGUAGES,
} from 'utils/constants'
import * as actions from 'actions/SearchResult'
import settings from 'settings'

const getInitialState = () => ({
    filtered: {
        count: 0,
        filterParams: {
            type: null,
            argument: null,
            ordering: SEARCH_RESULT_ITEMS_ORDERING.RATING,
        },
        isError: false,
        isFetchedAll: false,
        isFetching: false,
        items: [],
        offset: 0,
    },
    overallFilters: {
        language: SEARCH_RESULT_LANGUAGES.ALL,
        gameVersion: settings.gameVersions[0].id,
    },
})

/* eslint-disable complexity */
export const searchResult = (state = getInitialState(), action = {}) => {
    switch (action.type) {
        case actions.START_SEARCHING_ITEMS:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    isError: false,
                    isFetching: true,
                },
            }
        case actions.STOP_SEARCHING_ITEMS:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    isFetching: false,
                },
            }
        case actions.SET_ERROR_SEARCH_RESULT_ITEMS:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    isError: true,
                },
            }
        case actions.INCREMENT_SEARCHING_OFFSET:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    offset: state.filtered.isFetchedAll ?
                        state.filtered.offset :
                        state.filtered.offset + SEARCH_RESULT_ITEMS_PER_PAGE,
                },
            }
        case actions.RESET_SEARCH_RESULT_ITEMS:
            return {
                ...state,
                filtered: {
                    count: 0,
                    filterParams: {
                        ...state.filtered.filterParams,
                        type: null,
                        argument: null,
                    },
                    isError: false,
                    isFetchedAll: false,
                    isFetching: false,
                    items: [],
                    offset: 0,
                },
            }
        case actions.CHANGE_SEARCH_RESULT_ITEMS_ORDERING:
            return {
                ...state,
                filtered: {
                    count: 0,
                    filterParams: {
                        ...state.filtered.filterParams,
                        ordering: action.payload.ordering,
                    },
                    isError: false,
                    isFetchedAll: false,
                    isFetching: false,
                    items: [],
                    offset: 0,
                },
            }
        case actions.UPDATE_SEARCH_RESULT_ITEMS:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    filterParams: {
                        ...state.filtered.filterParams,
                        ...action.payload.filterParams,
                    },
                    isError: false,
                    items: state.filtered.offset ?
                        concat(state.filtered.items, action.payload.items) :
                        action.payload.items,
                    isFetchedAll: (
                        state.filtered.offset + SEARCH_RESULT_ITEMS_PER_PAGE >= action.payload.count
                    ),
                    count: action.payload.count,
                },
            }
        case actions.SET_LANGUAGE_FILTER:
            return {
                ...state,
                overallFilters: {
                    ...state.overallFilters,
                    language: action.payload.language,
                },
            }
        case actions.SET_GAME_VERSION_FILTER:
            return {
                ...state,
                overallFilters: {
                    ...state.overallFilters,
                    gameVersion: action.payload.gameVersionId,
                },
            }
        default:
            return state
    }
}
