import { isNil } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'

import {
    incrementDownloadCount,
    processDownloadMod,
    fetchDetails,
    voteForMod,
} from 'actions/Details'
import { followMod, unsubscribeMod } from 'actions/Subscriptions'

import { openDialog } from 'actions/Dialog'
import {
    DialogLogin,
    DialogReportMod,
} from 'containers'

import Details from 'components/Details/Details'

const mapStateToProps = (state) => ({
    data: state.details.data,
    realm: state.currentAccount.realm,
    isAuthenticated: !isNil(state.currentAccount.spaId),
    isError: state.details.isError,
    isErrorNotFound: state.details.isErrorNotFound,
    isFetching: state.details.isFetching,
    isFetched: state.details.isFetched,
    isModDownloadAccepted: state.persistedValues.isModDownloadAccepted,
    language: state.currentAccount.lang,
})

const mapDispatchToProps = (dispatch) => ({
    onDownloadClick: (isModDownloadAccepted, isModDownloaded, modId, title, versions) => {
        if (isModDownloaded) {
            dispatch(incrementDownloadCount(modId))
        } else {
            dispatch(processDownloadMod(isModDownloadAccepted, modId, title, versions))
        }
    },
    onLoginLinkClick: () => {
        dispatch(openDialog(<DialogLogin />))
    },
    onReportButtonClick: (modId) => {
        dispatch(openDialog(<DialogReportMod modId={modId} />))
    },
    onFollowMod: (modId) => {
        dispatch(followMod(modId))
    },
    onUnsubscribeMod: (modId) => {
        dispatch(unsubscribeMod(modId))
    },
    fetchDetails: (modId) => {
        dispatch(fetchDetails(modId))
    },
    voteForMod: (modId, value) => {
        dispatch(voteForMod(modId, value))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Details)
