import React from 'react'
import { FormattedMessage } from 'react-intl'

export const EDIT_LANGUAGE_ADD_LABEL = (
    <FormattedMessage
        id="edit.language.add.label"
        defaultMessage="Дополнительный язык описания:"
    />
)

export const EDIT_LANGUAGE_ADD_BUTTON = (
    <FormattedMessage
        id="edit.language.add.button"
        defaultMessage="Добавить выбранный язык"
    />
)

export const EDIT_LANGUAGE_ADD_RU = (
    <FormattedMessage
        id="edit.language.add.ru"
        defaultMessage="Русский"
    />
)

export const EDIT_LANGUAGE_ADD_EN = (
    <FormattedMessage
        id="edit.language.add.en"
        defaultMessage="Английский"
    />
)
