import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './LanguageSelect.scss'

export default class LanguageSelect extends React.PureComponent {
    static propTypes = {
        languages: PropTypes.arrayOf(PropTypes.shape({
            code: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })).isRequired,
        selected: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isOpened: false,
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.handleOutsideClick, true)
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleOutsideClick, true)
    }

    handleChange(code) {
        this.props.selected !== code && this.props.onChange(code)
        this.hide()
    }

    handleOutsideClick = (event) => {
        if (!this._base.contains(event.target)) {
            this.hide()
        }
    }

    show = () => {
        this.setState({ isOpened: true })
    }

    hide = () => {
        this.setState({ isOpened: false })
    }

    handleToggle = () => {
        this.state.isOpened ? this.hide() : this.show()
    }

    getSelectText() {
        const selected = this.props.selected

        if (selected) {
            return this.props.languages.find(item => (item.code === selected)).title
        }

        return ''
    }

    renderItems() {
        return this.props.languages.map((item, idx) => {
            return (
                <div
                    key={`languages-${idx}`}
                    className={classNames(styles.option, styles[`option__${item.code}`])}
                    onClick={() => this.handleChange(item.code)}
                >
                    {item.title}
                </div>
            )

        })
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isOpened]: this.state.isOpened,
        })

        const classNameValue = classNames(styles.value, styles[`value__${this.props.selected}`], {
            [styles.isOpened]: this.state.isOpened,
        })

        return (
            <div
                ref={c => (this._base = c)}
                className={classNameBase}
                onClick={this.handleToggle}
            >
                <div className={classNameValue}>{this.getSelectText()}</div>
                <div className={styles.dropdown}>
                    {this.renderItems()}
                </div>
            </div>
        )
    }
}
