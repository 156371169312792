import React from 'react'
import { FormattedMessage } from 'react-intl'

export const DIALOG_LOGIN_TITLE = (
    <FormattedMessage
        id="dialog.login.title"
        defaultMessage="Выберите регион, в котором зарегистрирован ваш аккаунт:"
    />
)

export const DIALOG_LOGIN_CAPTION = {
    ru: (
        <FormattedMessage
            id="dialog.login.caption.ru"
            defaultMessage="СНГ"
        />
    ),
    eu: (
        <FormattedMessage
            id="dialog.login.caption.eu"
            defaultMessage="Европа"
        />
    ),
    us: (
        <FormattedMessage
            id="dialog.login.caption.us"
            defaultMessage="Северная америка"
        />
    ),
    sg: (
        <FormattedMessage
            id="dialog.login.caption.sg"
            defaultMessage="Азия"
        />
    ),
}
