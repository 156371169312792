import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

export const NEWS_PAGE_TITLE = (
    <FormattedMessage
        id="news.page.title"
        defaultMessage="Новости"
    />
)

export const EMPTY_NEWS = (
    <FormattedMessage
        id="news.empty"
        defaultMessage="В данный момент новостей нет. Попробуйте зайти позже."
    />
)

export const messages = defineMessages({
    newsTitle: {
        id: 'news.title',
        defaultMessage: 'Новости — Моды для World of Tanks',
    },
})
