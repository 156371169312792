import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

export const NOTIFICATION_BLOCKED_USER_CONTENT = (
    <FormattedMessage
        id="notification.blocked.user.content"
        defaultMessage="Вы не можете загружать и редактировать моды, так как ваш аккаунт не соответствует следующим требованиям:"
    />
)

export const NOTIFICATION_BLOCKED_USER_CONTENT_REQUIREMENT_DAYS = (days) => (
    <FormattedHTMLMessage
        id="notification.blocked.user.content.requirement.days"
        defaultMessage="Количество дней с момента регистрации аккаунта: <strong>не менее {days}</strong>."
        values={{ days }}
    />
)

export const NOTIFICATION_BLOCKED_USER_CONTENT_REQUIREMENT_BATTLES_COUNT = (battlesCount) => (
    <FormattedHTMLMessage
        id="notification.blocked.user.content.requirement.battles_count"
        defaultMessage="Количество боёв в World of Tanks на аккаунте: <strong>не менее {battlesCount}</strong>."
        values={{ battlesCount }}
    />
)
