import { get } from 'lodash'

import * as actions from 'actions/News'

const initialState = {
    count: 0,
    initialized: true,
    isError: false,
    isFetching: false,
    items: [],
    page: 1,
}

export const news = (state = initialState, action) => {
    const payload = get(action, 'payload')
    switch (action.type) {
        case actions.START_FETCHING_NEWS:
            return {
                ...state,
                isError: false,
                isFetching: true,
            }
        case actions.SET_ERROR_FETCHING_NEWS:
            return {
                ...state,
                isFetching: false,
                isError: true,
            }
        case actions.STOP_FETCHING_NEWS:
            return {
                ...state,
                isFetching: false,
            }
        case actions.UPDATE_ITEMS_NEWS:
            return {
                ...state,
                items: payload.items,
                count: action.payload.count,
            }
        case actions.CHANGE_NEWS_PAGE:
            return {
                ...state,
                page: payload.page,
            }
        default:
            return state
    }
}
