import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { MOD_VERSION_COMMENT_TYPES } from 'utils/constants'
import { formatDateTime } from 'utils/formatting'

import { ScrollArea } from 'components'

import {
    EDIT_COMMENTS_ITEM_MOD_SUMMARY_VERSION,
    EDIT_COMMENTS_MODERATOR_CAPTION,
} from './translations'

import styles from './EditCommentsItem.scss'

const CONTENT_MAX_HEIGHT = 200

export default class EditCommentsItem extends React.PureComponent {
    static propTypes = {
        authorName: PropTypes.string,
        content: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        gameVersion: PropTypes.string.isRequired,
        modVersion: PropTypes.string,
        status: PropTypes.oneOf([MOD_VERSION_COMMENT_TYPES.REJECTED, MOD_VERSION_COMMENT_TYPES.APPROVED]),
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isApproved]: this.props.status === MOD_VERSION_COMMENT_TYPES.APPROVED,
            [styles.isRejected]: this.props.status === MOD_VERSION_COMMENT_TYPES.REJECTED,
        })

        return (
            <div className={classNameBase}>
                <div className={styles.name}>
                    {this.props.authorName ? this.props.authorName : EDIT_COMMENTS_MODERATOR_CAPTION}
                </div>
                <div className={styles.inner}>
                    <ScrollArea contentHeightMax={CONTENT_MAX_HEIGHT}>
                        <div className={styles.message}>{this.props.content}</div>
                    </ScrollArea>
                </div>
                <div className={styles.summary}>
                    {this.props.modVersion &&
                    EDIT_COMMENTS_ITEM_MOD_SUMMARY_VERSION(this.props.modVersion, this.props.gameVersion)}
                    <div className={styles.date}>
                        {formatDateTime(this.props.createdAt)}
                    </div>
                </div>
            </div>
        )
    }
}
