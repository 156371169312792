import { processChangelogHistoryItems } from 'utils/responseProcessing'

export const ADD_CHANGELOG_HISTORY_ITEM = 'ADD_CHANGELOG_HISTORY_ITEM'
export const CLEAR_CHANGELOG_HISTORY_ITEMS = 'CLEAR_CHANGELOG_HISTORY_ITEMS'
export const EDIT_CHANGELOG_HISTORY_ITEM = 'EDIT_CHANGELOG_HISTORY_ITEM'
export const REMOVE_CHANGELOG_HISTORY_ITEM = 'REMOVE_CHANGELOG_HISTORY_ITEM'
export const SET_CHANGELOG_HISTORY_ORDERING = 'SET_CHANGELOG_HISTORY_ORDERING'
export const UPDATE_CHANGELOG_HISTORY_ITEMS = 'UPDATE_CHANGELOG_HISTORY_ITEMS'

export const addChangelogHistoryItem = (content, version) => ({
    type: ADD_CHANGELOG_HISTORY_ITEM,
    payload: { content, version },
})

export const updateChangelogHistoryItems = (rawResponseData) => {
    return ({
        type: UPDATE_CHANGELOG_HISTORY_ITEMS,
        payload: { items: processChangelogHistoryItems(rawResponseData).changelogForPreviousVersions },
    })
}

export const clearChangelogHistoryItems = () => ({
    type: CLEAR_CHANGELOG_HISTORY_ITEMS,
})

export const editChangelogHistoryItem = (id, content, version) => ({
    type: EDIT_CHANGELOG_HISTORY_ITEM,
    payload: { id, content, version },
})

export const removeChangelogHistoryItem = (id) => ({
    type: REMOVE_CHANGELOG_HISTORY_ITEM,
    payload: { id },
})

export const setChangelogHistoryOrdering = (orderedItems) => ({
    type: SET_CHANGELOG_HISTORY_ORDERING,
    payload: { orderedItems },
})
