import React from 'react'
import { FormattedMessage } from 'react-intl'

export const VOTING_TOTAL_LABEL = (
    <FormattedMessage
        id="voting.total.label"
        defaultMessage="Голосов пользователей"
    />
)

export const VOTING_VOTE_LABEL = (
    <FormattedMessage
        id="voting.vote.label"
        defaultMessage="Рейтинг"
    />
)
