import React from 'react'
import { FormattedMessage, FormattedHTMLMessage, defineMessages } from 'react-intl'
import { ExternalLink } from 'components'

export const DETAILS_TAGS_TITLE = (
    <FormattedMessage
        id="details.tags.title"
        defaultMessage="Категории:"
    />
)

export const DETAILS_AUTHENTICATION_FAILS_FOR_ACTIONS = (onClick) => (
    <FormattedMessage
        id="details.authenticated.fails.for.actions"
        defaultMessage="{link}, чтобы подписаться на обновления или сообщить о проблеме."
        values={{
            link: (
                <a href="" onClick={onClick}>
                    <FormattedMessage
                        id="details.authenticated.fails.for.actions.link"
                        defaultMessage="Войдите"
                    />
                </a>
            ),
        }}
    />
)

export const DETAILS_AUTHENTICATION_FAILS_FOR_COMMENTS = (onClick) => (
    <FormattedMessage
        id="details.authenticated.fails.for.comments"
        defaultMessage="{link}, чтобы оставить комментарий."
        values={{
            link: (
                <a href="" onClick={onClick}>
                    <FormattedMessage
                        id="details.authenticated.fails.for.comments.link"
                        defaultMessage="Войдите"
                    />
                </a>
            ),
        }}
    />
)

export const DETAILS_AUTHENTICATED_INFO_FOR_COMMENTS = (link) => (
    <FormattedMessage
        id="details.authenticated.info.for.comments"
        defaultMessage="В целях безопасности не указывайте здесь личную информацию о себе или о ком-либо другом, так как мы не сможем защитить её в соответствии с {link}."
        values={{
            link: (
                <ExternalLink to={link}>
                    <FormattedMessage
                        id="details.authenticated.info.for.comments.link"
                        defaultMessage="Политикой конфиденциальности Wargaming"
                    />
                </ExternalLink>
            ),
        }}
    />
)

export const DETAILS_AUTHORED_BY = (
    <FormattedMessage
        id="details.authored.by"
        defaultMessage="Автор:"
    />
)

export const DETAILS_UPLOADED_BY = (
    <FormattedMessage
        id="details.uploaded.by"
        defaultMessage="Загрузил:"
    />
)

export const DETAILS_DESCRIPTION_TITLE = (
    <FormattedMessage
        id="details.description.title"
        defaultMessage="Описание мода"
    />
)

export const DETAILS_DESCRIPTION_LANGUAGE_SELECT_CAPTION = (
    <FormattedMessage
        id="details.description.language.select.caption"
        defaultMessage="Язык описания:"
    />
)

export const DETAILS_INSTALLATION_GUIDE_TITLE = (
    <FormattedMessage
        id="details.installation.guide.title"
        defaultMessage="Инструкция по установке"
    />
)

export const DETAILS_CHANGELOG_TITLE = (
    <FormattedMessage
        id="details.changelog.title"
        defaultMessage="История изменений"
    />
)

export const DETAILS_CHANGELOG_VERSION = (version) => (
    <FormattedHTMLMessage
        id="details.changelog.caption"
        defaultMessage="Версия {version}"
        values={{ version }}
    />
)

export const DETAILS_COMMENTS_LANGUAGE_SELECT_CAPTION = (
    <FormattedMessage
        id="details.comments.language.select.caption"
        defaultMessage="Язык комментариев:"
    />
)

export const DETAILS_BUTTON_LOAD_CAPTION = (
    <FormattedMessage
        id="details.button.load.caption"
        defaultMessage="Больше сообщений"
    />
)

export const DETAILS_COMMENTS_TITLE = (
    <FormattedMessage
        id="details.comments.title"
        defaultMessage="Комментарии:"
    />
)

export const messages = defineMessages({
    documentTitle: {
        id: 'details.document.title',
        defaultMessage: '{modTitle} — Моды для World of Tanks',
    },
})
