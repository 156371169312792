import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './FileUploadCaption.scss'

export default class FileUploadCaption extends React.PureComponent {
    static propTypes = {
        caption: PropTypes.node.isRequired,
        isError: PropTypes.bool,
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isError]: this.props.isError,
        })

        return (
            <span className={classNameBase}>{this.props.caption}</span>
        )
    }
}
