import React from 'react'
import { FormattedMessage, FormattedHTMLMessage, defineMessages } from 'react-intl'

export const MODDER_SECTION_PAGE_TITLE_DEFAULT = (value) => (
    <FormattedMessage
        id="modder.section.page.title.default"
        defaultMessage="Мои моды: {value}"
        values={{
            value,
        }}
    />
)

export const MODDER_SECTION_PAGE_TITLE_EMPTY = (
    <FormattedMessage
        id="modder.section.page.title.empty"
        defaultMessage="Мои моды"
    />
)

export const MODDER_SECTION_LOGIN_MESSAGE = (
    <FormattedMessage
        id="modder.section.login.message"
        defaultMessage="Войдите на сайт, чтобы получить доступ к разделу мододела."
    />
)

export const MODDER_SECTION_LOGIN = (
    <FormattedMessage
        id="modder.section.login"
        defaultMessage="Войти"
    />
)

export const MODDER_SECTION_FILTER_CAPTION = (
    <FormattedMessage
        id="modder.section.filter.caption"
        defaultMessage="Показывать моды со статусом:"
    />
)

export const MODDER_SECTION_ADD_MOD_MESSAGE = (
    <FormattedMessage
        id="modder.section.add.mod.message"
        defaultMessage="Вы ещё не добавили ни одного мода"
    />
)

export const MODDER_SECTION_ADD_MOD = (
    <FormattedHTMLMessage
        id="modder.section.add.mod.caption"
        defaultMessage="+ Добавить мод"
    />
)

export const MODDER_SECTION_ALL_STATE_VALUES = (
    <FormattedMessage
        id="mod.state.all"
        defaultMessage="Все"
    />
)

export const messages = defineMessages({
    modderSectionTitle: {
        id: 'modder.section.title',
        defaultMessage: 'Раздел мододела. Мои моды — Моды для World of Tanks',
    },
})
