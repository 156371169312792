import React from 'react'
import PropTypes from 'prop-types'

import styles from './ExternalLink.scss'

export default class ExternalLink extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        to: PropTypes.string.isRequired,
    }

    render() {
        return (
            <a href={this.props.to} className={styles.base} target="__blank">{this.props.children}</a>
        )
    }
}
