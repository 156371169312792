import en from 'react-intl/locale-data/en'
import ru from 'react-intl/locale-data/ru'

import apiUrls from 'apiUrls'
import { fetchWrapper as fetch } from 'utils/fetch'

export const localeDataHash = {
    en,
    ru,
}

export const AVAILABLE_LANGUAGES = Object.keys(localeDataHash)
export const LANGUAGE_CODES = {
    EN: 'en',
    RU: 'ru',
}

export const savePreferredLanguage = (lang_code) => {
    return fetch(apiUrls.setLanguage, {
        method: 'POST',
        body: {
            lang_code,
        },
    }).promise
}
