export const scrollToElement = (box, element, duration, callback) => {
    const getElementTop = (el) => {
        const pPos = el.parentNode.getBoundingClientRect()
        const cPos = el.getBoundingClientRect()
      
        return cPos.top - pPos.top + el.parentNode.scrollTop
    }

    const scrollTo = (el, to, duration) => {
        var start = el.scrollTop,
            change = to - start,
            startTime = Date.now(),
            now, elapsed, t
    
        const animateScroll = () => {
            now = Date.now()
            elapsed = now - startTime
            t = (elapsed/duration)
    
            el.scrollTop = start + change * easeInOutQuad(t)
    
            if (t < 1) {
                window.requestAnimationFrame(animateScroll)
            } else if (callback) {
                callback()
            }
        }

        function easeInOutQuad(t) {
            return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
        }
    
        animateScroll()
    }

    scrollTo(box, getElementTop(element), duration)
}