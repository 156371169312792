import React from 'react'
import PropTypes from 'prop-types'

import {
    MOD_STATE,
} from 'utils/constants'

import {
    ActionCancelMod,
    ActionHiddenMod,
    ActionVisibleMod,
    Caption,
    ModState,
} from 'components'

import {
    EDIT_STATE_LABEL,
} from './translations'

import styles from './EditState.scss'

export default class EditState extends React.PureComponent {
    static propTypes = {
        isHidden: PropTypes.bool.isRequired,
        state: PropTypes.number.isRequired,
        onModVisibilityChange: PropTypes.func.isRequired,
        onUpdateCancel: PropTypes.func.isRequired,
    }

    handleModVisibilitySet = () => {
        this.props.onModVisibilityChange(true)
    }

    handleModVisibilityClear = () => {
        this.props.onModVisibilityChange(false)
    }

    renderActionVisibility() {
        if ([MOD_STATE.REJECTED, MOD_STATE.DRAFT, MOD_STATE.IN_REVIEW].includes(this.props.state)) {
            return null
        }

        return this.props.isHidden ? (
            <div className={styles.action}>
                <ActionVisibleMod onClick={this.handleModVisibilitySet} />
            </div>
        ) : (
            <div className={styles.action}>
                <ActionHiddenMod onClick={this.handleModVisibilityClear} />
            </div>
        )
    }

    renderActionCancelUpdate() {
        return [MOD_STATE.IN_REVIEW, MOD_STATE.PUBLISHED_UPDATE_IN_REVIEW].includes(this.props.state) ? (
            <div className={styles.action}>
                <ActionCancelMod onClick={this.props.onUpdateCancel} />
            </div>
        ) : null
    }

    render() {
        return (
            <div className={styles.base}>
                <div className={styles.label}>
                    <Caption isLarge>
                        {EDIT_STATE_LABEL(<ModState isHidden={this.props.isHidden} state={this.props.state} />)}
                    </Caption>
                </div>
                <div className={styles.field}>
                    {this.renderActionVisibility()}
                    {this.renderActionCancelUpdate()}
                </div>
            </div>
        )
    }
}
