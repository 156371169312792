export const OPEN_DIALOG_ERROR = 'OPEN_DIALOG_ERROR'
export const CLOSE_DIALOG_ERROR = 'CLOSE_DIALOG_ERROR'

export const openDialogError = (content, title) => ({
    content,
    title,
    type: OPEN_DIALOG_ERROR,
})

export const closeDialogError = () => ({
    type: CLOSE_DIALOG_ERROR,
})
