import { batchActions } from 'redux-batched-actions'

import { fetchWithSpinner, fetchWrapper as fetch } from 'utils/fetch'
import urls from 'apiUrls'

export const SET_ERROR_FETCHING_CONTENT_PAGE = 'SET_ERROR_FETCHING_CONTENT_PAGE'
export const START_FETCHING_CONTENT_PAGE = 'START_FETCHING_CONTENT_PAGE'
export const STOP_FETCHING_CONTENT_PAGE = 'STOP_FETCHING_CONTENT_PAGE'
export const UPDATE_CONTENT_PAGE = 'UPDATE_CONTENT_PAGE'

export const startFetchingContentPage = () => ({
    type: START_FETCHING_CONTENT_PAGE,
})

export const stopFetchingContentPage = () => ({
    type: STOP_FETCHING_CONTENT_PAGE,
})

export const updateContentPage = (rawResponseData) => ({
    type: UPDATE_CONTENT_PAGE,
    payload: {
        data: {
            content: rawResponseData.content,
            title: rawResponseData.title,
        },
        slug: rawResponseData.slug,
    },
})

export const setErrorFetchingContentPage = (errorData) => ({
    type: SET_ERROR_FETCHING_CONTENT_PAGE,
    payload: {
        errorData,
    },
})

export const fetchContentPage = (slug) => {
    return (dispatch) => {
        const url = urls.contentPages.replace(/:(slug)/g, slug)

        dispatch(startFetchingContentPage())

        const fetchPromise = fetch(url).promise
            .then((rawResponseData) => {
                dispatch(batchActions([
                    updateContentPage(rawResponseData),
                    stopFetchingContentPage(),
                ], UPDATE_CONTENT_PAGE))
            }, (errorData) => {
                dispatch(batchActions([
                    setErrorFetchingContentPage(errorData),
                    stopFetchingContentPage(),
                ], SET_ERROR_FETCHING_CONTENT_PAGE))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}
